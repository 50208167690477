import React from 'react';
import { getFirestore, collection, query, where, getDocs, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from "../firebase.js"; // Firebase database instance
const serverUrl = import.meta.env.VITE_SERVER_URL;

const ChatButton = ({ userEmail, posterEmail }) => {
    const navigate = useNavigate();

    const handleCreateChat = async () => {
        if (!userEmail || !posterEmail) {
            alert('Invalid email.');
            return;
        }

        try {
            const chatsCollection = collection(db, 'chats');

            // Check if a chat with the same participants already exists
            const chatQuery = query(
                chatsCollection,
                where('participants', 'array-contains', userEmail) // Check for user's email
            );

            const snapshot = await getDocs(chatQuery);
            let existingChatId = null;

            snapshot.forEach((doc) => {
                const chat = doc.data();
                if (chat.participants.includes(posterEmail)) {
                    existingChatId = doc.id; // Found an existing chat
                }
            });

            if (existingChatId) {
                // Navigate to the existing chat
                navigate(`/chat/${existingChatId}`);
                return;
            }
/*
            const fetchCsrfToken = async () => {
                const response = await fetch(`${serverUrl}/csrf-token`, {
                    credentials: 'include', // Include cookies
                });
                const data = await response.json();
                return data.csrfToken;
            };
*/
            // If no chat exists, create a new one
            const newChat = {
                participants: [userEmail, posterEmail],
                createdAt: serverTimestamp(),
            };

            const docRef = await addDoc(chatsCollection, newChat);
            const chatId = docRef.id;

            // Call the endpoint to generate the chat key
           // const csrfToken = await fetchCsrfToken();
            const response = await fetch(`${serverUrl}/chat/${chatId}/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                   // 'X-CSRF-Token': csrfToken, // Include CSRF token if your server requires it
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to generate chat key');
            }

            const { message } = await response.json();

            // Navigate to the new chat
            navigate(`/chat/${chatId}`);
        } catch (error) {
            console.error('Error creating chat:', error);
            alert('Failed to create or navigate to chat. Please try again.');
        }
    };

    return (
        <button 
            onClick={handleCreateChat} 
            style={{
                padding: '10px',
                color: '#4caf50',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
            }}
        >
            Message Creator
        </button>
    );
};

export default ChatButton;
