import React from 'react';
import { useEffect } from 'react';
import './LegalPage.css';

const LegalPage = () => {

    useEffect(() => {
        // Scroll to the specific section if a hash is present in the URL
        const hash = window.location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);



    return (
        <div className="legal-page">
            <h1 style={{color: "#d43255"}}>Website Information</h1>
            
        


<>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #d43255 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor:rgb(176, 176, 176) !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #d43255 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #d43255 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: rgb(176, 176, 176) !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: rgb(183, 54, 82) !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
    }}
  />
  <span
    style={{
      display: "block",
      margin: "0 auto 3.125rem",
      width: "11.125rem",
      height: "2.375rem",
      background: "url(data:image/svg+xml"
    }}
  />
  <div data-custom-class="body"  id="privacy-policy">
    <div>
      <strong>
        <span style={{ fontSize: 26 }}>
          <span data-custom-class="title">
            <bdt className="block-component" />
            <bdt className="question">
              <h1>PRIVACY POLICY</h1>
            </bdt>
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </strong>
    </div>
    <div>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">January 31, 2025</bdt>
            </span>
          </span>
        </strong>
      </span>
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            This Privacy Notice for{" "}
            <bdt className="question">
              IdeaSights
              <bdt className="block-component" />
            </bdt>{" "}
            (<bdt className="block-component" />"<strong>we</strong>," "
            <strong>us</strong>," or "<strong>our</strong>"
            <bdt className="statement-end-if-in-editor" />
          </span>
          <span data-custom-class="body_text">
            ), describes how and why we might access, collect, store, use,
            and/or share (<bdt className="block-component" />"
            <strong>process</strong>"
            <bdt className="statement-end-if-in-editor" />) your personal
            information when you use our services (
            <bdt className="block-component" />"<strong>Services</strong>"
            <bdt className="statement-end-if-in-editor" />
            ), including when you:
          </span>
        </span>
      </span>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Visit our website
              <bdt className="block-component" /> at{" "}
              <span style={{ color:"rgb(183, 54, 82)" }}>
                <bdt className="question">
                  <a
                    href="https://ideasights.com"
                    target="_blank"
                    data-custom-class="link"
                  >
                    https://ideasights.com
                  </a>
                </bdt>
              </span>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor">
                          , or any website of ours that links to this Privacy
                          Notice
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span style={{ fontSize: 15 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </bdt>
    </div>
    <ul>
        {/*
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Download and use
              <bdt className="block-component" /> our mobile application
              <bdt className="block-component" /> (
              <bdt className="question">
                IdeaSights)
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor">
                                  ,
                                </bdt>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </bdt>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor">
                          <bdt className="block-component">
                            {" "}
                            or any other application of ours that links to this
                            Privacy Notice
                          </bdt>
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>*/}
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }} />
      </bdt>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Engage with us in other related ways, including any sales,
                marketing, or events
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <strong>Questions or concerns?&nbsp;</strong>Reading this Privacy
              Notice will help you understand your privacy rights and choices.
              We are responsible for making decisions about how your personal
              information is processed. If you do not agree with our policies
              and practices, please do not use our Services.
              <bdt className="block-component" /> If you still have any
              questions or concerns, please contact us at{" "}
              <bdt className="question">ideasights.public@gmail.com</bdt>.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="heading_1">
              <h2>SUMMARY OF KEY POINTS</h2>
            </span>
          </span>
        </strong>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>
                This summary provides key points from our Privacy Notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our&nbsp;
              </em>
            </strong>
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <strong>
                <em>table of contents</em>
              </strong>
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>&nbsp;below to find the section you are looking for.</em>
            </strong>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What personal information do we process?</strong> When you
            visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services,
            the choices you make, and the products and features you use. Learn
            more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#personalinfo">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              personal information you disclose to us
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              Do we process any sensitive personal information?&nbsp;
            </strong>
            Some of the information may be considered{" "}
            <bdt className="block-component" />
            "special" or "sensitive"
            <bdt className="statement-end-if-in-editor" /> in certain
            jurisdictions, for example your racial or ethnic origins, sexual
            orientation, and religious beliefs.{" "}
            <bdt className="block-component" />
            We do not process sensitive personal information.
            <bdt className="else-block" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Do we collect any information from third parties?</strong>{" "}
            <bdt className="block-component" />
            We do not collect any information from third parties.
            <bdt className="else-block" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we process your information?</strong> We process your
            information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to
            comply with law. We may also process your information for other
            purposes with your consent. We process your information only when we
            have a valid legal reason to do so. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we process your information
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              In what situations and with which{" "}
              <bdt className="block-component" />
              parties do we share personal information?
            </strong>{" "}
            We may share information in specific situations and with specific{" "}
            <bdt className="block-component" />
            third parties. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#whoshare">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              when and with whom we share your personal information
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            .<bdt className="block-component" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do we keep your information safe?</strong> We have
            adequate <bdt className="block-component" />
            organizational
            <bdt className="statement-end-if-in-editor" /> and technical
            processes and procedures in place to protect your personal
            information. However, no electronic transmission over the internet
            or information storage technology can be guaranteed to be 100%
            secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other <bdt className="block-component" />
            unauthorized
            <bdt className="statement-end-if-in-editor" /> third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information. Learn more about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              how we keep your information safe
            </span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>What are your rights?</strong> Depending on where you are
            located geographically, the applicable privacy law may mean you have
            certain rights regarding your personal information. Learn more
            about&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#privacyrights">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">your privacy rights</span>
          </span>
        </a>
        <span data-custom-class="body_text">.</span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>How do you exercise your rights?</strong> The easiest way to
            exercise your rights is by{" "}
            <bdt className="block-component">submitting a&nbsp;</bdt>
          </span>
        </span>
        <a
          data-custom-class="link"
          href="https://app.termly.io/notify/9cf05ed3-484f-4251-92f6-e55266e6ff30"
          rel="noopener noreferrer"
          target="_blank"
        >
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              data subject access request
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />, or by contacting us. We will
            consider and act upon any request in accordance with applicable data
            protection laws.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            Want to learn more about what we do with any information we
            collect?&nbsp;
          </span>
        </span>
        <a data-custom-class="link" href="#toc">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              Review the Privacy Notice in full
            </span>
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">.</span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="toc" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(0, 0, 0)" }}>
              <strong>
                <span data-custom-class="heading_1">
                  <h2>TABLE OF CONTENTS</h2>
                </span>
              </strong>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infocollect">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              1. WHAT INFORMATION DO WE COLLECT?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infouse">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              2. HOW DO WE PROCESS YOUR INFORMATION?
              <bdt className="block-component" />
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#legalbases">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              3.{" "}
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(183, 54, 82)" }}>
                  WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                  INFORMATION?
                </span>
              </span>
              <bdt className="statement-end-if-in-editor" />
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a data-custom-class="link" href="#whoshare">
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </a>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
          <a data-custom-class="link" href="#3pwebsites">
            5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
            <bdt className="statement-end-if-in-editor" />
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#cookies">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
          <bdt className="block-component" />
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#sociallogins">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              <span style={{ color: "rgb(183, 54, 82)" }}>
                <span style={{ color: "rgb(183, 54, 82)" }}>
                  7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </span>
              </span>
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#inforetain">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              8. HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#infosafe">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              9. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </a>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="statement-end-if-in-editor" />
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a data-custom-class="link" href="#privacyrights">
              10. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#DNT">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              11. CONTROLS FOR DO-NOT-TRACK FEATURES
              <bdt className="block-component" />
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#uslaws">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </a>
        </span>
        <bdt className="block-component">
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text" />
          </span>
        </bdt>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#otherlaws">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
          </span>
        </a>
        <span style={{ fontSize: 15 }}>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-class="body_text" />
          </bdt>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <bdt className="block-component">
          <span style={{ fontSize: 15 }} />
        </bdt>
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <a data-custom-class="link" href="#policyupdates">
            <span style={{ color: "rgb(183, 54, 82)" }}>
              14. DO WE MAKE UPDATES TO THIS NOTICE?
            </span>
          </a>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#contact">
          <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
            15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <a data-custom-class="link" href="#request">
          <span style={{ color: "rgb(183, 54, 82)" }}>
            16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </span>
        </a>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div id="infocollect" style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
            <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
              <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </span>
        </span>
        <span
          data-custom-class="heading_2"
          id="personalinfo"
          style={{ color: "rgb(0, 0, 0)" }}
        >
          <span style={{ fontSize: 15 }}>
            <strong>
              <h3>Personal information you disclose to us</h3>
            </strong>
          </span>
        </span>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>&nbsp;</em>
                    </strong>
                    <em>
                      We collect personal information that you provide to us.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              We collect personal information that you voluntarily provide to us
              when you{" "}
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
              register on the Services,&nbsp;
            </span>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
              <span data-custom-class="body_text">
                express an interest in obtaining information about us or our
                products and Services, when you participate in activities on the
                Services, or otherwise when you contact us.
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Personal Information Provided by You.</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">names</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">phone numbers</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">email addresses</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">usernames</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">passwords</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <ul>
        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="question">Date of birth</bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </li>
      </ul>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="forloop-component" />
                </span>
                <span data-custom-class="body_text">
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>Sensitive Information.</strong>{" "}
            <bdt className="block-component" />
            We do not process sensitive information.
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="else-block" />
          </span>
        </span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component">
                    <bdt className="block-component" />
                  </bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>Social Media Login Data.&nbsp;</strong>We may provide you
              with the option to register with us using your existing social
              media account details, like your Facebook, X, or other social
              media account. If you choose to register in this way, we will
              collect certain profile information about you from the social
              media provider, as described in the section called{" "}
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" />
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(183, 54, 82)" }}>
                      <a data-custom-class="link" href="#sociallogins">
                        HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                      </a>
                    </span>
                  </span>
                </span>
              </span>
              <bdt className="block-component" />"
              <bdt className="statement-end-if-in-editor" /> below.
            </span>
          </span>
        </span>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="statement-end-if-in-editor">
                  <bdt className="statement-end-if-in-editor" />
                </bdt>
              </span>
            </span>
          </span>
        </span>
        <bdt className="block-component">
          <bdt className="block-component" />
        </bdt>
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="body_text">
          <span style={{ fontSize: 15 }}>
            <strong>Application Data.</strong> If you use our application(s), we
            also may collect the following information if you choose to provide
            us with access or permission:
            <bdt className="block-component" />
          </span>
        </span>
        <div style={{ lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
          <div style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text" />
              </span>
            </bdt>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </bdt>
            </div>
            <ul>
              <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <em>Push Notifications.</em> We may request to send you push
                    notifications regarding your account or certain features of
                    the application(s). If you wish to opt out from receiving
                    these types of communications, you may turn them off in your
                    device's settings.
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  This information is primarily needed to maintain the security
                  and operation of our application(s), for troubleshooting, and
                  for our internal analytics and reporting purposes.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="statement-end-if-in-editor">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </bdt>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: 15 }}>
                  <strong>
                    <h3>Information automatically collected</h3>
                  </strong>
                </span>
              </span>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>
                            Some information — such as your Internet Protocol
                            (IP) address and/or browser and device
                            characteristics — is collected automatically when
                            you visit our Services.
                          </em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Like many businesses, we also collect information through
                    cookies and similar technologies.{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <span data-custom-class="body_text" />
                    </bdt>
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The information we collect includes:
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Log and Usage Data.</em> Log and usage data is
                      service-related, diagnostic, usage, and performance
                      information our servers automatically collect when you
                      access or use our Services and which we record in log
                      files. Depending on how you interact with us, this log
                      data may include your IP address, device information,
                      browser type, and settings and information about your
                      activity in the Services
                      <span style={{ fontSize: 15 }}>&nbsp;</span>(such as the
                      date/time stamps associated with your usage, pages and
                      files viewed, searches, and other actions you take such as
                      which features you use), device event information (such as
                      system activity, error reports (sometimes called{" "}
                      <bdt className="block-component" />
                      "crash dumps"
                      <bdt className="statement-end-if-in-editor" />
                      ), and hardware settings).
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </bdt>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <em>Location Data.</em> We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Services. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        However, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <strong>
                    <span data-custom-class="heading_2">
                      <h3>Google API</h3>
                    </span>
                  </strong>
                  <span data-custom-class="body_text">
                    Our use of information received from Google APIs will adhere
                    to&nbsp;
                  </span>
                </span>
                <a
                  data-custom-class="link"
                  href="https://developers.google.com/terms/api-services-user-data-policy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      Google API Services User Data Policy
                    </span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    , including the&nbsp;
                  </span>
                </span>
                <a
                  data-custom-class="link"
                  href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      Limited Use requirements
                    </span>
                  </span>
                </a>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">.</span>
                  <br />
                </span>
              </div>
              <div>
                <span style={{ fontSize: 15 }}>
                  <br />
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="statement-end-if-in-editor">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span
                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                          >
                            <span data-custom-class="body_text">
                              <bdt className="statement-end-if-in-editor">
                                <bdt className="block-component" />
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="infouse" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span data-custom-class="heading_1">
                              <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                            <em>
                              We process your information to provide, improve,
                              and administer our Services, communicate with you,
                              for security and fraud prevention, and to comply
                              with law. We may also process your information for
                              other purposes with your consent.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                      </strong>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </div>
              <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <strong>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </strong>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span data-custom-class="body_text">
                                          <bdt className="statement-end-if-in-editor" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>
                            To deliver and facilitate delivery of services to
                            the user.&nbsp;
                          </strong>
                          We may process your information to provide you with
                          the requested service.
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)"
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }} />
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </bdt>
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component" />
                            </p>
                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                              <bdt className="block-component" />
                            </p>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }} />
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }} />
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: 15 }}>
                                      <bdt className="block-component">
                                        <span data-custom-class="body_text" />
                                      </bdt>
                                    </span>
                                  </div>
                                  <ul>
                                    <li
                                      data-custom-class="body_text"
                                      style={{ lineHeight: "1.5" }}
                                    >
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          <strong>
                                            To deliver targeted advertising to
                                            you.
                                          </strong>{" "}
                                          We may process your information to
                                          develop and display{" "}
                                          <bdt className="block-component" />
                                          personalized
                                          <bdt className="statement-end-if-in-editor" />{" "}
                                          content and advertising tailored to
                                          your interests, location, and more.
                                          <bdt className="block-component" />
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <strong>
                                                        To identify usage
                                                        trends.
                                                      </strong>{" "}
                                                      We may process information
                                                      about how you use our
                                                      Services to better
                                                      understand how they are
                                                      being used so we can
                                                      improve them.
                                                    </span>
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </li>
                                              </ul>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                </div>
                                                <ul>
                                                  <li
                                                    data-custom-class="body_text"
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          To determine the
                                                          effectiveness of our
                                                          marketing and
                                                          promotional campaigns.
                                                        </strong>{" "}
                                                        We may process your
                                                        information to better
                                                        understand how to
                                                        provide marketing and
                                                        promotional campaigns
                                                        that are most relevant
                                                        to you.
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <strong>
                                                            To save or protect
                                                            an individual's
                                                            vital interest.
                                                          </strong>{" "}
                                                          We may process your
                                                          information when
                                                          necessary to save or
                                                          protect an
                                                          individual’s vital
                                                          interest, such as to
                                                          prevent harm.
                                                        </span>
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="legalbases"
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <strong>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="heading_1">
                                                          <h2>
                                                            3. WHAT LEGAL BASES
                                                            DO WE RELY ON TO
                                                            PROCESS YOUR
                                                            INFORMATION?
                                                          </h2>
                                                        </span>
                                                      </span>
                                                    </strong>
                                                    <em>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            In Short:&nbsp;
                                                          </strong>
                                                          We only process your
                                                          personal information
                                                          when we believe it is
                                                          necessary and we have
                                                          a valid legal reason
                                                          (i.e.
                                                          <bdt className="block-component" />
                                                          ,
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          legal basis) to do so
                                                          under applicable law,
                                                          like with your
                                                          consent, to comply
                                                          with laws, to provide
                                                          you with services to
                                                          enter into or{" "}
                                                          <bdt className="block-component" />
                                                          fulfill
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          our contractual
                                                          obligations, to
                                                          protect your rights,
                                                          or to{" "}
                                                          <bdt className="block-component" />
                                                          fulfill
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          our legitimate
                                                          business interests.
                                                        </span>
                                                      </span>
                                                    </em>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <em>
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              If you are located
                                                              in the EU or UK,
                                                              this section
                                                              applies to you.
                                                            </u>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </em>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        The General Data
                                                        Protection Regulation
                                                        (GDPR) and UK GDPR
                                                        require us to explain
                                                        the valid legal bases we
                                                        rely on in order to
                                                        process your personal
                                                        information. As such, we
                                                        may rely on the
                                                        following legal bases to
                                                        process your personal
                                                        information:
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Consent.&nbsp;
                                                          </strong>
                                                          We may process your
                                                          information if you
                                                          have given us
                                                          permission (i.e.
                                                          <bdt className="block-component" />
                                                          ,
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          consent) to use your
                                                          personal information
                                                          for a specific
                                                          purpose. You can
                                                          withdraw your consent
                                                          at any time. Learn
                                                          more about&nbsp;
                                                        </span>
                                                      </span>
                                                      <a
                                                        data-custom-class="link"
                                                        href="#withdrawconsent"
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(183, 54, 82)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            withdrawing your
                                                            consent
                                                          </span>
                                                        </span>
                                                      </a>
                                                      <span data-custom-class="body_text">
                                                        .
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <strong>
                                                            Performance of a
                                                            Contract.
                                                          </strong>{" "}
                                                          We may process your
                                                          personal information
                                                          when we believe it is
                                                          necessary to{" "}
                                                          <bdt className="block-component" />
                                                          fulfill
                                                          <bdt className="statement-end-if-in-editor" />{" "}
                                                          our contractual
                                                          obligations to you,
                                                          including providing
                                                          our Services or at
                                                          your request prior to
                                                          entering into a
                                                          contract with you.
                                                        </span>
                                                      </span>
                                                      <bdt className="statement-end-if-in-editor">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </bdt>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <ul>
                                                    <li
                                                      data-custom-class="body_text"
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <strong>
                                                            Legitimate
                                                            Interests.
                                                          </strong>{" "}
                                                          We may process your
                                                          information when we
                                                          believe it is
                                                          reasonably necessary
                                                          to achieve our
                                                          legitimate business
                                                          interests and those
                                                          interests do not
                                                          outweigh your
                                                          interests and
                                                          fundamental rights and
                                                          freedoms. For example,
                                                          we may process your
                                                          personal information
                                                          for some of the
                                                          purposes described in
                                                          order to:
                                                        </span>
                                                      </span>
                                                    </li>
                                                  </ul>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{ marginLeft: 40 }}
                                                    >
                                                      <li
                                                        data-custom-class="body_text"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            Develop and display{" "}
                                                            <bdt className="block-component" />
                                                            personalized
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            and relevant
                                                            advertising content
                                                            for our users
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{ marginLeft: 40 }}
                                                    >
                                                      <li
                                                        data-custom-class="body_text"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <bdt className="block-component" />
                                                            Analyze
                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                            how our Services are
                                                            used so we can
                                                            improve them to
                                                            engage and retain
                                                            users
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul
                                                      style={{ marginLeft: 40 }}
                                                    >
                                                      <li
                                                        data-custom-class="body_text"
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            Support our
                                                            marketing activities
                                                            <bdt className="statement-end-if-in-editor" />
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  Legal
                                                                  Obligations.
                                                                </strong>{" "}
                                                                We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                for compliance
                                                                with our legal
                                                                obligations,
                                                                such as to
                                                                cooperate with a
                                                                law enforcement
                                                                body or
                                                                regulatory
                                                                agency, exercise
                                                                or defend our
                                                                legal rights, or
                                                                disclose your
                                                                information as
                                                                evidence in
                                                                litigation in
                                                                which we are
                                                                involved.
                                                                <bdt className="statement-end-if-in-editor" />
                                                                <br />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <strong>
                                                                  Vital
                                                                  Interests.
                                                                </strong>{" "}
                                                                We may process
                                                                your information
                                                                where we believe
                                                                it is necessary
                                                                to protect your
                                                                vital interests
                                                                or the vital
                                                                interests of a
                                                                third party,
                                                                such as
                                                                situations
                                                                involving
                                                                potential
                                                                threats to the
                                                                safety of any
                                                                person.
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <strong>
                                                                <u>
                                                                  <em>
                                                                    If you are
                                                                    located in
                                                                    Canada, this
                                                                    section
                                                                    applies to
                                                                    you.
                                                                  </em>
                                                                </u>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              We may process
                                                              your information
                                                              if you have given
                                                              us specific
                                                              permission (i.e.
                                                              <bdt className="block-component" />
                                                              ,
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              express consent)
                                                              to use your
                                                              personal
                                                              information for a
                                                              specific purpose,
                                                              or in situations
                                                              where your
                                                              permission can be
                                                              inferred (i.e.
                                                              <bdt className="block-component" />
                                                              ,
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              implied consent).
                                                              You can&nbsp;
                                                            </span>
                                                          </span>
                                                          <a
                                                            data-custom-class="link"
                                                            href="#withdrawconsent"
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(183, 54, 82)",
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                withdraw your
                                                                consent
                                                              </span>
                                                            </span>
                                                          </a>
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              &nbsp;at any time.
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              In some
                                                              exceptional cases,
                                                              we may be legally
                                                              permitted under
                                                              applicable law to
                                                              process your
                                                              information
                                                              without your
                                                              consent,
                                                              including, for
                                                              example:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If collection is
                                                                clearly in the
                                                                interests of an
                                                                individual and
                                                                consent cannot
                                                                be obtained in a
                                                                timely way
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                For
                                                                investigations
                                                                and fraud
                                                                detection and
                                                                prevention
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                For business
                                                                transactions
                                                                provided certain
                                                                conditions are
                                                                met
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If it is
                                                                contained in a
                                                                witness
                                                                statement and
                                                                the collection
                                                                is necessary to
                                                                assess, process,
                                                                or settle an
                                                                insurance claim
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                For identifying
                                                                injured, ill, or
                                                                deceased persons
                                                                and
                                                                communicating
                                                                with next of kin
                                                              </span>
                                                            </span>
                                                            <bdt className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If we have
                                                                reasonable
                                                                grounds to
                                                                believe an
                                                                individual has
                                                                been, is, or may
                                                                be victim of
                                                                financial abuse
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If it is
                                                                reasonable to
                                                                expect
                                                                collection and
                                                                use with consent
                                                                would compromise
                                                                the availability
                                                                or the accuracy
                                                                of the
                                                                information and
                                                                the collection
                                                                is reasonable
                                                                for purposes
                                                                related to
                                                                investigating a
                                                                breach of an
                                                                agreement or a
                                                                contravention of
                                                                the laws of
                                                                Canada or a
                                                                province
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                If disclosure is
                                                                required to
                                                                comply with a
                                                                subpoena,
                                                                warrant, court
                                                                order, or rules
                                                                of the court
                                                                relating to the
                                                                production of
                                                                records
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If it was
                                                                produced by an
                                                                individual in
                                                                the course of
                                                                their
                                                                employment,
                                                                business, or
                                                                profession and
                                                                the collection
                                                                is consistent
                                                                with the
                                                                purposes for
                                                                which the
                                                                information was
                                                                produced
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If the
                                                                collection is
                                                                solely for
                                                                journalistic,
                                                                artistic, or
                                                                literary
                                                                purposes
                                                                <bdt className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                If the
                                                                information is
                                                                publicly
                                                                available and is
                                                                specified by the
                                                                regulations
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span data-custom-class="body_text" />
                                                              </bdt>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </bdt>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          id="whoshare"
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(127, 127, 127)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    id="control"
                                                                    style={{
                                                                      color:
                                                                        "rgb(0, 0, 0)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span data-custom-class="heading_1">
                                                                        <h2>
                                                                          4.
                                                                          WHEN
                                                                          AND
                                                                          WITH
                                                                          WHOM
                                                                          DO WE
                                                                          SHARE
                                                                          YOUR
                                                                          PERSONAL
                                                                          INFORMATION?
                                                                        </h2>
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <em>
                                                                    In Short:
                                                                  </em>
                                                                </strong>
                                                                <em>
                                                                  &nbsp;We may
                                                                  share
                                                                  information in
                                                                  specific
                                                                  situations
                                                                  described in
                                                                  this section
                                                                  and/or with
                                                                  the following{" "}
                                                                  <bdt className="block-component" />
                                                                  third parties.
                                                                </em>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Vendors,
                                                                Consultants, and
                                                                Other
                                                                Third-Party
                                                                Service
                                                                Providers.
                                                              </strong>{" "}
                                                              We may share your
                                                              data with
                                                              third-party
                                                              vendors, service
                                                              providers,
                                                              contractors, or
                                                              agents (
                                                              <bdt className="block-component" />
                                                              "
                                                              <strong>
                                                                third parties
                                                              </strong>
                                                              "
                                                              <bdt className="statement-end-if-in-editor" />
                                                              ) who perform
                                                              services for us or
                                                              on our behalf and
                                                              require access to
                                                              such information
                                                              to do that work.{" "}
                                                              <bdt className="block-component" />
                                                              We have contracts
                                                              in place with our
                                                              third parties,
                                                              which are designed
                                                              to help safeguard
                                                              your personal
                                                              information. This
                                                              means that they
                                                              cannot do anything
                                                              with your personal
                                                              information unless
                                                              we have instructed
                                                              them to do it.
                                                              They will also not
                                                              share your
                                                              personal
                                                              information with
                                                              any{" "}
                                                              <bdt className="block-component" />
                                                              organization
                                                              <bdt className="statement-end-if-in-editor" />{" "}
                                                              apart from us.
                                                              They also commit
                                                              to pr
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              otect the data
                                                              they hold on our
                                                              behalf and to
                                                              retain it for the
                                                              period we
                                                              instruct.
                                                              <bdt className="statement-end-if-in-editor" />
                                                            </span>
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              The{" "}
                                                              <bdt className="block-component" />
                                                              third parties we
                                                              may share personal
                                                              information with
                                                              are as follows:
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    Advertising,
                                                                    Direct
                                                                    Marketing,
                                                                    and Lead
                                                                    Generation
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            marginLeft: 40
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </bdt>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                          <bdt className="question">
                                                                            Google
                                                                            AdSense
                                                                          </bdt>
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="forloop-component" />
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="block-component" />
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <bdt className="block-component" />
                                                          </span>
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    Retargeting
                                                                    Platforms
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            marginLeft: 40
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </bdt>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                          <bdt className="question">
                                                                            Google
                                                                            Analytics
                                                                            Remarketing
                                                                          </bdt>
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>{" "}
                                                                  and{" "}
                                                                  <bdt className="question">
                                                                    Google Ads
                                                                    Remarketing{" "}
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="forloop-component" />
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    Web and
                                                                    Mobile
                                                                    Analytics
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            marginLeft: 40
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </bdt>
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                          <bdt className="question">
                                                                            Google
                                                                            Analytics
                                                                          </bdt>
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="forloop-component" />
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>{" "}
                                                                  and{" "}
                                                                  <bdt className="question">
                                                                    Google Ads
                                                                  </bdt>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <bdt className="forloop-component" />
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <bdt className="block-component" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                          <span data-custom-class="body_text" />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <br />
                                                        </div>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              We{" "}
                                                              <bdt className="block-component" />
                                                              also{" "}
                                                              <bdt className="statement-end-if-in-editor" />
                                                              may need to share
                                                              your personal
                                                              information in the
                                                              following
                                                              situations:
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            data-custom-class="body_text"
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Business
                                                                  Transfers.
                                                                </strong>{" "}
                                                                We may share or
                                                                transfer your
                                                                information in
                                                                connection with,
                                                                or during
                                                                negotiations of,
                                                                any merger, sale
                                                                of company
                                                                assets,
                                                                financing, or
                                                                acquisition of
                                                                all or a portion
                                                                of our business
                                                                to another
                                                                company.
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </span>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span data-custom-class="body_text" />
                                                              </bdt>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              data-custom-class="body_text"
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    Affiliates.&nbsp;
                                                                  </strong>
                                                                  We may share
                                                                  your
                                                                  information
                                                                  with our
                                                                  affiliates, in
                                                                  which case we
                                                                  will require
                                                                  those
                                                                  affiliates to{" "}
                                                                  <bdt className="block-component" />
                                                                  honor
                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                  this Privacy
                                                                  Notice.
                                                                  Affiliates
                                                                  include our
                                                                  parent company
                                                                  and any
                                                                  subsidiaries,
                                                                  joint venture
                                                                  partners, or
                                                                  other
                                                                  companies that
                                                                  we control or
                                                                  that are under
                                                                  common control
                                                                  with us.
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span data-custom-class="body_text" />
                                                                </bdt>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              data-custom-class="body_text"
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    Business
                                                                    Partners.
                                                                  </strong>{" "}
                                                                  We may share
                                                                  your
                                                                  information
                                                                  with our
                                                                  business
                                                                  partners to
                                                                  offer you
                                                                  certain
                                                                  products,
                                                                  services, or
                                                                  promotions.
                                                                </span>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5"
                                                            }}
                                                          >
                                                            <bdt className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </bdt>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.5"
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </span>
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                              </bdt>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span
                                                                    id="3pwebsites"
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="heading_1">
                                                                      <h2>
                                                                        5. WHAT
                                                                        IS OUR
                                                                        STANCE
                                                                        ON
                                                                        THIRD-PARTY
                                                                        WEBSITES?
                                                                      </h2>
                                                                    </span>
                                                                  </span>
                                                                </strong>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <strong>
                                                                      <em>
                                                                        In
                                                                        Short:
                                                                      </em>
                                                                    </strong>
                                                                    <em>
                                                                      &nbsp;We
                                                                      are not
                                                                      responsible
                                                                      for the
                                                                      safety of
                                                                      any
                                                                      information
                                                                      that you
                                                                      share with
                                                                      third
                                                                      parties
                                                                      that we
                                                                      may link
                                                                      to or who
                                                                      advertise
                                                                      on our
                                                                      Services,
                                                                      but are
                                                                      not
                                                                      affiliated
                                                                      with, our
                                                                      Services.
                                                                    </em>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    The Services
                                                                    <bdt className="block-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    may link to
                                                                    third-party
                                                                    websites,
                                                                    online
                                                                    services, or
                                                                    mobile
                                                                    applications
                                                                    and/or
                                                                    contain
                                                                    advertisements
                                                                    from third
                                                                    parties that
                                                                    are not
                                                                    affiliated
                                                                    with us and
                                                                    which may
                                                                    link to
                                                                    other
                                                                    websites,
                                                                    services, or
                                                                    applications.
                                                                    Accordingly,
                                                                    we do not
                                                                    make any
                                                                    guarantee
                                                                    regarding
                                                                    any such
                                                                    third
                                                                    parties, and
                                                                    we will not
                                                                    be liable
                                                                    for any loss
                                                                    or damage
                                                                    caused by
                                                                    the use of
                                                                    such
                                                                    third-party
                                                                    websites,
                                                                    services, or
                                                                    applications.
                                                                    The
                                                                    inclusion of
                                                                    a link
                                                                    towards a
                                                                    third-party
                                                                    website,
                                                                    service, or
                                                                    application
                                                                    does not
                                                                    imply an
                                                                    endorsement
                                                                    by us. We
                                                                    cannot
                                                                    guarantee
                                                                    the safety
                                                                    and privacy
                                                                    of data you
                                                                    provide to
                                                                    any
                                                                    third-party
                                                                    websites.
                                                                    Any data
                                                                    collected by
                                                                    third
                                                                    parties is
                                                                    not covered
                                                                    by this
                                                                    Privacy
                                                                    Notice. We
                                                                    are not
                                                                    responsible
                                                                    for the
                                                                    content or
                                                                    privacy and
                                                                    security
                                                                    practices
                                                                    and policies
                                                                    of any third
                                                                    parties,
                                                                    including
                                                                    other
                                                                    websites,
                                                                    services, or
                                                                    applications
                                                                    that may be
                                                                    linked to or
                                                                    from the
                                                                    Services.
                                                                    You should
                                                                    review the
                                                                    policies of
                                                                    such third
                                                                    parties and
                                                                    contact them
                                                                    directly to
                                                                    respond to
                                                                    your
                                                                    questions.
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component">
                                                                            <span data-custom-class="heading_1">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                          </bdt>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="cookies"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                6.
                                                                                DO
                                                                                WE
                                                                                USE
                                                                                COOKIES
                                                                                AND
                                                                                OTHER
                                                                                TRACKING
                                                                                TECHNOLOGIES?
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        &nbsp;We
                                                                        may use
                                                                        cookies
                                                                        and
                                                                        other
                                                                        tracking
                                                                        technologies
                                                                        to
                                                                        collect
                                                                        and
                                                                        store
                                                                        your
                                                                        information.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We may use
                                                                      cookies
                                                                      and
                                                                      similar
                                                                      tracking
                                                                      technologies
                                                                      (like web
                                                                      beacons
                                                                      and
                                                                      pixels) to
                                                                      gather
                                                                      information
                                                                      when you
                                                                      interact
                                                                      with our
                                                                      Services.
                                                                      Some
                                                                      online
                                                                      tracking
                                                                      technologies
                                                                      help us
                                                                      maintain
                                                                      the
                                                                      security
                                                                      of our
                                                                      Services
                                                                      <bdt className="block-component" />{" "}
                                                                      and your
                                                                      account
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                      , prevent
                                                                      crashes,
                                                                      fix bugs,
                                                                      save your
                                                                      preferences,
                                                                      and assist
                                                                      with basic
                                                                      site
                                                                      functions.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We also
                                                                      permit
                                                                      third
                                                                      parties
                                                                      and
                                                                      service
                                                                      providers
                                                                      to use
                                                                      online
                                                                      tracking
                                                                      technologies
                                                                      on our
                                                                      Services
                                                                      for
                                                                      analytics
                                                                      and
                                                                      advertising,
                                                                      including
                                                                      to help
                                                                      manage and
                                                                      display
                                                                      advertisements,
                                                                      to tailor
                                                                      advertisements
                                                                      to your
                                                                      interests,
                                                                      or to send
                                                                      abandoned
                                                                      shopping
                                                                      cart
                                                                      reminders
                                                                      (depending
                                                                      on your
                                                                      communication
                                                                      preferences).
                                                                      The third
                                                                      parties
                                                                      and
                                                                      service
                                                                      providers
                                                                      use their
                                                                      technology
                                                                      to provide
                                                                      advertising
                                                                      about
                                                                      products
                                                                      and
                                                                      services
                                                                      tailored
                                                                      to your
                                                                      interests
                                                                      which may
                                                                      appear
                                                                      either on
                                                                      our
                                                                      Services
                                                                      or on
                                                                      other
                                                                      websites.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <br />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    To the
                                                                    extent these
                                                                    online
                                                                    tracking
                                                                    technologies
                                                                    are deemed
                                                                    to be a{" "}
                                                                    <bdt className="block-component" />
                                                                    "sale"/"sharing"
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    (which
                                                                    includes
                                                                    targeted
                                                                    advertising,
                                                                    as defined
                                                                    under the
                                                                    applicable
                                                                    laws) under
                                                                    applicable
                                                                    US state
                                                                    laws, you
                                                                    can opt out
                                                                    of these
                                                                    online
                                                                    tracking
                                                                    technologies
                                                                    by
                                                                    submitting a
                                                                    request as
                                                                    described
                                                                    below under
                                                                    section{" "}
                                                                    <bdt className="block-component" />
                                                                    "
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <a
                                                                    data-custom-class="link"
                                                                    href="#uslaws"
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(183, 54, 82)",
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      DO UNITED
                                                                      STATES
                                                                      RESIDENTS
                                                                      HAVE
                                                                      SPECIFIC
                                                                      PRIVACY
                                                                      RIGHTS?
                                                                    </span>
                                                                  </a>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component" />
                                                                    "
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Specific
                                                                      information
                                                                      about how
                                                                      we use
                                                                      such
                                                                      technologies
                                                                      and how
                                                                      you can
                                                                      refuse
                                                                      certain
                                                                      cookies is
                                                                      set out in
                                                                      our Cookie
                                                                      Notice
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                          .
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <strong>
                                                                    <span data-custom-class="heading_2">
                                                                      <h3>
                                                                        Google
                                                                        Analytics
                                                                      </h3>
                                                                    </span>
                                                                  </strong>
                                                                  &nbsp;
                                                                  <span data-custom-class="body_text">
                                                                    We may share
                                                                    your
                                                                    information
                                                                    with Google
                                                                    Analytics to
                                                                    track and{" "}
                                                                    <bdt className="block-component" />
                                                                    analyze
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    the use of
                                                                    the
                                                                    Services.
                                                                    <bdt className="block-component" />{" "}
                                                                    The Google
                                                                    Analytics
                                                                    Advertising
                                                                    Features
                                                                    that we may
                                                                    use include:{" "}
                                                                    <bdt className="forloop-component" />
                                                                    <bdt className="block-component" />
                                                                    <bdt className="question">
                                                                      Google
                                                                      Analytics
                                                                      Demographics
                                                                      and
                                                                      Interests
                                                                      Reporting
                                                                    </bdt>
                                                                    <bdt className="block-component" />
                                                                    <bdt className="forloop-component" />
                                                                    <bdt className="block-component" />{" "}
                                                                    and{" "}
                                                                    <bdt className="question">
                                                                      Remarketing
                                                                      with
                                                                      Google
                                                                      Analytics
                                                                    </bdt>
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                    <bdt className="forloop-component" />
                                                                    .
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    To opt out
                                                                    of being
                                                                    tracked by
                                                                    Google
                                                                    Analytics
                                                                    across the
                                                                    Services,
                                                                    visit{" "}
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://tools.google.com/dlpage/gaoptout"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        https://tools.google.com/dlpage/gaoptout
                                                                      </a>
                                                                      .
                                                                    </span>
                                                                    <bdt className="block-component" />{" "}
                                                                    You can opt
                                                                    out of
                                                                    Google
                                                                    Analytics
                                                                    Advertising
                                                                    Features
                                                                    through{" "}
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://adssettings.google.com/"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        Ads
                                                                        Settings
                                                                      </a>
                                                                    </span>{" "}
                                                                    and Ad
                                                                    Settings for
                                                                    mobile apps.
                                                                    Other opt
                                                                    out means
                                                                    include{" "}
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rbg(0, 58, 250)"
                                                                        }}
                                                                      >
                                                                        <a
                                                                          data-custom-class="link"
                                                                          href="http://optout.networkadvertising.org/"
                                                                          rel="noopener noreferrer"
                                                                          target="_blank"
                                                                        >
                                                                          http://optout.networkadvertising.org/
                                                                        </a>
                                                                      </span>
                                                                    </span>{" "}
                                                                    and{" "}
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="http://www.networkadvertising.org/mobile-choice"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        http://www.networkadvertising.org/mobile-choice
                                                                      </a>
                                                                    </span>
                                                                    .
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    For more
                                                                    information
                                                                    on the
                                                                    privacy
                                                                    practices of
                                                                    Google,
                                                                    please visit
                                                                    the{" "}
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://policies.google.com/privacy"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        Google
                                                                        Privacy
                                                                        &amp;
                                                                        Terms
                                                                        page
                                                                      </a>
                                                                    </span>
                                                                    .
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="block-component" />
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <bdt className="block-component" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="sociallogins"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                7.
                                                                                HOW
                                                                                DO
                                                                                WE
                                                                                HANDLE
                                                                                YOUR
                                                                                SOCIAL
                                                                                LOGINS?
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        If you
                                                                        choose
                                                                        to
                                                                        register
                                                                        or log
                                                                        in to
                                                                        our
                                                                        Services
                                                                        using a
                                                                        social
                                                                        media
                                                                        account,
                                                                        we may
                                                                        have
                                                                        access
                                                                        to
                                                                        certain
                                                                        information
                                                                        about
                                                                        you.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Our
                                                                      Services
                                                                      offer you
                                                                      the
                                                                      ability to
                                                                      register
                                                                      and log in
                                                                      using your
                                                                      third-party
                                                                      social
                                                                      media
                                                                      account
                                                                      details
                                                                      (like your
                                                                      Facebook
                                                                      or X
                                                                      logins).
                                                                      Where you
                                                                      choose to
                                                                      do this,
                                                                      we will
                                                                      receive
                                                                      certain
                                                                      profile
                                                                      information
                                                                      about you
                                                                      from your
                                                                      social
                                                                      media
                                                                      provider.
                                                                      The
                                                                      profile
                                                                      information
                                                                      we receive
                                                                      may vary
                                                                      depending
                                                                      on the
                                                                      social
                                                                      media
                                                                      provider
                                                                      concerned,
                                                                      but will
                                                                      often
                                                                      include
                                                                      your name,
                                                                      email
                                                                      address,
                                                                      friends
                                                                      list, and
                                                                      profile
                                                                      picture,
                                                                      as well as
                                                                      other
                                                                      information
                                                                      you choose
                                                                      to make
                                                                      public on
                                                                      such a
                                                                      social
                                                                      media
                                                                      platform.
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We will
                                                                      use the
                                                                      information
                                                                      we receive
                                                                      only for
                                                                      the
                                                                      purposes
                                                                      that are
                                                                      described
                                                                      in this
                                                                      Privacy
                                                                      Notice or
                                                                      that are
                                                                      otherwise
                                                                      made clear
                                                                      to you on
                                                                      the
                                                                      relevant
                                                                      Services.
                                                                      Please
                                                                      note that
                                                                      we do not
                                                                      control,
                                                                      and are
                                                                      not
                                                                      responsible
                                                                      for, other
                                                                      uses of
                                                                      your
                                                                      personal
                                                                      information
                                                                      by your
                                                                      third-party
                                                                      social
                                                                      media
                                                                      provider.
                                                                      We
                                                                      recommend
                                                                      that you
                                                                      review
                                                                      their
                                                                      privacy
                                                                      notice to
                                                                      understand
                                                                      how they
                                                                      collect,
                                                                      use, and
                                                                      share your
                                                                      personal
                                                                      information,
                                                                      and how
                                                                      you can
                                                                      set your
                                                                      privacy
                                                                      preferences
                                                                      on their
                                                                      sites and
                                                                      apps.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                      <bdt className="block-component">
                                                                                        <span data-custom-class="body_text">
                                                                                          <bdt className="block-component" />
                                                                                        </span>
                                                                                      </bdt>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="inforetain"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                8.
                                                                                HOW
                                                                                LONG
                                                                                DO
                                                                                WE
                                                                                KEEP
                                                                                YOUR
                                                                                INFORMATION?
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        We keep
                                                                        your
                                                                        information
                                                                        for as
                                                                        long as
                                                                        necessary
                                                                        to{" "}
                                                                        <bdt className="block-component" />
                                                                        fulfill
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        the
                                                                        purposes
                                                                        outlined
                                                                        in this
                                                                        Privacy
                                                                        Notice
                                                                        unless
                                                                        otherwise
                                                                        required
                                                                        by law.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We will
                                                                      only keep
                                                                      your
                                                                      personal
                                                                      information
                                                                      for as
                                                                      long as it
                                                                      is
                                                                      necessary
                                                                      for the
                                                                      purposes
                                                                      set out in
                                                                      this
                                                                      Privacy
                                                                      Notice,
                                                                      unless a
                                                                      longer
                                                                      retention
                                                                      period is
                                                                      required
                                                                      or
                                                                      permitted
                                                                      by law
                                                                      (such as
                                                                      tax,
                                                                      accounting,
                                                                      or other
                                                                      legal
                                                                      requirements).
                                                                      <bdt className="block-component" />{" "}
                                                                      No purpose
                                                                      in this
                                                                      notice
                                                                      will
                                                                      require us
                                                                      keeping
                                                                      your
                                                                      personal
                                                                      information
                                                                      for longer
                                                                      than{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      <bdt className="block-component" />
                                                                      the period
                                                                      of time in
                                                                      which
                                                                      users have
                                                                      an account
                                                                      with us
                                                                      <bdt className="block-component" />
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="else-block" />
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      When we
                                                                      have no
                                                                      ongoing
                                                                      legitimate
                                                                      business
                                                                      need to
                                                                      process
                                                                      your
                                                                      personal
                                                                      information,
                                                                      we will
                                                                      either
                                                                      delete or{" "}
                                                                      <bdt className="block-component" />
                                                                      anonymize
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      such
                                                                      information,
                                                                      or, if
                                                                      this is
                                                                      not
                                                                      possible
                                                                      (for
                                                                      example,
                                                                      because
                                                                      your
                                                                      personal
                                                                      information
                                                                      has been
                                                                      stored in
                                                                      backup
                                                                      archives),
                                                                      then we
                                                                      will
                                                                      securely
                                                                      store your
                                                                      personal
                                                                      information
                                                                      and
                                                                      isolate it
                                                                      from any
                                                                      further
                                                                      processing
                                                                      until
                                                                      deletion
                                                                      is
                                                                      possible.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="infosafe"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                9.
                                                                                HOW
                                                                                DO
                                                                                WE
                                                                                KEEP
                                                                                YOUR
                                                                                INFORMATION
                                                                                SAFE?
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        We aim
                                                                        to
                                                                        protect
                                                                        your
                                                                        personal
                                                                        information
                                                                        through
                                                                        a system
                                                                        of{" "}
                                                                        <bdt className="block-component" />
                                                                        organizational
                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                        and
                                                                        technical
                                                                        security
                                                                        measures.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We have
                                                                      implemented
                                                                      appropriate
                                                                      and
                                                                      reasonable
                                                                      technical
                                                                      and{" "}
                                                                      <bdt className="block-component" />
                                                                      organizational
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      security
                                                                      measures
                                                                      designed
                                                                      to protect
                                                                      the
                                                                      security
                                                                      of any
                                                                      personal
                                                                      information
                                                                      we
                                                                      process.
                                                                      However,
                                                                      despite
                                                                      our
                                                                      safeguards
                                                                      and
                                                                      efforts to
                                                                      secure
                                                                      your
                                                                      information,
                                                                      no
                                                                      electronic
                                                                      transmission
                                                                      over the
                                                                      Internet
                                                                      or
                                                                      information
                                                                      storage
                                                                      technology
                                                                      can be
                                                                      guaranteed
                                                                      to be 100%
                                                                      secure, so
                                                                      we cannot
                                                                      promise or
                                                                      guarantee
                                                                      that
                                                                      hackers,
                                                                      cybercriminals,
                                                                      or other{" "}
                                                                      <bdt className="block-component" />
                                                                      unauthorized
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      third
                                                                      parties
                                                                      will not
                                                                      be able to
                                                                      defeat our
                                                                      security
                                                                      and
                                                                      improperly
                                                                      collect,
                                                                      access,
                                                                      steal, or
                                                                      modify
                                                                      your
                                                                      information.
                                                                      Although
                                                                      we will do
                                                                      our best
                                                                      to protect
                                                                      your
                                                                      personal
                                                                      information,
                                                                      transmission
                                                                      of
                                                                      personal
                                                                      information
                                                                      to and
                                                                      from our
                                                                      Services
                                                                      is at your
                                                                      own risk.
                                                                      You should
                                                                      only
                                                                      access the
                                                                      Services
                                                                      within a
                                                                      secure
                                                                      environment.
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="privacyrights"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                10.
                                                                                WHAT
                                                                                ARE
                                                                                YOUR
                                                                                PRIVACY
                                                                                RIGHTS?
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        &nbsp;
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <em>
                                                                                <bdt className="block-component" />
                                                                              </em>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                        <bdt className="block-component" />
                                                                        Depending
                                                                        on your
                                                                        state of
                                                                        residence
                                                                        in the
                                                                        US or in{" "}
                                                                        <bdt className="else-block" />
                                                                        some
                                                                        regions,
                                                                        such as{" "}
                                                                        <bdt className="block-component" />
                                                                        the
                                                                        European
                                                                        Economic
                                                                        Area
                                                                        (EEA),
                                                                        United
                                                                        Kingdom
                                                                        (UK),
                                                                        Switzerland,
                                                                        and
                                                                        Canada
                                                                        <bdt className="block-component" />
                                                                        , you
                                                                        have
                                                                        rights
                                                                        that
                                                                        allow
                                                                        you
                                                                        greater
                                                                        access
                                                                        to and
                                                                        control
                                                                        over
                                                                        your
                                                                        personal
                                                                        information.
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <em>
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                              </em>
                                                                            </span>
                                                                          </span>
                                                                          &nbsp;
                                                                        </span>
                                                                        You may
                                                                        review,
                                                                        change,
                                                                        or
                                                                        terminate
                                                                        your
                                                                        account
                                                                        at any
                                                                        time,
                                                                        depending
                                                                        on your
                                                                        country,
                                                                        province,
                                                                        or state
                                                                        of
                                                                        residence.
                                                                      </em>
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      In some
                                                                      regions
                                                                      (like{" "}
                                                                      <bdt className="block-component" />
                                                                      the EEA,
                                                                      UK,
                                                                      Switzerland,
                                                                      and Canada
                                                                      <bdt className="block-component" />
                                                                      ), you
                                                                      have
                                                                      certain
                                                                      rights
                                                                      under
                                                                      applicable
                                                                      data
                                                                      protection
                                                                      laws.
                                                                      These may
                                                                      include
                                                                      the right
                                                                      (i) to
                                                                      request
                                                                      access and
                                                                      obtain a
                                                                      copy of
                                                                      your
                                                                      personal
                                                                      information,
                                                                      (ii) to
                                                                      request
                                                                      rectification
                                                                      or
                                                                      erasure;
                                                                      (iii) to
                                                                      restrict
                                                                      the
                                                                      processing
                                                                      of your
                                                                      personal
                                                                      information;
                                                                      (iv) if
                                                                      applicable,
                                                                      to data
                                                                      portability;
                                                                      and (v)
                                                                      not to be
                                                                      subject to
                                                                      automated
                                                                      decision-making.
                                                                      In certain
                                                                      circumstances,
                                                                      you may
                                                                      also have
                                                                      the right
                                                                      to object
                                                                      to the
                                                                      processing
                                                                      of your
                                                                      personal
                                                                      information.
                                                                      You can
                                                                      make such
                                                                      a request
                                                                      by
                                                                      contacting
                                                                      us by
                                                                      using the
                                                                      contact
                                                                      details
                                                                      provided
                                                                      in the
                                                                      section{" "}
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="#contact"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(183, 54, 82)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        HOW CAN
                                                                        YOU
                                                                        CONTACT
                                                                        US ABOUT
                                                                        THIS
                                                                        NOTICE?
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      below.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We will
                                                                      consider
                                                                      and act
                                                                      upon any
                                                                      request in
                                                                      accordance
                                                                      with
                                                                      applicable
                                                                      data
                                                                      protection
                                                                      laws.
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  &nbsp;
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you are
                                                                      located in
                                                                      the EEA or
                                                                      UK and you
                                                                      believe we
                                                                      are
                                                                      unlawfully
                                                                      processing
                                                                      your
                                                                      personal
                                                                      information,
                                                                      you also
                                                                      have the
                                                                      right to
                                                                      complain
                                                                      to your{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(183, 54, 82)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(183, 54, 82)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <a
                                                                                  data-custom-class="link"
                                                                                  href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    Member
                                                                                    State
                                                                                    data
                                                                                    protection
                                                                                    authority
                                                                                  </span>
                                                                                </a>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>{" "}
                                                                      or&nbsp;
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                  rel="noopener noreferrer"
                                                                  target="_blank"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(183, 54, 82)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        UK data
                                                                        protection
                                                                        authority
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      If you are
                                                                      located in
                                                                      Switzerland,
                                                                      you may
                                                                      contact
                                                                      the{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(183, 54, 82)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(183, 54, 82)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(183, 54, 82)",
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <a
                                                                                    data-custom-class="link"
                                                                                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                    rel="noopener noreferrer"
                                                                                    target="_blank"
                                                                                  >
                                                                                    Federal
                                                                                    Data
                                                                                    Protection
                                                                                    and
                                                                                    Information
                                                                                    Commissioner
                                                                                  </a>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="withdrawconsent"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <u>
                                                                          Withdrawing
                                                                          your
                                                                          consent:
                                                                        </u>
                                                                      </strong>{" "}
                                                                      If we are
                                                                      relying on
                                                                      your
                                                                      consent to
                                                                      process
                                                                      your
                                                                      personal
                                                                      information,
                                                                      <bdt className="block-component" />{" "}
                                                                      which may
                                                                      be express
                                                                      and/or
                                                                      implied
                                                                      consent
                                                                      depending
                                                                      on the
                                                                      applicable
                                                                      law,
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      you have
                                                                      the right
                                                                      to
                                                                      withdraw
                                                                      your
                                                                      consent at
                                                                      any time.
                                                                      You can
                                                                      withdraw
                                                                      your
                                                                      consent at
                                                                      any time
                                                                      by
                                                                      contacting
                                                                      us by
                                                                      using the
                                                                      contact
                                                                      details
                                                                      provided
                                                                      in the
                                                                      section{" "}
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <a
                                                                  data-custom-class="link"
                                                                  href="#contact"
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(183, 54, 82)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(183, 54, 82)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        HOW CAN
                                                                        YOU
                                                                        CONTACT
                                                                        US ABOUT
                                                                        THIS
                                                                        NOTICE?
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </a>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                      "
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      below
                                                                      <bdt className="block-component" />
                                                                      .
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    However,
                                                                    please note
                                                                    that this
                                                                    will not
                                                                    affect the
                                                                    lawfulness
                                                                    of the
                                                                    processing
                                                                    before its
                                                                    withdrawal
                                                                    nor,
                                                                    <bdt className="block-component" />{" "}
                                                                    when
                                                                    applicable
                                                                    law allows,
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    will it
                                                                    affect the
                                                                    processing
                                                                    of your
                                                                    personal
                                                                    information
                                                                    conducted in
                                                                    reliance on
                                                                    lawful
                                                                    processing
                                                                    grounds
                                                                    other than
                                                                    consent.
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="heading_2">
                                                                    <strong>
                                                                      <h3>
                                                                        Account
                                                                        Information
                                                                      </h3>
                                                                    </strong>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    If you would
                                                                    at any time
                                                                    like to
                                                                    review or
                                                                    change the
                                                                    information
                                                                    in your
                                                                    account or
                                                                    terminate
                                                                    your
                                                                    account, you
                                                                    can:
                                                                    <bdt className="forloop-component" />
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li
                                                                  data-custom-class="body_text"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <bdt className="question">
                                                                        Log in
                                                                        to your
                                                                        account
                                                                        settings
                                                                        and
                                                                        update
                                                                        your
                                                                        user
                                                                        account.
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <bdt className="forloop-component" />
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    Upon your
                                                                    request to
                                                                    terminate
                                                                    your
                                                                    account, we
                                                                    will
                                                                    deactivate
                                                                    or delete
                                                                    your account
                                                                    and
                                                                    information
                                                                    from our
                                                                    active
                                                                    databases.
                                                                    However, we
                                                                    may retain
                                                                    some
                                                                    information
                                                                    in our files
                                                                    to prevent
                                                                    fraud,
                                                                    troubleshoot
                                                                    problems,
                                                                    assist with
                                                                    any
                                                                    investigations,
                                                                    enforce our
                                                                    legal terms
                                                                    and/or
                                                                    comply with
                                                                    applicable
                                                                    legal
                                                                    requirements.
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <u>
                                                                          Cookies
                                                                          and
                                                                          similar
                                                                          technologies:
                                                                        </u>
                                                                      </strong>{" "}
                                                                      Most Web
                                                                      browsers
                                                                      are set to
                                                                      accept
                                                                      cookies by
                                                                      default.
                                                                      If you
                                                                      prefer,
                                                                      you can
                                                                      usually
                                                                      choose to
                                                                      set your
                                                                      browser to
                                                                      remove
                                                                      cookies
                                                                      and to
                                                                      reject
                                                                      cookies.
                                                                      If you
                                                                      choose to
                                                                      remove
                                                                      cookies or
                                                                      reject
                                                                      cookies,
                                                                      this could
                                                                      affect
                                                                      certain
                                                                      features
                                                                      or
                                                                      services
                                                                      of our
                                                                      Services.{" "}
                                                                      <bdt className="block-component" />
                                                                      You may
                                                                      also{" "}
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(183, 54, 82)"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <a
                                                                            data-custom-class="link"
                                                                            href="http://www.aboutads.info/choices/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(183, 54, 82)",
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              opt
                                                                              out
                                                                              of
                                                                              interest-based
                                                                              advertising
                                                                              by
                                                                              advertisers
                                                                            </span>
                                                                          </a>
                                                                        </span>
                                                                      </span>{" "}
                                                                      on our
                                                                      Services.{" "}
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)"
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)"
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    If you have
                                                                    questions or
                                                                    comments
                                                                    about your
                                                                    privacy
                                                                    rights, you
                                                                    may email us
                                                                    at{" "}
                                                                    <bdt className="question">
                                                                      ideasights.public@gmail.com
                                                                    </bdt>
                                                                    .
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="DNT"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                11.
                                                                                CONTROLS
                                                                                FOR
                                                                                DO-NOT-TRACK
                                                                                FEATURES
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      Most web
                                                                      browsers
                                                                      and some
                                                                      mobile
                                                                      operating
                                                                      systems
                                                                      and mobile
                                                                      applications
                                                                      include a
                                                                      Do-Not-Track
                                                                      (
                                                                      <bdt className="block-component" />
                                                                      "DNT"
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                      ) feature
                                                                      or setting
                                                                      you can
                                                                      activate
                                                                      to signal
                                                                      your
                                                                      privacy
                                                                      preference
                                                                      not to
                                                                      have data
                                                                      about your
                                                                      online
                                                                      browsing
                                                                      activities
                                                                      monitored
                                                                      and
                                                                      collected.
                                                                      At this
                                                                      stage, no
                                                                      uniform
                                                                      technology
                                                                      standard
                                                                      for{" "}
                                                                      <bdt className="block-component" />
                                                                      recognizing
                                                                      <bdt className="statement-end-if-in-editor" />{" "}
                                                                      and
                                                                      implementing
                                                                      DNT
                                                                      signals
                                                                      has been{" "}
                                                                      <bdt className="block-component" />
                                                                      finalized
                                                                      <bdt className="statement-end-if-in-editor" />
                                                                      . As such,
                                                                      we do not
                                                                      currently
                                                                      respond to
                                                                      DNT
                                                                      browser
                                                                      signals or
                                                                      any other
                                                                      mechanism
                                                                      that
                                                                      automatically
                                                                      communicates
                                                                      your
                                                                      choice not
                                                                      to be
                                                                      tracked
                                                                      online. If
                                                                      a standard
                                                                      for online
                                                                      tracking
                                                                      is adopted
                                                                      that we
                                                                      must
                                                                      follow in
                                                                      the
                                                                      future, we
                                                                      will
                                                                      inform you
                                                                      about that
                                                                      practice
                                                                      in a
                                                                      revised
                                                                      version of
                                                                      this
                                                                      Privacy
                                                                      Notice.
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <br />
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    California
                                                                    law requires
                                                                    us to let
                                                                    you know how
                                                                    we respond
                                                                    to web
                                                                    browser DNT
                                                                    signals.
                                                                    Because
                                                                    there
                                                                    currently is
                                                                    not an
                                                                    industry or
                                                                    legal
                                                                    standard for{" "}
                                                                    <bdt className="block-component" />
                                                                    recognizing
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    or{" "}
                                                                    <bdt className="block-component" />
                                                                    honoring
                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                    DNT signals,
                                                                    we do not
                                                                    respond to
                                                                    them at this
                                                                    time.
                                                                  </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="uslaws"
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(127, 127, 127)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          id="control"
                                                                          style={{
                                                                            color:
                                                                              "rgb(0, 0, 0)"
                                                                          }}
                                                                        >
                                                                          <strong>
                                                                            <span data-custom-class="heading_1">
                                                                              <h2>
                                                                                12.
                                                                                DO
                                                                                UNITED
                                                                                STATES
                                                                                RESIDENTS
                                                                                HAVE
                                                                                SPECIFIC
                                                                                PRIVACY
                                                                                RIGHTS?
                                                                              </h2>
                                                                            </span>
                                                                          </strong>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        <em>
                                                                          In
                                                                          Short:&nbsp;
                                                                        </em>
                                                                      </strong>
                                                                      <em>
                                                                        If you
                                                                        are a
                                                                        resident
                                                                        of
                                                                        <bdt className="block-component" />{" "}
                                                                        California,
                                                                        Colorado,
                                                                        Connecticut,
                                                                        Delaware,
                                                                        Florida,
                                                                        Indiana,
                                                                        Iowa,
                                                                        Kentucky,
                                                                        Minnesota,
                                                                        Montana,
                                                                        Nebraska,
                                                                        New
                                                                        Hampshire,
                                                                        New
                                                                        Jersey,
                                                                        Oregon,
                                                                        Tennessee,
                                                                        Texas,
                                                                        Utah, or
                                                                        Virginia
                                                                        <bdt className="else-block" />
                                                                        , you
                                                                        may have
                                                                        the
                                                                        right to
                                                                        request
                                                                        access
                                                                        to and
                                                                        receive
                                                                        details
                                                                        about
                                                                        the
                                                                        personal
                                                                        information
                                                                        we
                                                                        maintain
                                                                        about
                                                                        you and
                                                                        how we
                                                                        have
                                                                        processed
                                                                        it,
                                                                        correct
                                                                        inaccuracies,
                                                                        get a
                                                                        copy of,
                                                                        or
                                                                        delete
                                                                        your
                                                                        personal
                                                                        information.
                                                                        You may
                                                                        also
                                                                        have the
                                                                        right to
                                                                        withdraw
                                                                        your
                                                                        consent
                                                                        to our
                                                                        processing
                                                                        of your
                                                                        personal
                                                                        information.
                                                                        These
                                                                        rights
                                                                        may be
                                                                        limited
                                                                        in some
                                                                        circumstances
                                                                        by
                                                                        applicable
                                                                        law.
                                                                        More
                                                                        information
                                                                        is
                                                                        provided
                                                                        below.
                                                                      </em>
                                                                    </span>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <strong>
                                                                      <span data-custom-class="heading_2">
                                                                        <h3>
                                                                          Categories
                                                                          of
                                                                          Personal
                                                                          Information
                                                                          We
                                                                          Collect
                                                                        </h3>
                                                                      </span>
                                                                    </strong>
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We have
                                                                      collected
                                                                      the
                                                                      following
                                                                      categories
                                                                      of
                                                                      personal
                                                                      information
                                                                      in the
                                                                      past
                                                                      twelve
                                                                      (12)
                                                                      months:
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <table
                                                                style={{
                                                                  width: "100%"
                                                                }}
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              Category
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              Examples
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        textAlign:
                                                                          "center"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <strong>
                                                                              Collected
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              A.
                                                                              Identifiers
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Contact
                                                                              details,
                                                                              such
                                                                              as
                                                                              real
                                                                              name,
                                                                              alias,
                                                                              postal
                                                                              address,
                                                                              telephone
                                                                              or
                                                                              mobile
                                                                              contact
                                                                              number,
                                                                              unique
                                                                              personal
                                                                              identifier,
                                                                              online
                                                                              identifier,
                                                                              Internet
                                                                              Protocol
                                                                              address,
                                                                              email
                                                                              address,
                                                                              and
                                                                              account
                                                                              name
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        verticalAlign:
                                                                          "middle",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component">
                                                                                <bdt className="block-component">
                                                                                  <bdt className="forloop-component" />
                                                                                  <bdt className="block-component">
                                                                                    NO
                                                                                  </bdt>
                                                                                </bdt>
                                                                              </bdt>
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </div>
                                                              <table
                                                                style={{
                                                                  width: "100%"
                                                                }}
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              B.
                                                                              Personal
                                                                              information
                                                                              as
                                                                              defined
                                                                              in
                                                                              the
                                                                              California
                                                                              Customer
                                                                              Records
                                                                              statute
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Name,
                                                                              contact
                                                                              information,
                                                                              education,
                                                                              employment,
                                                                              employment
                                                                              history,
                                                                              and
                                                                              financial
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="forloop-component">
                                                                                <bdt className="block-component">
                                                                                  <bdt className="forloop-component" />
                                                                                  <bdt className="block-component">
                                                                                    NO
                                                                                  </bdt>
                                                                                </bdt>
                                                                              </bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component" />
                                                              </div>
                                                              <table
                                                                style={{
                                                                  width: "100%"
                                                                }}
                                                              >
                                                                <tbody>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              C
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Protected
                                                                              classification
                                                                              characteristics
                                                                              under
                                                                              state
                                                                              or
                                                                              federal
                                                                              law
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Gender,
                                                                              age,
                                                                              date
                                                                              of
                                                                              birth,
                                                                              race
                                                                              and
                                                                              ethnicity,
                                                                              national
                                                                              origin,
                                                                              marital
                                                                              status,
                                                                              and
                                                                              other
                                                                              demographic
                                                                              data
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              D
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Commercial
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Transaction
                                                                              information,
                                                                              purchase
                                                                              history,
                                                                              financial
                                                                              details,
                                                                              and
                                                                              payment
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              E
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Biometric
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Fingerprints
                                                                              and
                                                                              voiceprints
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              F
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Internet
                                                                              or
                                                                              other
                                                                              similar
                                                                              network
                                                                              activity
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Browsing
                                                                              history,
                                                                              search
                                                                              history,
                                                                              online{" "}
                                                                              <bdt className="block-component" />
                                                                              behavior
                                                                              <bdt className="statement-end-if-in-editor" />
                                                                              ,
                                                                              interest
                                                                              data,
                                                                              and
                                                                              interactions
                                                                              with
                                                                              our
                                                                              and
                                                                              other
                                                                              websites,
                                                                              applications,
                                                                              systems,
                                                                              and
                                                                              advertisements
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              G
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Geolocation
                                                                              data
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Device
                                                                              location
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              H
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Audio,
                                                                              electronic,
                                                                              sensory,
                                                                              or
                                                                              similar
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Images
                                                                              and
                                                                              audio,
                                                                              video
                                                                              or
                                                                              call
                                                                              recordings
                                                                              created
                                                                              in
                                                                              connection
                                                                              with
                                                                              our
                                                                              business
                                                                              activities
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "33.8274%",
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              I
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Professional
                                                                              or
                                                                              employment-related
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "51.4385%",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Business
                                                                              contact
                                                                              details
                                                                              in
                                                                              order
                                                                              to
                                                                              provide
                                                                              you
                                                                              our
                                                                              Services
                                                                              at
                                                                              a
                                                                              business
                                                                              level
                                                                              or
                                                                              job
                                                                              title,
                                                                              work
                                                                              history,
                                                                              and
                                                                              professional
                                                                              qualifications
                                                                              if
                                                                              you
                                                                              apply
                                                                              for
                                                                              a
                                                                              job
                                                                              with
                                                                              us
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        width:
                                                                          "14.9084%",
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        width:
                                                                          "33.8274%"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              J
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Education
                                                                              Information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        width:
                                                                          "51.4385%"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Student
                                                                              records
                                                                              and
                                                                              directory
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        width:
                                                                          "14.9084%"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="forloop-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                        </bdt>
                                                                        <bdt className="block-component" />
                                                                        <bdt className="block-component" />
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        borderWidth: 1,
                                                                        borderColor:
                                                                          "black",
                                                                        borderStyle:
                                                                          "solid",
                                                                        width:
                                                                          "33.8274%"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                              K
                                                                              <bdt className="else-block" />
                                                                              .
                                                                              Inferences
                                                                              drawn
                                                                              from
                                                                              collected
                                                                              personal
                                                                              information
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        borderBottom:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        width:
                                                                          "51.4385%"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              Inferences
                                                                              drawn
                                                                              from
                                                                              any
                                                                              of
                                                                              the
                                                                              collected
                                                                              personal
                                                                              information
                                                                              listed
                                                                              above
                                                                              to
                                                                              create
                                                                              a
                                                                              profile
                                                                              or
                                                                              summary
                                                                              about,
                                                                              for
                                                                              example,
                                                                              an
                                                                              individual’s
                                                                              preferences
                                                                              and
                                                                              characteristics
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        textAlign:
                                                                          "center",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderBottom:
                                                                          "1px solid black",
                                                                        borderTop:
                                                                          "1px solid black",
                                                                        width:
                                                                          "14.9084%"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              YES
                                                                              <bdt className="else-block" />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td
                                                                      style={{
                                                                        borderLeft:
                                                                          "1px solid black",
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderBottom:
                                                                          "1px solid black",
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                        L
                                                                        <bdt className="else-block" />
                                                                        .
                                                                        Sensitive
                                                                        personal
                                                                        Information
                                                                      </span>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderBottom:
                                                                          "1px solid black",
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <bdt className="block-component">
                                                                        <span data-custom-class="body_text" />
                                                                      </bdt>
                                                                    </td>
                                                                    <td
                                                                      style={{
                                                                        borderRight:
                                                                          "1px solid black",
                                                                        borderBottom:
                                                                          "1px solid black"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        data-empty="true"
                                                                        style={{
                                                                          textAlign:
                                                                            "center"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                      <div
                                                                        data-custom-class="body_text"
                                                                        data-empty="true"
                                                                        style={{
                                                                          textAlign:
                                                                            "center",
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <bdt className="block-component">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                        NO
                                                                        <bdt className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </bdt>
                                                                      </div>
                                                                      <div
                                                                        data-empty="true"
                                                                        style={{
                                                                          textAlign:
                                                                            "center"
                                                                        }}
                                                                      >
                                                                        <br />
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  />
                                                                </bdt>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    We may also
                                                                    collect
                                                                    other
                                                                    personal
                                                                    information
                                                                    outside of
                                                                    these
                                                                    categories
                                                                    through
                                                                    instances
                                                                    where you
                                                                    interact
                                                                    with us in
                                                                    person,
                                                                    online, or
                                                                    by phone or
                                                                    mail in the
                                                                    context of:
                                                                  </span>
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li
                                                                  data-custom-class="body_text"
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    Receiving
                                                                    help through
                                                                    our customer
                                                                    support
                                                                    channels;
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    Participation
                                                                    in customer
                                                                    surveys or
                                                                    contests;
                                                                    and
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div>
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="block-component" />
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    Facilitation
                                                                    in the
                                                                    delivery of
                                                                    our Services
                                                                    and to
                                                                    respond to
                                                                    your
                                                                    inquiries.
                                                                  </span>
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    />
                                                                  </bdt>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5"
                                                                }}
                                                              >
                                                                <bdt className="block-component">
                                                                  <span data-custom-class="body_text" />
                                                                </bdt>
                                                                <span data-custom-class="body_text">
                                                                  We will use
                                                                  and retain the
                                                                  collected
                                                                  personal
                                                                  information as
                                                                  needed to
                                                                  provide the
                                                                  Services or
                                                                  for:
                                                                  <bdt className="block-component" />
                                                                </span>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="block-component">
                                                                      <bdt className="block-component" />
                                                                    </bdt>
                                                                  </span>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                      </span>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5"
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5"
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component" />
                                                                          </span>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <bdt className="block-component" />
                                                                            </span>
                                                                            <div
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1.5"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                            </div>
                                                                            <ul>
                                                                              <li
                                                                                data-custom-class="body_text"
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Category{" "}
                                                                                  <bdt className="block-component" />
                                                                                  H
                                                                                  <bdt className="else-block" />{" "}
                                                                                  -{" "}
                                                                                  <bdt className="question">
                                                                                    As
                                                                                    long
                                                                                    as
                                                                                    the
                                                                                    user
                                                                                    has
                                                                                    an
                                                                                    account
                                                                                    with
                                                                                    us
                                                                                  </bdt>
                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                </span>
                                                                              </li>
                                                                            </ul>
                                                                            <div
                                                                              style={{
                                                                                lineHeight:
                                                                                  "1.5"
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <bdt className="block-component" />
                                                                              </span>
                                                                              <div
                                                                                style={{
                                                                                  lineHeight:
                                                                                    "1.5"
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <bdt className="block-component" />
                                                                                </span>
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                </div>
                                                                                <ul>
                                                                                  <li
                                                                                    data-custom-class="body_text"
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5"
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      Category{" "}
                                                                                      <bdt className="block-component" />
                                                                                      K
                                                                                      <bdt className="else-block" />{" "}
                                                                                      -{" "}
                                                                                      <bdt className="question">
                                                                                        As
                                                                                        long
                                                                                        as
                                                                                        the
                                                                                        user
                                                                                        has
                                                                                        an
                                                                                        account
                                                                                        with
                                                                                        us
                                                                                      </bdt>
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </li>
                                                                                </ul>
                                                                                <div
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5"
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <bdt className="block-component" />
                                                                                  </span>
                                                                                  <bdt className="statement-end-if-in-editor">
                                                                                    <span data-custom-class="body_text" />
                                                                                  </bdt>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5"
                                                                                    }}
                                                                                  >
                                                                                    <strong>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="heading_2">
                                                                                          <h3>
                                                                                            Sources
                                                                                            of
                                                                                            Personal
                                                                                            Information
                                                                                          </h3>
                                                                                        </span>
                                                                                      </span>
                                                                                    </strong>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        Learn
                                                                                        more
                                                                                        about
                                                                                        the
                                                                                        sources
                                                                                        of
                                                                                        personal
                                                                                        information
                                                                                        we
                                                                                        collect
                                                                                        in{" "}
                                                                                        <bdt className="block-component" />
                                                                                        "
                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </span>
                                                                                    <span data-custom-class="body_text">
                                                                                      <a
                                                                                        data-custom-class="link"
                                                                                        href="#infocollect"
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb (0, 58, 250)",
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          WHAT
                                                                                          INFORMATION
                                                                                          DO
                                                                                          WE
                                                                                          COLLECT?
                                                                                        </span>
                                                                                      </a>
                                                                                    </span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <bdt className="block-component" />
                                                                                        "
                                                                                      </span>
                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                        <span data-custom-class="body_text" />
                                                                                      </bdt>
                                                                                    </span>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <strong>
                                                                                          <span data-custom-class="heading_2">
                                                                                            <h3>
                                                                                              How
                                                                                              We
                                                                                              Use
                                                                                              and
                                                                                              Share
                                                                                              Personal
                                                                                              Information
                                                                                            </h3>
                                                                                          </span>
                                                                                        </strong>
                                                                                      </span>
                                                                                    </span>
                                                                                    <span
                                                                                      data-custom-class="body_text"
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="block-component" />
                                                                                      Learn
                                                                                      more
                                                                                      about
                                                                                      how
                                                                                      we
                                                                                      use
                                                                                      your
                                                                                      personal
                                                                                      information
                                                                                      in
                                                                                      the
                                                                                      section,{" "}
                                                                                      <bdt className="block-component" />
                                                                                      "
                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                    <a
                                                                                      data-custom-class="link"
                                                                                      href="#infouse"
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(183, 54, 82)",
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        HOW
                                                                                        DO
                                                                                        WE
                                                                                        PROCESS
                                                                                        YOUR
                                                                                        INFORMATION?
                                                                                      </span>
                                                                                    </a>
                                                                                    <span
                                                                                      data-custom-class="body_text"
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <bdt className="block-component" />
                                                                                      "
                                                                                    </span>
                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                      <span
                                                                                        data-custom-class="body_text"
                                                                                        style={{
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      />
                                                                                    </bdt>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5"
                                                                                    }}
                                                                                  >
                                                                                    <br />
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5"
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5"
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          We
                                                                                          collect
                                                                                          and
                                                                                          share
                                                                                          your
                                                                                          personal
                                                                                          information
                                                                                          through:
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                  <ul>
                                                                                    <li
                                                                                      data-custom-class="body_text"
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Targeting
                                                                                            cookies/Marketing
                                                                                            cookies
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </li>
                                                                                  </ul>
                                                                                  <div>
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)"
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                <bdt className="block-component" />
                                                                                              </bdt>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <div>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <bdt className="block-component" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <div>
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <div>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <bdt className="statement-end-if-in-editor">
                                                                                                                        <bdt className="block-component" />
                                                                                                                      </bdt>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)"
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)"
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <strong>
                                                                                                    Will
                                                                                                    your
                                                                                                    information
                                                                                                    be
                                                                                                    shared
                                                                                                    with
                                                                                                    anyone
                                                                                                    else?
                                                                                                  </strong>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  We
                                                                                                  may
                                                                                                  disclose
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  with
                                                                                                  our
                                                                                                  service
                                                                                                  providers
                                                                                                  pursuant
                                                                                                  to
                                                                                                  a
                                                                                                  written
                                                                                                  contract
                                                                                                  between
                                                                                                  us
                                                                                                  and
                                                                                                  each
                                                                                                  service
                                                                                                  provider.
                                                                                                  Learn
                                                                                                  more
                                                                                                  about
                                                                                                  how
                                                                                                  we
                                                                                                  disclose
                                                                                                  personal
                                                                                                  information
                                                                                                  to
                                                                                                  in
                                                                                                  the
                                                                                                  section,{" "}
                                                                                                  <bdt className="block-component" />
                                                                                                  "
                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <a
                                                                                              data-custom-class="link"
                                                                                              href="#whoshare"
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(183, 54, 82)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(183, 54, 82)"
                                                                                                  }}
                                                                                                >
                                                                                                  WHEN
                                                                                                  AND
                                                                                                  WITH
                                                                                                  WHOM
                                                                                                  DO
                                                                                                  WE
                                                                                                  SHARE
                                                                                                  YOUR
                                                                                                  PERSONAL
                                                                                                  INFORMATION?
                                                                                                </span>
                                                                                              </span>
                                                                                            </a>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <bdt className="block-component" />
                                                                                                  "
                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  We
                                                                                                  may
                                                                                                  use
                                                                                                  your
                                                                                                  personal
                                                                                                  information
                                                                                                  for
                                                                                                  our
                                                                                                  own
                                                                                                  business
                                                                                                  purposes,
                                                                                                  such
                                                                                                  as
                                                                                                  for
                                                                                                  undertaking
                                                                                                  internal
                                                                                                  research
                                                                                                  for
                                                                                                  technological
                                                                                                  development
                                                                                                  and
                                                                                                  demonstration.
                                                                                                  This
                                                                                                  is
                                                                                                  not
                                                                                                  considered
                                                                                                  to
                                                                                                  be{" "}
                                                                                                  <bdt className="block-component" />
                                                                                                  "selling"
                                                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                  of
                                                                                                  your
                                                                                                  personal
                                                                                                  information.
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <bdt className="block-component" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <bdt className="block-component" />
                                                                                            </span>
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <br />
                                                                                          </div>
                                                                                          <div
                                                                                            style={{
                                                                                              lineHeight:
                                                                                                "1.5"
                                                                                            }}
                                                                                          >
                                                                                            <bdt className="block-component">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text" />
                                                                                              </span>
                                                                                            </bdt>
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                We
                                                                                                have
                                                                                                not
                                                                                                sold
                                                                                                or
                                                                                                shared
                                                                                                any
                                                                                                personal
                                                                                                information
                                                                                                to
                                                                                                third
                                                                                                parties
                                                                                                for
                                                                                                a
                                                                                                business
                                                                                                or
                                                                                                commercial
                                                                                                purpose
                                                                                                in
                                                                                                the
                                                                                                preceding
                                                                                                twelve
                                                                                                (12)
                                                                                                months.&nbsp;
                                                                                              </span>
                                                                                            </span>
                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text" />
                                                                                              </span>
                                                                                            </bdt>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)"
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)"
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    We
                                                                                                    have
                                                                                                    disclosed
                                                                                                    the
                                                                                                    following
                                                                                                    categories
                                                                                                    of
                                                                                                    personal
                                                                                                    information
                                                                                                    to
                                                                                                    third
                                                                                                    parties
                                                                                                    for
                                                                                                    a
                                                                                                    business
                                                                                                    or
                                                                                                    commercial
                                                                                                    purpose
                                                                                                    in
                                                                                                    the
                                                                                                    preceding
                                                                                                    twelve
                                                                                                    (12)
                                                                                                    months:
                                                                                                    <bdt className="forloop-component" />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                lineHeight:
                                                                                                  "1.5"
                                                                                              }}
                                                                                            >
                                                                                              <bdt className="forloop-component">
                                                                                                <bdt className="block-component" />
                                                                                              </bdt>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                lineHeight:
                                                                                                  "1.5"
                                                                                              }}
                                                                                            >
                                                                                              <bdt className="forloop-component" />
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <p
                                                                                              style={{
                                                                                                fontSize: 15
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                            </p>
                                                                                            <div
                                                                                              style={{
                                                                                                lineHeight:
                                                                                                  "1.5"
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </span>
                                                                                              <p
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </p>
                                                                                              <p
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component" />
                                                                                              </p>
                                                                                              <p
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <bdt className="forloop-component">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15
                                                                                                    }}
                                                                                                  />
                                                                                                </bdt>
                                                                                              </p>
                                                                                              <p
                                                                                                style={{
                                                                                                  fontSize: 15
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <bdt className="forloop-component" />
                                                                                                </span>
                                                                                              </p>
                                                                                              <div>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15
                                                                                                  }}
                                                                                                >
                                                                                                  <bdt className="forloop-component" />
                                                                                                </span>
                                                                                                <div>
                                                                                                  <bdt className="forloop-component">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15
                                                                                                      }}
                                                                                                    />
                                                                                                  </bdt>
                                                                                                  <div>
                                                                                                    <bdt className="forloop-component">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15
                                                                                                        }}
                                                                                                      />
                                                                                                    </bdt>
                                                                                                    <div>
                                                                                                      <bdt className="forloop-component">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15
                                                                                                          }}
                                                                                                        />
                                                                                                      </bdt>
                                                                                                      <div>
                                                                                                        <bdt className="block-component">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15
                                                                                                            }}
                                                                                                          />
                                                                                                        </bdt>
                                                                                                        <div>
                                                                                                          <bdt className="block-component" />
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  The
                                                                                                                  categories
                                                                                                                  of
                                                                                                                  third
                                                                                                                  parties
                                                                                                                  to
                                                                                                                  whom
                                                                                                                  we
                                                                                                                  disclosed
                                                                                                                  personal
                                                                                                                  information
                                                                                                                  for
                                                                                                                  a
                                                                                                                  business
                                                                                                                  or
                                                                                                                  commercial
                                                                                                                  purpose
                                                                                                                  can
                                                                                                                  be
                                                                                                                  found
                                                                                                                  under{" "}
                                                                                                                  <bdt className="block-component" />
                                                                                                                  "
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(183, 54, 82)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <a
                                                                                                                          data-custom-class="link"
                                                                                                                          href="#whoshare"
                                                                                                                        >
                                                                                                                          WHEN
                                                                                                                          AND
                                                                                                                          WITH
                                                                                                                          WHOM
                                                                                                                          DO
                                                                                                                          WE
                                                                                                                          SHARE
                                                                                                                          YOUR
                                                                                                                          PERSONAL
                                                                                                                          INFORMATION?
                                                                                                                        </a>
                                                                                                                      </span>
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        color:
                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <bdt className="block-component" />
                                                                                                                                      "
                                                                                                                                      <bdt className="statement-end-if-in-editor" />
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          fontSize: 15
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              fontSize: 15
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  fontSize: 15
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span
                                                                                                                                  style={{
                                                                                                                                    color:
                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                  }}
                                                                                                                                >
                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        color:
                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          fontSize: 15
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            color:
                                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              fontSize: 15
                                                                                                                                            }}
                                                                                                                                          >
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                color:
                                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  fontSize: 15
                                                                                                                                                }}
                                                                                                                                              >
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    color:
                                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span
                                                                                                                                                    style={{
                                                                                                                                                      fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                          <span
                                                                                                                                                            style={{
                                                                                                                                                              fontSize: 15
                                                                                                                                                            }}
                                                                                                                                                          >
                                                                                                                                                            <span
                                                                                                                                                              style={{
                                                                                                                                                                color:
                                                                                                                                                                  "rgb(89, 89, 89)"
                                                                                                                                                              }}
                                                                                                                                                            >
                                                                                                                                                              <span
                                                                                                                                                                style={{
                                                                                                                                                                  fontSize: 15
                                                                                                                                                                }}
                                                                                                                                                              >
                                                                                                                                                                <span
                                                                                                                                                                  style={{
                                                                                                                                                                    color:
                                                                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                                                                  }}
                                                                                                                                                                >
                                                                                                                                                                  <span
                                                                                                                                                                    style={{
                                                                                                                                                                      fontSize: 15
                                                                                                                                                                    }}
                                                                                                                                                                  >
                                                                                                                                                                    <span
                                                                                                                                                                      style={{
                                                                                                                                                                        color:
                                                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                                                      }}
                                                                                                                                                                    >
                                                                                                                                                                      <span
                                                                                                                                                                        style={{
                                                                                                                                                                          fontSize: 15
                                                                                                                                                                        }}
                                                                                                                                                                      >
                                                                                                                                                                        <span
                                                                                                                                                                          style={{
                                                                                                                                                                            color:
                                                                                                                                                                              "rgb(89, 89, 89)"
                                                                                                                                                                          }}
                                                                                                                                                                        >
                                                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                                                          </span>
                                                                                                                                                                        </span>
                                                                                                                                                                      </span>
                                                                                                                                                                    </span>
                                                                                                                                                                  </span>
                                                                                                                                                                </span>
                                                                                                                                                              </span>
                                                                                                                                                            </span>
                                                                                                                                                          </span>
                                                                                                                                                        </bdt>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(0, 0, 0)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <strong>
                                                                                                                <span data-custom-class="heading_2">
                                                                                                                  <h3>
                                                                                                                    Your
                                                                                                                    Rights
                                                                                                                  </h3>
                                                                                                                </span>
                                                                                                              </strong>
                                                                                                              <span data-custom-class="body_text">
                                                                                                                You
                                                                                                                have
                                                                                                                rights
                                                                                                                under
                                                                                                                certain
                                                                                                                US
                                                                                                                state
                                                                                                                data
                                                                                                                protection
                                                                                                                laws.
                                                                                                                However,
                                                                                                                these
                                                                                                                rights
                                                                                                                are
                                                                                                                not
                                                                                                                absolute,
                                                                                                                and
                                                                                                                in
                                                                                                                certain
                                                                                                                cases,
                                                                                                                we
                                                                                                                may
                                                                                                                decline
                                                                                                                your
                                                                                                                request
                                                                                                                as
                                                                                                                permitted
                                                                                                                by
                                                                                                                law.
                                                                                                                These
                                                                                                                rights
                                                                                                                include:
                                                                                                              </span>
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  know
                                                                                                                </strong>{" "}
                                                                                                                whether
                                                                                                                or
                                                                                                                not
                                                                                                                we
                                                                                                                are
                                                                                                                processing
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  access&nbsp;
                                                                                                                </strong>
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  correct&nbsp;
                                                                                                                </strong>
                                                                                                                inaccuracies
                                                                                                                in
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  request
                                                                                                                </strong>{" "}
                                                                                                                the
                                                                                                                deletion
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  obtain
                                                                                                                  a
                                                                                                                  copy&nbsp;
                                                                                                                </strong>
                                                                                                                of
                                                                                                                the
                                                                                                                personal
                                                                                                                data
                                                                                                                you
                                                                                                                previously
                                                                                                                shared
                                                                                                                with
                                                                                                                us
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  non-discrimination
                                                                                                                </strong>{" "}
                                                                                                                for
                                                                                                                exercising
                                                                                                                your
                                                                                                                rights
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <strong>
                                                                                                                  Right
                                                                                                                  to
                                                                                                                  opt
                                                                                                                  out
                                                                                                                </strong>{" "}
                                                                                                                of
                                                                                                                the
                                                                                                                processing
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                data
                                                                                                                if
                                                                                                                it
                                                                                                                is
                                                                                                                used
                                                                                                                for
                                                                                                                targeted
                                                                                                                advertising
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                (or
                                                                                                                sharing
                                                                                                                as
                                                                                                                defined
                                                                                                                under
                                                                                                                California’s
                                                                                                                privacy
                                                                                                                law)
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                ,
                                                                                                                the
                                                                                                                sale
                                                                                                                of
                                                                                                                personal
                                                                                                                data,
                                                                                                                or
                                                                                                                profiling
                                                                                                                in
                                                                                                                furtherance
                                                                                                                of
                                                                                                                decisions
                                                                                                                that
                                                                                                                produce
                                                                                                                legal
                                                                                                                or
                                                                                                                similarly
                                                                                                                significant
                                                                                                                effects
                                                                                                                (
                                                                                                                <bdt className="block-component" />
                                                                                                                "profiling"
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                )
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                Depending
                                                                                                                upon
                                                                                                                the
                                                                                                                state
                                                                                                                where
                                                                                                                you
                                                                                                                live,
                                                                                                                you
                                                                                                                may
                                                                                                                also
                                                                                                                have
                                                                                                                the
                                                                                                                following
                                                                                                                rights:
                                                                                                              </span>
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Right
                                                                                                                to
                                                                                                                access
                                                                                                                the
                                                                                                                categories
                                                                                                                of
                                                                                                                personal
                                                                                                                data
                                                                                                                being
                                                                                                                processed
                                                                                                                (as
                                                                                                                permitted
                                                                                                                by
                                                                                                                applicable
                                                                                                                law,
                                                                                                                including
                                                                                                                Minnesota’s
                                                                                                                privacy
                                                                                                                law)
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Right
                                                                                                                to
                                                                                                                obtain
                                                                                                                a
                                                                                                                list
                                                                                                                of
                                                                                                                the
                                                                                                                categories
                                                                                                                of
                                                                                                                third
                                                                                                                parties
                                                                                                                to
                                                                                                                which
                                                                                                                we
                                                                                                                have
                                                                                                                disclosed
                                                                                                                personal
                                                                                                                data
                                                                                                                (as
                                                                                                                permitted
                                                                                                                by
                                                                                                                applicable
                                                                                                                law,
                                                                                                                including
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                California's
                                                                                                                and
                                                                                                                Delaware's
                                                                                                                <bdt className="else-block" />{" "}
                                                                                                                privacy
                                                                                                                law)
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Right
                                                                                                                to
                                                                                                                obtain
                                                                                                                a
                                                                                                                list
                                                                                                                of
                                                                                                                specific
                                                                                                                third
                                                                                                                parties
                                                                                                                to
                                                                                                                which
                                                                                                                we
                                                                                                                have
                                                                                                                disclosed
                                                                                                                personal
                                                                                                                data
                                                                                                                (as
                                                                                                                permitted
                                                                                                                by
                                                                                                                applicable
                                                                                                                law,
                                                                                                                including
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                Minnesota's
                                                                                                                and
                                                                                                                Oregon's
                                                                                                                <bdt className="else-block" />{" "}
                                                                                                                privacy
                                                                                                                law)
                                                                                                              </span>{" "}
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                />
                                                                                                              </bdt>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Right
                                                                                                                to
                                                                                                                review,
                                                                                                                understand,
                                                                                                                question,
                                                                                                                and
                                                                                                                correct
                                                                                                                how
                                                                                                                personal
                                                                                                                data
                                                                                                                has
                                                                                                                been
                                                                                                                profiled
                                                                                                                (as
                                                                                                                permitted
                                                                                                                by
                                                                                                                applicable
                                                                                                                law,
                                                                                                                including
                                                                                                                Minnesota’s
                                                                                                                privacy
                                                                                                                law)
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Right
                                                                                                                to
                                                                                                                limit
                                                                                                                use
                                                                                                                and
                                                                                                                disclosure
                                                                                                                of
                                                                                                                sensitive
                                                                                                                personal
                                                                                                                data
                                                                                                                (as
                                                                                                                permitted
                                                                                                                by
                                                                                                                applicable
                                                                                                                law,
                                                                                                                including
                                                                                                                California’s
                                                                                                                privacy
                                                                                                                law)
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                />
                                                                                                              </bdt>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Right
                                                                                                                to
                                                                                                                opt
                                                                                                                out
                                                                                                                of
                                                                                                                the
                                                                                                                collection
                                                                                                                of
                                                                                                                sensitive
                                                                                                                data
                                                                                                                and
                                                                                                                personal
                                                                                                                data
                                                                                                                collected
                                                                                                                through
                                                                                                                the
                                                                                                                operation
                                                                                                                of
                                                                                                                a
                                                                                                                voice
                                                                                                                or
                                                                                                                facial
                                                                                                                recognition
                                                                                                                feature
                                                                                                                (as
                                                                                                                permitted
                                                                                                                by
                                                                                                                applicable
                                                                                                                law,
                                                                                                                including
                                                                                                                Florida’s
                                                                                                                privacy
                                                                                                                law)
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                />
                                                                                                              </bdt>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                            </span>
                                                                                                            <strong>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="heading_2">
                                                                                                                  <h3>
                                                                                                                    How
                                                                                                                    to
                                                                                                                    Exercise
                                                                                                                    Your
                                                                                                                    Rights
                                                                                                                  </h3>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </strong>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  To
                                                                                                                  exercise
                                                                                                                  these
                                                                                                                  rights,
                                                                                                                  you
                                                                                                                  can
                                                                                                                  contact
                                                                                                                  us{" "}
                                                                                                                  <bdt className="block-component" />
                                                                                                                  by
                                                                                                                  submitting
                                                                                                                  a&nbsp;
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <a
                                                                                                              data-custom-class="link"
                                                                                                              href="https://app.termly.io/notify/9cf05ed3-484f-4251-92f6-e55266e6ff30"
                                                                                                              rel="noopener noreferrer"
                                                                                                              target="_blank"
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(183, 54, 82)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(183, 54, 82)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  data
                                                                                                                  subject
                                                                                                                  access
                                                                                                                  request
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </a>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  ,{" "}
                                                                                                                  <bdt className="block-component" />
                                                                                                                </span>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                        by
                                                                                                                        emailing
                                                                                                                        us
                                                                                                                        at{" "}
                                                                                                                        <bdt className="question">
                                                                                                                          ideasights.public@gmail.com
                                                                                                                        </bdt>
                                                                                                                        ,{" "}
                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                        <bdt className="block-component" />
                                                                                                                      </span>
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                        <bdt className="block-component">
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="block-component" />
                                                                                                                          </span>
                                                                                                                        </bdt>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              or
                                                                                                              by
                                                                                                              referring
                                                                                                              to
                                                                                                              the
                                                                                                              contact
                                                                                                              details
                                                                                                              at
                                                                                                              the
                                                                                                              bottom
                                                                                                              of
                                                                                                              this
                                                                                                              document.
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <br />
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                Under
                                                                                                                certain
                                                                                                                US
                                                                                                                state
                                                                                                                data
                                                                                                                protection
                                                                                                                laws,
                                                                                                                you
                                                                                                                can
                                                                                                                designate
                                                                                                                an{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                agent
                                                                                                                to
                                                                                                                make
                                                                                                                a
                                                                                                                request
                                                                                                                on
                                                                                                                your
                                                                                                                behalf.
                                                                                                                We
                                                                                                                may
                                                                                                                deny
                                                                                                                a
                                                                                                                request
                                                                                                                from
                                                                                                                an{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                agent
                                                                                                                that
                                                                                                                does
                                                                                                                not
                                                                                                                submit
                                                                                                                proof
                                                                                                                that
                                                                                                                they
                                                                                                                have
                                                                                                                been
                                                                                                                validly{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                to
                                                                                                                act
                                                                                                                on
                                                                                                                your
                                                                                                                behalf
                                                                                                                in
                                                                                                                accordance
                                                                                                                with
                                                                                                                applicable
                                                                                                                laws.
                                                                                                              </span>
                                                                                                              <br />
                                                                                                              <strong>
                                                                                                                <span data-custom-class="heading_2">
                                                                                                                  <h3>
                                                                                                                    Request
                                                                                                                    Verification
                                                                                                                  </h3>
                                                                                                                </span>
                                                                                                              </strong>
                                                                                                              <span data-custom-class="body_text">
                                                                                                                Upon
                                                                                                                receiving
                                                                                                                your
                                                                                                                request,
                                                                                                                we
                                                                                                                will
                                                                                                                need
                                                                                                                to
                                                                                                                verify
                                                                                                                your
                                                                                                                identity
                                                                                                                to
                                                                                                                determine
                                                                                                                you
                                                                                                                are
                                                                                                                the
                                                                                                                same
                                                                                                                person
                                                                                                                about
                                                                                                                whom
                                                                                                                we
                                                                                                                have
                                                                                                                the
                                                                                                                information
                                                                                                                in
                                                                                                                our
                                                                                                                system.
                                                                                                                We
                                                                                                                will
                                                                                                                only
                                                                                                                use
                                                                                                                personal
                                                                                                                information
                                                                                                                provided
                                                                                                                in
                                                                                                                your
                                                                                                                request
                                                                                                                to
                                                                                                                verify
                                                                                                                your
                                                                                                                identity
                                                                                                                or
                                                                                                                authority
                                                                                                                to
                                                                                                                make
                                                                                                                the
                                                                                                                request.
                                                                                                                However,
                                                                                                                if
                                                                                                                we
                                                                                                                cannot
                                                                                                                verify
                                                                                                                your
                                                                                                                identity
                                                                                                                from
                                                                                                                the
                                                                                                                information
                                                                                                                already
                                                                                                                maintained
                                                                                                                by
                                                                                                                us,
                                                                                                                we
                                                                                                                may
                                                                                                                request
                                                                                                                that
                                                                                                                you
                                                                                                                provide
                                                                                                                additional
                                                                                                                information
                                                                                                                for
                                                                                                                the
                                                                                                                purposes
                                                                                                                of
                                                                                                                verifying
                                                                                                                your
                                                                                                                identity
                                                                                                                and
                                                                                                                for
                                                                                                                security
                                                                                                                or
                                                                                                                fraud-prevention
                                                                                                                purposes.
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                If
                                                                                                                you
                                                                                                                submit
                                                                                                                the
                                                                                                                request
                                                                                                                through
                                                                                                                an{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                authorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                agent,
                                                                                                                we
                                                                                                                may
                                                                                                                need
                                                                                                                to
                                                                                                                collect
                                                                                                                additional
                                                                                                                information
                                                                                                                to
                                                                                                                verify
                                                                                                                your
                                                                                                                identity
                                                                                                                before
                                                                                                                processing
                                                                                                                your
                                                                                                                request
                                                                                                                and
                                                                                                                the
                                                                                                                agent
                                                                                                                will
                                                                                                                need
                                                                                                                to
                                                                                                                provide
                                                                                                                a
                                                                                                                written
                                                                                                                and
                                                                                                                signed
                                                                                                                permission
                                                                                                                from
                                                                                                                you
                                                                                                                to
                                                                                                                submit
                                                                                                                such
                                                                                                                request
                                                                                                                on
                                                                                                                your
                                                                                                                behalf.
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="heading_2">
                                                                                                                <strong>
                                                                                                                  <h3>
                                                                                                                    Appeals
                                                                                                                  </h3>
                                                                                                                </strong>
                                                                                                              </span>
                                                                                                              <span data-custom-class="body_text">
                                                                                                                Under
                                                                                                                certain
                                                                                                                US
                                                                                                                state
                                                                                                                data
                                                                                                                protection
                                                                                                                laws,
                                                                                                                if
                                                                                                                we
                                                                                                                decline
                                                                                                                to
                                                                                                                take
                                                                                                                action
                                                                                                                regarding
                                                                                                                your
                                                                                                                request,
                                                                                                                you
                                                                                                                may
                                                                                                                appeal
                                                                                                                our
                                                                                                                decision
                                                                                                                by
                                                                                                                emailing
                                                                                                                us
                                                                                                                at{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                <bdt className="question">
                                                                                                                  ideasights.public@gmail.com
                                                                                                                </bdt>
                                                                                                                <bdt className="else-block" />
                                                                                                                .
                                                                                                                We
                                                                                                                will
                                                                                                                inform
                                                                                                                you
                                                                                                                in
                                                                                                                writing
                                                                                                                of
                                                                                                                any
                                                                                                                action
                                                                                                                taken
                                                                                                                or
                                                                                                                not
                                                                                                                taken
                                                                                                                in
                                                                                                                response
                                                                                                                to
                                                                                                                the
                                                                                                                appeal,
                                                                                                                including
                                                                                                                a
                                                                                                                written
                                                                                                                explanation
                                                                                                                of
                                                                                                                the
                                                                                                                reasons
                                                                                                                for
                                                                                                                the
                                                                                                                decisions.
                                                                                                                If
                                                                                                                your
                                                                                                                appeal
                                                                                                                is
                                                                                                                denied,
                                                                                                                you
                                                                                                                may
                                                                                                                submit
                                                                                                                a
                                                                                                                complaint
                                                                                                                to
                                                                                                                your
                                                                                                                state
                                                                                                                attorney
                                                                                                                general.
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <bdt className="block-component">
                                                                                                                      <bdt className="block-component" />
                                                                                                                    </bdt>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <strong>
                                                                                                                <span data-custom-class="heading_2">
                                                                                                                  <h3>
                                                                                                                    California{" "}
                                                                                                                    <bdt className="block-component" />
                                                                                                                    "Shine
                                                                                                                    The
                                                                                                                    Light"
                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                    Law
                                                                                                                  </h3>
                                                                                                                </span>
                                                                                                              </strong>
                                                                                                              <span data-custom-class="body_text">
                                                                                                                California
                                                                                                                Civil
                                                                                                                Code
                                                                                                                Section
                                                                                                                1798.83,
                                                                                                                also
                                                                                                                known
                                                                                                                as
                                                                                                                the{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                "Shine
                                                                                                                The
                                                                                                                Light"
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                law,
                                                                                                                permits
                                                                                                                our
                                                                                                                users
                                                                                                                who
                                                                                                                are
                                                                                                                California
                                                                                                                residents
                                                                                                                to
                                                                                                                request
                                                                                                                and
                                                                                                                obtain
                                                                                                                from
                                                                                                                us,
                                                                                                                once
                                                                                                                a
                                                                                                                year
                                                                                                                and
                                                                                                                free
                                                                                                                of
                                                                                                                charge,
                                                                                                                information
                                                                                                                about
                                                                                                                categories
                                                                                                                of
                                                                                                                personal
                                                                                                                information
                                                                                                                (if
                                                                                                                any)
                                                                                                                we
                                                                                                                disclosed
                                                                                                                to
                                                                                                                third
                                                                                                                parties
                                                                                                                for
                                                                                                                direct
                                                                                                                marketing
                                                                                                                purposes
                                                                                                                and
                                                                                                                the
                                                                                                                names
                                                                                                                and
                                                                                                                addresses
                                                                                                                of
                                                                                                                all
                                                                                                                third
                                                                                                                parties
                                                                                                                with
                                                                                                                which
                                                                                                                we
                                                                                                                shared
                                                                                                                personal
                                                                                                                information
                                                                                                                in
                                                                                                                the
                                                                                                                immediately
                                                                                                                preceding
                                                                                                                calendar
                                                                                                                year.
                                                                                                                If
                                                                                                                you
                                                                                                                are
                                                                                                                a
                                                                                                                California
                                                                                                                resident
                                                                                                                and
                                                                                                                would
                                                                                                                like
                                                                                                                to
                                                                                                                make
                                                                                                                such
                                                                                                                a
                                                                                                                request,
                                                                                                                please
                                                                                                                submit
                                                                                                                your
                                                                                                                request
                                                                                                                in
                                                                                                                writing
                                                                                                                to
                                                                                                                us
                                                                                                                by
                                                                                                                using
                                                                                                                the
                                                                                                                contact
                                                                                                                details
                                                                                                                provided
                                                                                                                in
                                                                                                                the
                                                                                                                section{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <a
                                                                                                                data-custom-class="link"
                                                                                                                href="#contact"
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(183, 54, 82)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  HOW
                                                                                                                  CAN
                                                                                                                  YOU
                                                                                                                  CONTACT
                                                                                                                  US
                                                                                                                  ABOUT
                                                                                                                  THIS
                                                                                                                  NOTICE?
                                                                                                                </span>
                                                                                                              </a>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span data-custom-class="body_text" />
                                                                                                              </bdt>
                                                                                                            </span>
                                                                                                            <bdt className="statement-end-if-in-editor">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            color:
                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                color:
                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                fontSize: 15
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                        color:
                                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          fontSize: 15,
                                                                                                                                          color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            color:
                                                                                                                                              "rgb(89, 89, 89)",
                                                                                                                                            fontSize: 15
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span data-custom-class="body_text">
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                color:
                                                                                                                                                  "rgb(89, 89, 89)",
                                                                                                                                                fontSize: 15
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span data-custom-class="body_text">
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    color:
                                                                                                                                                      "rgb(89, 89, 89)",
                                                                                                                                                    fontSize: 15
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                      style={{
                                                                                                                                                        color:
                                                                                                                                                          "rgb(89, 89, 89)",
                                                                                                                                                        fontSize: 15
                                                                                                                                                      }}
                                                                                                                                                    >
                                                                                                                                                      <span data-custom-class="body_text">
                                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                                                                                        </bdt>
                                                                                                                                                      </span>
                                                                                                                                                    </span>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </bdt>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              id="otherlaws"
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <strong>
                                                                                                                <span data-custom-class="heading_1">
                                                                                                                  <h2>
                                                                                                                    13.
                                                                                                                    DO
                                                                                                                    OTHER
                                                                                                                    REGIONS
                                                                                                                    HAVE
                                                                                                                    SPECIFIC
                                                                                                                    PRIVACY
                                                                                                                    RIGHTS?
                                                                                                                  </h2>
                                                                                                                </span>
                                                                                                              </strong>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <em>
                                                                                                                <strong>
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    In
                                                                                                                    Short:
                                                                                                                  </span>
                                                                                                                </strong>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  &nbsp;You
                                                                                                                  may
                                                                                                                  have
                                                                                                                  additional
                                                                                                                  rights
                                                                                                                  based
                                                                                                                  on
                                                                                                                  the
                                                                                                                  country
                                                                                                                  you
                                                                                                                  reside
                                                                                                                  in.
                                                                                                                </span>
                                                                                                              </em>
                                                                                                              <bdt className="block-component" />
                                                                                                              &nbsp;
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component">
                                                                                                                <span data-custom-class="heading_2" />
                                                                                                              </bdt>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="heading_2">
                                                                                                                <h3>
                                                                                                                  <strong>
                                                                                                                    Australia
                                                                                                                  </strong>
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                  <bdt className="block-component" />{" "}
                                                                                                                  <strong>
                                                                                                                    and
                                                                                                                  </strong>{" "}
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                  <bdt className="block-component" />
                                                                                                                  <strong>
                                                                                                                    New
                                                                                                                    Zealand
                                                                                                                  </strong>
                                                                                                                </h3>
                                                                                                              </span>{" "}
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span data-custom-class="heading_2" />
                                                                                                              </bdt>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                We
                                                                                                                collect
                                                                                                                and
                                                                                                                process
                                                                                                                your
                                                                                                                personal
                                                                                                                information
                                                                                                                under
                                                                                                                the
                                                                                                                obligations
                                                                                                                and
                                                                                                                conditions
                                                                                                                set
                                                                                                                by{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                Australia's
                                                                                                                Privacy
                                                                                                                Act
                                                                                                                1988
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                and{" "}
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                <bdt className="block-component" />
                                                                                                                New
                                                                                                                Zealand's
                                                                                                                Privacy
                                                                                                                Act
                                                                                                                2020
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                (Privacy
                                                                                                                Act).
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                This
                                                                                                                Privacy
                                                                                                                Notice
                                                                                                                satisfies
                                                                                                                the
                                                                                                                notice
                                                                                                                requirements
                                                                                                                defined
                                                                                                                in
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                both
                                                                                                                Privacy
                                                                                                                Acts
                                                                                                                <bdt className="block-component" />
                                                                                                                ,
                                                                                                                in
                                                                                                                particular:
                                                                                                                what
                                                                                                                personal
                                                                                                                information
                                                                                                                we
                                                                                                                collect
                                                                                                                from
                                                                                                                you,
                                                                                                                from
                                                                                                                which
                                                                                                                sources,
                                                                                                                for
                                                                                                                which
                                                                                                                purposes,
                                                                                                                and
                                                                                                                other
                                                                                                                recipients
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                information.
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                If
                                                                                                                you
                                                                                                                do
                                                                                                                not
                                                                                                                wish
                                                                                                                to
                                                                                                                provide
                                                                                                                the
                                                                                                                personal
                                                                                                                information
                                                                                                                necessary
                                                                                                                to{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                fulfill
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                their
                                                                                                                applicable
                                                                                                                purpose,
                                                                                                                it
                                                                                                                may
                                                                                                                affect
                                                                                                                our
                                                                                                                ability
                                                                                                                to
                                                                                                                provide
                                                                                                                our
                                                                                                                services,
                                                                                                                in
                                                                                                                particular:
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  offer
                                                                                                                  you
                                                                                                                  the
                                                                                                                  products
                                                                                                                  or
                                                                                                                  services
                                                                                                                  that
                                                                                                                  you
                                                                                                                  want
                                                                                                                </span>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  respond
                                                                                                                  to
                                                                                                                  or
                                                                                                                  help
                                                                                                                  with
                                                                                                                  your
                                                                                                                  requests
                                                                                                                </span>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  manage
                                                                                                                  your
                                                                                                                  account
                                                                                                                  with
                                                                                                                  us
                                                                                                                </span>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="block-component" />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <ul>
                                                                                                            <li
                                                                                                              data-custom-class="body_text"
                                                                                                              style={{
                                                                                                                lineHeight:
                                                                                                                  "1.5"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  confirm
                                                                                                                  your
                                                                                                                  identity
                                                                                                                  and
                                                                                                                  protect
                                                                                                                  your
                                                                                                                  account
                                                                                                                </span>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                At
                                                                                                                any
                                                                                                                time,
                                                                                                                you
                                                                                                                have
                                                                                                                the
                                                                                                                right
                                                                                                                to
                                                                                                                request
                                                                                                                access
                                                                                                                to
                                                                                                                or
                                                                                                                correction
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                information.
                                                                                                                You
                                                                                                                can
                                                                                                                make
                                                                                                                such
                                                                                                                a
                                                                                                                request
                                                                                                                by
                                                                                                                contacting
                                                                                                                us
                                                                                                                by
                                                                                                                using
                                                                                                                the
                                                                                                                contact
                                                                                                                details
                                                                                                                provided
                                                                                                                in
                                                                                                                the
                                                                                                                section{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                <a
                                                                                                                  data-custom-class="link"
                                                                                                                  href="#request"
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(183, 54, 82)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="link">
                                                                                                                      HOW
                                                                                                                      CAN
                                                                                                                      YOU
                                                                                                                      REVIEW,
                                                                                                                      UPDATE,
                                                                                                                      OR
                                                                                                                      DELETE
                                                                                                                      THE
                                                                                                                      DATA
                                                                                                                      WE
                                                                                                                      COLLECT
                                                                                                                      FROM
                                                                                                                      YOU?
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </a>
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span data-custom-class="body_text" />
                                                                                                              </bdt>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                If
                                                                                                                you
                                                                                                                believe
                                                                                                                we
                                                                                                                are
                                                                                                                unlawfully
                                                                                                                processing
                                                                                                                your
                                                                                                                personal
                                                                                                                information,
                                                                                                                you
                                                                                                                have
                                                                                                                the
                                                                                                                right
                                                                                                                to
                                                                                                                submit
                                                                                                                a
                                                                                                                complaint
                                                                                                                about{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                a
                                                                                                                breach
                                                                                                                of
                                                                                                                the
                                                                                                                Australian
                                                                                                                Privacy
                                                                                                                Principles
                                                                                                                to
                                                                                                                the{" "}
                                                                                                                <a
                                                                                                                  data-custom-class="link"
                                                                                                                  href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
                                                                                                                  rel="noopener noreferrer"
                                                                                                                  target="_blank"
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(183, 54, 82)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="link">
                                                                                                                      Office
                                                                                                                      of
                                                                                                                      the
                                                                                                                      Australian
                                                                                                                      Information
                                                                                                                      Commissioner
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </a>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                and{" "}
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                <bdt className="block-component" />
                                                                                                                a
                                                                                                                breach
                                                                                                                of
                                                                                                                New
                                                                                                                Zealand's
                                                                                                                Privacy
                                                                                                                Principles
                                                                                                                to
                                                                                                                the{" "}
                                                                                                                <a
                                                                                                                  data-custom-class="link"
                                                                                                                  href="https://www.privacy.org.nz/your-rights/making-a-complaint/"
                                                                                                                  rel="noopener noreferrer"
                                                                                                                  target="_blank"
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(183, 54, 82)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="link">
                                                                                                                      Office
                                                                                                                      of
                                                                                                                      New
                                                                                                                      Zealand
                                                                                                                      Privacy
                                                                                                                      Commissioner
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </a>
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                .
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <strong>
                                                                                                                <span data-custom-class="heading_2">
                                                                                                                  <h3>
                                                                                                                    Republic
                                                                                                                    of
                                                                                                                    South
                                                                                                                    Africa
                                                                                                                  </h3>
                                                                                                                </span>
                                                                                                              </strong>
                                                                                                              <span data-custom-class="body_text">
                                                                                                                At
                                                                                                                any
                                                                                                                time,
                                                                                                                you
                                                                                                                have
                                                                                                                the
                                                                                                                right
                                                                                                                to
                                                                                                                request
                                                                                                                access
                                                                                                                to
                                                                                                                or
                                                                                                                correction
                                                                                                                of
                                                                                                                your
                                                                                                                personal
                                                                                                                information.
                                                                                                                You
                                                                                                                can
                                                                                                                make
                                                                                                                such
                                                                                                                a
                                                                                                                request
                                                                                                                by
                                                                                                                contacting
                                                                                                                us
                                                                                                                by
                                                                                                                using
                                                                                                                the
                                                                                                                contact
                                                                                                                details
                                                                                                                provided
                                                                                                                in
                                                                                                                the
                                                                                                                section{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="link">
                                                                                                              <a href="#request">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(183, 54, 82)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="link">
                                                                                                                    HOW
                                                                                                                    CAN
                                                                                                                    YOU
                                                                                                                    REVIEW,
                                                                                                                    UPDATE,
                                                                                                                    OR
                                                                                                                    DELETE
                                                                                                                    THE
                                                                                                                    DATA
                                                                                                                    WE
                                                                                                                    COLLECT
                                                                                                                    FROM
                                                                                                                    YOU?
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </a>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                              </span>
                                                                                                              <bdt className="statement-end-if-in-editor">
                                                                                                                <span data-custom-class="body_text" />
                                                                                                              </bdt>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                If
                                                                                                                you
                                                                                                                are
                                                                                                                unsatisfied
                                                                                                                with
                                                                                                                the
                                                                                                                manner
                                                                                                                in
                                                                                                                which
                                                                                                                we
                                                                                                                address
                                                                                                                any
                                                                                                                complaint
                                                                                                                with
                                                                                                                regard
                                                                                                                to
                                                                                                                our
                                                                                                                processing
                                                                                                                of
                                                                                                                personal
                                                                                                                information,
                                                                                                                you
                                                                                                                can
                                                                                                                contact
                                                                                                                the
                                                                                                                office
                                                                                                                of
                                                                                                                the
                                                                                                                regulator,
                                                                                                                the
                                                                                                                details
                                                                                                                of
                                                                                                                which
                                                                                                                are:
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <br />
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <a
                                                                                                              data-custom-class="link"
                                                                                                              href="https://inforegulator.org.za/"
                                                                                                              rel="noopener noreferrer"
                                                                                                              target="_blank"
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(183, 54, 82)",
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span data-custom-class="link">
                                                                                                                    The
                                                                                                                    Information
                                                                                                                    Regulator
                                                                                                                    (South
                                                                                                                    Africa)
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </a>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                General
                                                                                                                enquiries:&nbsp;
                                                                                                              </span>
                                                                                                              <a
                                                                                                                data-custom-class="link"
                                                                                                                href="mailto:enquiries@inforegulator.org.za"
                                                                                                                rel="noopener noreferrer"
                                                                                                                target="_blank"
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(183, 54, 82)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="link">
                                                                                                                    enquiries@inforegulator.org.za
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </a>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                Complaints
                                                                                                                (complete
                                                                                                                POPIA/PAIA
                                                                                                                form
                                                                                                                5):&nbsp;
                                                                                                              </span>
                                                                                                              <a
                                                                                                                data-custom-class="link"
                                                                                                                href="mailto:PAIAComplaints@inforegulator.org.za"
                                                                                                                rel="noopener noreferrer"
                                                                                                                target="_blank"
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(183, 54, 82)",
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="link">
                                                                                                                    PAIAComplaints@inforegulator.org.za
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </a>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                &nbsp;&amp;&nbsp;
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <a
                                                                                                              data-custom-class="link"
                                                                                                              href="mailto:POPIAComplaints@inforegulator.org.za"
                                                                                                              rel="noopener noreferrer"
                                                                                                              target="_blank"
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(183, 54, 82)",
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span data-custom-class="link">
                                                                                                                    POPIAComplaints@inforegulator.org.za
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </a>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                              <bdt className="statement-end-if-in-editor" />
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              />
                                                                                                            </bdt>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <br />
                                                                                                          </div>
                                                                                                          <div
                                                                                                            id="policyupdates"
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(127, 127, 127)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      id="control"
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(0, 0, 0)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <strong>
                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                          <h2>
                                                                                                                            14.
                                                                                                                            DO
                                                                                                                            WE
                                                                                                                            MAKE
                                                                                                                            UPDATES
                                                                                                                            TO
                                                                                                                            THIS
                                                                                                                            NOTICE?
                                                                                                                          </h2>
                                                                                                                        </span>
                                                                                                                      </strong>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <em>
                                                                                                                    <strong>
                                                                                                                      In
                                                                                                                      Short:&nbsp;
                                                                                                                    </strong>
                                                                                                                    Yes,
                                                                                                                    we
                                                                                                                    will
                                                                                                                    update
                                                                                                                    this
                                                                                                                    notice
                                                                                                                    as
                                                                                                                    necessary
                                                                                                                    to
                                                                                                                    stay
                                                                                                                    compliant
                                                                                                                    with
                                                                                                                    relevant
                                                                                                                    laws.
                                                                                                                  </em>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <br />
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  We
                                                                                                                  may
                                                                                                                  update
                                                                                                                  this
                                                                                                                  Privacy
                                                                                                                  Notice
                                                                                                                  from
                                                                                                                  time
                                                                                                                  to
                                                                                                                  time.
                                                                                                                  The
                                                                                                                  updated
                                                                                                                  version
                                                                                                                  will
                                                                                                                  be
                                                                                                                  indicated
                                                                                                                  by
                                                                                                                  an
                                                                                                                  updated{" "}
                                                                                                                  <bdt className="block-component" />
                                                                                                                  "Revised"
                                                                                                                  <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                  date
                                                                                                                  at
                                                                                                                  the
                                                                                                                  top
                                                                                                                  of
                                                                                                                  this
                                                                                                                  Privacy
                                                                                                                  Notice.
                                                                                                                  If
                                                                                                                  we
                                                                                                                  make
                                                                                                                  material
                                                                                                                  changes
                                                                                                                  to
                                                                                                                  this
                                                                                                                  Privacy
                                                                                                                  Notice,
                                                                                                                  we
                                                                                                                  may
                                                                                                                  notify
                                                                                                                  you
                                                                                                                  either
                                                                                                                  by
                                                                                                                  prominently
                                                                                                                  posting
                                                                                                                  a
                                                                                                                  notice
                                                                                                                  of
                                                                                                                  such
                                                                                                                  changes
                                                                                                                  or
                                                                                                                  by
                                                                                                                  directly
                                                                                                                  sending
                                                                                                                  you
                                                                                                                  a
                                                                                                                  notification.
                                                                                                                  We
                                                                                                                  encourage
                                                                                                                  you
                                                                                                                  to
                                                                                                                  review
                                                                                                                  this
                                                                                                                  Privacy
                                                                                                                  Notice
                                                                                                                  frequently
                                                                                                                  to
                                                                                                                  be
                                                                                                                  informed
                                                                                                                  of
                                                                                                                  how
                                                                                                                  we
                                                                                                                  are
                                                                                                                  protecting
                                                                                                                  your
                                                                                                                  information.
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <br />
                                                                                                          </div>
                                                                                                          <div
                                                                                                            id="contact"
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(127, 127, 127)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      id="control"
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(0, 0, 0)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <strong>
                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                          <h2>
                                                                                                                            15.
                                                                                                                            HOW
                                                                                                                            CAN
                                                                                                                            YOU
                                                                                                                            CONTACT
                                                                                                                            US
                                                                                                                            ABOUT
                                                                                                                            THIS
                                                                                                                            NOTICE?
                                                                                                                          </h2>
                                                                                                                        </span>
                                                                                                                      </strong>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  If
                                                                                                                  you
                                                                                                                  have
                                                                                                                  questions
                                                                                                                  or
                                                                                                                  comments
                                                                                                                  about
                                                                                                                  this
                                                                                                                  notice,
                                                                                                                  you
                                                                                                                  may{" "}
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <bdt className="block-component">
                                                                                                                        <bdt className="block-component" />
                                                                                                                      </bdt>
                                                                                                                      email
                                                                                                                      us
                                                                                                                      at{" "}
                                                                                                                      <bdt className="question">
                                                                                                                        ideasights.public@gmail.com
                                                                                                                       
                                                                                                                      </bdt>
                                                                                                                      
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <br />
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(89, 89, 89)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                       
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                      <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                               
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <bdt className="block-component" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                              
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <bdt className="block-component" />
                                                                                                                    <bdt className="block-component" />
                                                                                                                    <bdt className="block-component" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span data-custom-class="body_text">
                                                                                                                            <bdt className="block-component">
                                                                                                                              <bdt className="block-component" />
                                                                                                                            </bdt>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </bdt>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                              <bdt className="block-component">
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15
                                                                                                                  }}
                                                                                                                />
                                                                                                              </bdt>
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                      fontSize: 15
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span data-custom-class="body_text">
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                          <bdt className="block-component" />
                                                                                                                        </bdt>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <div
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <br />
                                                                                                          </div>
                                                                                                          <div
                                                                                                            id="request"
                                                                                                            style={{
                                                                                                              lineHeight:
                                                                                                                "1.5"
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                color:
                                                                                                                  "rgb(127, 127, 127)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                  fontSize: 15
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                      "rgb(89, 89, 89)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      fontSize: 15,
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      id="control"
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(0, 0, 0)"
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <strong>
                                                                                                                        <span data-custom-class="heading_1">
                                                                                                                          <h2>
                                                                                                                            16.
                                                                                                                            HOW
                                                                                                                            CAN
                                                                                                                            YOU
                                                                                                                            REVIEW,
                                                                                                                            UPDATE,
                                                                                                                            OR
                                                                                                                            DELETE
                                                                                                                            THE
                                                                                                                            DATA
                                                                                                                            WE
                                                                                                                            COLLECT
                                                                                                                            FROM
                                                                                                                            YOU?
                                                                                                                          </h2>
                                                                                                                        </span>
                                                                                                                      </strong>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                  "rgb(89, 89, 89)"
                                                                                                              }}
                                                                                                            >
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  fontSize: 15,
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <bdt className="block-component" />
                                                                                                                  Based
                                                                                                                  on
                                                                                                                  the
                                                                                                                  applicable
                                                                                                                  laws
                                                                                                                  of
                                                                                                                  your
                                                                                                                  country
                                                                                                                  <bdt className="block-component" />{" "}
                                                                                                                  or
                                                                                                                  state
                                                                                                                  of
                                                                                                                  residence
                                                                                                                  in
                                                                                                                  the
                                                                                                                  US
                                                                                                                  <bdt className="statement-end-if-in-editor" />
                                                                                                                  ,
                                                                                                                  you
                                                                                                                  may
                                                                                                                  <bdt className="else-block">
                                                                                                                    <bdt className="block-component">
                                                                                                                      {" "}
                                                                                                                      have
                                                                                                                      the
                                                                                                                      right
                                                                                                                      to
                                                                                                                      request
                                                                                                                      access
                                                                                                                      to
                                                                                                                      the
                                                                                                                      personal
                                                                                                                      information
                                                                                                                      we
                                                                                                                      collect
                                                                                                                      from
                                                                                                                      you,
                                                                                                                      details
                                                                                                                      about
                                                                                                                      how
                                                                                                                      we
                                                                                                                      have
                                                                                                                      processed
                                                                                                                      it,
                                                                                                                      correct
                                                                                                                      inaccuracies,
                                                                                                                      or
                                                                                                                      delete
                                                                                                                      your
                                                                                                                      personal
                                                                                                                      information.
                                                                                                                      You
                                                                                                                      may
                                                                                                                      also
                                                                                                                      have
                                                                                                                      the
                                                                                                                      right
                                                                                                                      to{" "}
                                                                                                                      <bdt className="block-component" />
                                                                                                                      withdraw
                                                                                                                      your
                                                                                                                      consent
                                                                                                                      to
                                                                                                                      our
                                                                                                                      processing
                                                                                                                      of
                                                                                                                      your
                                                                                                                      personal
                                                                                                                      information.
                                                                                                                      These
                                                                                                                      rights
                                                                                                                      may
                                                                                                                      be
                                                                                                                      limited
                                                                                                                      in
                                                                                                                      some
                                                                                                                      circumstances
                                                                                                                      by
                                                                                                                      applicable
                                                                                                                      law.
                                                                                                                      To
                                                                                                                      request
                                                                                                                      to
                                                                                                                      review,
                                                                                                                      update,
                                                                                                                      or
                                                                                                                      delete
                                                                                                                      your
                                                                                                                      personal
                                                                                                                      information,
                                                                                                                      please{" "}
                                                                                                                      <bdt className="block-component" />
                                                                                                                      fill
                                                                                                                      out
                                                                                                                      and
                                                                                                                      submit
                                                                                                                      a&nbsp;
                                                                                                                    </bdt>
                                                                                                                  </bdt>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    color:
                                                                                                                      "rgb(183, 54, 82)"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        color:
                                                                                                                          "rgb(183, 54, 82)",
                                                                                                                        fontSize: 15
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <a
                                                                                                                        data-custom-class="link"
                                                                                                                        href="https://app.termly.io/notify/9cf05ed3-484f-4251-92f6-e55266e6ff30"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                        target="_blank"
                                                                                                                      >
                                                                                                                        data
                                                                                                                        subject
                                                                                                                        access
                                                                                                                        request
                                                                                                                      </a>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                                <bdt className="block-component">
                                                                                                                  <span data-custom-class="body_text" />
                                                                                                                </bdt>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                              .
                                                                                                            </span>
                                                                                                          </div>
                                                                                                          <style
                                                                                                            dangerouslySetInnerHTML={{
                                                                                                              __html:
                                                                                                                "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                                                                                                            }}
                                                                                                          />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div>
                                                                                                </div>
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</>


<>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #d43255 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: rgb(176, 176, 176) !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #d43255 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #d43255 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: rgb(176, 176, 176) !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: rgb(183, 54, 82) !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
    }}
  />
  <span
    style={{
      display: "block",
      margin: "0 auto 3.125rem",
      width: "11.125rem",
      height: "2.375rem",
      background: "url(data:image/svg+xml"
    }}
  />
  <div data-custom-class="body">
    <div>
      <strong>
        <span style={{ fontSize: 26 }}>
          <h1 data-custom-class="title" id="cookie-policy">Cookie policy</h1>
        </span>
      </strong>
    </div>
    <div>
      <br />
    </div>
    <div>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <strong>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="subtitle">
              Last updated <bdt className="question">February 01, 2025</bdt>
            </span>
          </span>
        </strong>
      </span>
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            This Cookie Policy explains how{" "}
            <bdt className="question">IdeaSights</bdt> ("
            <strong>Company</strong>," "<strong>we</strong>," "
            <strong>us</strong>," and "<strong>our</strong>") uses cookies and
            similar technologies to recognize you when you visit our website
            at&nbsp;
          </span>
        </span>
        <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            <bdt className="question">
              <a
                href="https://ideasights.com"
                target="_blank"
                data-custom-class="link"
              >
                https://ideasights.com
              </a>
            </bdt>
          </span>
        </span>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            {" "}
            ("<strong>Website</strong>"). It explains what these technologies
            are and why we use them, as well as your rights to control our use
            of them.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            In some cases we may use cookies to collect personal information, or
            that becomes personal information if we combine it with other
            information.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
          <strong>
            <span data-custom-class="heading_1">What are cookies?</span>
          </strong>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            Cookies are small data files that are placed on your computer or
            mobile device when you visit a website. Cookies are widely used by
            website owners in order to make their websites work, or to work more
            efficiently, as well as to provide reporting information.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            Cookies set by the website owner (in this case,{" "}
            <bdt className="question">IdeaSights</bdt>) are called "first-party
            cookies." Cookies set by parties other than the website owner are
            called "third-party cookies." Third-party cookies enable third-party
            features or functionality to be provided on or through the website
            (e.g., advertising, interactive content, and analytics). The parties
            that set these third-party cookies can recognize your computer both
            when it visits the website in question and also when it visits
            certain other websites.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
          <strong>
            <span data-custom-class="heading_1">Why do we use cookies?</span>
          </strong>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            We use first-
            <bdt className="block-component" /> and third-
            <bdt className="statement-end-if-in-editor" />
            party cookies for several reasons. Some cookies are required for
            technical reasons in order for our Website to operate, and we refer
            to these as "essential" or "strictly necessary" cookies. Other
            cookies also enable us to track and target the interests of our
            users to enhance the experience on our Online Properties.{" "}
            <bdt className="block-component" />
            Third parties serve cookies through our Website for advertising,
            analytics, and other purposes.{" "}
            <bdt className="statement-end-if-in-editor" />
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
  
   
    
    
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }} />
      <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
        <span style={{ fontSize: 15 }}>
          <strong>
            <u>
              <br />
              <br />
              Essential website cookies:
            </u>
          </strong>
        </span>
      </span>
    </div>
    <div>
      <div>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              These cookies are strictly necessary to provide you with services
              available through our Website and to use some of its features,
              such as access to secure areas.
            </span>
          </span>
        </span>
      </div>
    </div>
    <div />
    <div>
      <div style={{ lineHeight: "1.5" }}>
        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
          <span style={{ fontSize: 15 }}>
            <strong>
              <u>
                <br />
                Analytics and customization cookies:
              </u>
            </strong>
          </span>
        </span>
      </div>
      <p style={{ fontSize: 15, lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              These cookies collect information that is used either in aggregate
              form to help us understand how our Website is being used or how
              effective our marketing campaigns are, or to help us customize our
              Website for you.
            </span>
          </span>
        </span>
      </p>
    
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
          <strong>
            <span data-custom-class="heading_1">
              How can I control cookies on my browser?
            </span>
          </strong>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span data-custom-class="body_text">
        As the means by which you can refuse cookies through your web browser
        controls vary from browser to browser, you should visit your browser's
        help menu for more information. The following is information about how
        to manage cookies on the most popular browsers:
      </span>
    </div>
    <ul>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Chrome</span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Internet Explorer</span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Firefox</span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Safari</span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Edge</span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://help.opera.com/en/latest/web-preferences/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Opera</span>
            </a>
          </span>
        </div>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span data-custom-class="body_text">
        In addition, most advertising networks offer you a way to opt out of
        targeted advertising. If you would like to find out more information,
        please visit:
      </span>
    </div>
    <ul>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="http://www.aboutads.info/choices/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>Digital Advertising Alliance</span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="https://youradchoices.ca/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ color: "rgb(183, 54, 82)", fontSize: 15 }}>
                Digital Advertising Alliance of Canada
              </span>
            </a>
          </span>
        </div>
      </li>
      <li>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ color: "rgb(183, 54, 82)" }}>
            <a
              data-custom-class="link"
              href="http://www.youronlinechoices.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ fontSize: 15 }}>
                European Interactive Digital Advertising Alliance
              </span>
            </a>
          </span>
        </div>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
          <strong>
            <span data-custom-class="heading_1">
              What about other tracking technologies, like web beacons?
            </span>
          </strong>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            Cookies are not the only way&nbsp;
          </span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              to recognize or track visitors to a website. We may use other,
              similar technologies from time to time, like web beacons
              (sometimes called "tracking pixels" or "clear gifs"). These are
              tiny graphics files that contain a unique identifier that enables
              us to recognize when someone has visited our Website
              <bdt className="block-component" /> or opened an email including
              them
              <bdt className="statement-end-if-in-editor" />. This allows us,
              for example, to monitor&nbsp;
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  the traffic patterns of users from one page within a website
                  to another, to deliver or communicate with cookies, to
                  understand whether you have come to the website from an online
                  advertisement displayed on a third-party website, to improve
                  site performance, and to measure the success of email
                  marketing campaigns. In many instances, these technologies are
                  reliant on cookies to function properly, and so declining
                  cookies will impair their functioning.
                </span>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">
                    Do you use Flash cookies or Local Shared Objects?
                  </span>
                </strong>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span data-custom-class="body_text">
          Websites may also use so-called "Flash Cookies" (also known as Local
          Shared Objects or "LSOs") to, among other things, collect and store
          information about your use of our services, fraud prevention, and for
          other site operations.
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span data-custom-class="body_text">
          If you do not want Flash Cookies stored on your computer, you can
          adjust the settings of your Flash player to block Flash Cookies
          storage using the tools contained in the&nbsp;
        </span>
      </span>
      <span data-custom-class="body_text">
        <span style={{ color: "rgb(183, 54, 82)" }}>
          <a
            data-custom-class="link"
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
            target="_BLANK"
          >
            <span style={{ fontSize: 15 }}>Website Storage Settings Panel</span>
          </a>
        </span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          . You can also control Flash Cookies by going to the&nbsp;
        </span>
        <span style={{ color: "rgb(183, 54, 82)" }}>
          <a
            data-custom-class="link"
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
            target="_BLANK"
          >
            <span style={{ fontSize: 15 }}>Global Storage Settings Panel</span>
          </a>
        </span>
      </span>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span data-custom-class="body_text">&nbsp;and&nbsp;</span>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            following the instructions (which may include instructions that
            explain, for example, how to delete existing Flash Cookies (referred
            to "information" on the Macromedia site), how to prevent Flash LSOs
            from being placed on your computer without your being asked, and
            (for Flash Player 8 and later) how to block Flash Cookies that are
            not being delivered by the operator of the page you are on at the
            time).
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Please note that setting the Flash Player to restrict or limit
            acceptance of Flash Cookies may reduce or impede the functionality
            of some Flash applications, including, potentially, Flash
            applications used in connection with our services or online content.
          </span>
        </span>
      </span>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    
    
  
   
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
          <strong>
            <span data-custom-class="heading_1">
              How often will we update this Cookie Policy?
            </span>
          </strong>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">We may update&nbsp;</span>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              this Cookie Policy from time to time in order to reflect, for
              example, changes to the cookies we use or for other operational,
              legal, or regulatory reasons. Please therefore revisit this Cookie
              Policy regularly to stay informed about our use of cookies and
              related technologies.
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              The date at the top of this Cookie Policy indicates when it was
              last updated.
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    
   
    
   
   
    
    
   
    <style
      dangerouslySetInnerHTML={{
        __html:
          "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
      }}
    />
  </div>

  <div style={{ color: "rgb(176, 176, 176)", fontFamily: "Arial, sans-serif", lineHeight: "1.6" }}>
      <h1 id='terms-of-use' style={{ color: "#d43255" }}>Terms of Use</h1>
      <span><strong>Last updated January 31, 2025</strong></span>

      <p style={{color: "#B0B0B0"}}>
        Welcome to IdeaSights. By accessing or using our Service, you agree to be
        bound by these Terms of Use (the "Terms"). If you do not agree to these Terms, you may not
        use the Service.
      </p>

      <h2 style={{ color: "#d43255" }}>1. Eligibility</h2>
      <p style={{color: "#B0B0B0"}}>
        You must be at least 13 years of age to use the Service. By using the Service, you represent
        and warrant that you meet this minimum age requirement. If you are under the age of 18, you
        must have the consent of a parent or guardian to use the Service.
      </p>

      <h2 style={{ color: "#d43255" }}>2. User Responsibilities</h2>
      <h3 style={{ color: "#d43255" }}>2.1 Content Standards</h3>
      <p style={{color: "#B0B0B0"}}>
        You are solely responsible for all content, including but not limited to text, images,
        links, and other materials, that you post, upload, or otherwise make available on the
        Service ("User Content"). You agree not to post or share any content that:
      </p>
      <ul>
        <li style={{color: "#B0B0B0"}}>Contains nudity, sexually explicit material, or pornographic content;</li>
        <li style={{color: "#B0B0B0"}}>Promotes or glorifies the use of illegal drugs, violence, or other criminal activities;</li>
        <li style={{color: "#B0B0B0"}}>Is hateful, harassing, defamatory, or discriminatory;</li>
        <li style={{color: "#B0B0B0"}}>Violates any applicable laws or regulations;</li>
        <li style={{color: "#B0B0B0"}}>Infringes upon the intellectual property rights of others.</li>
      </ul>

      <h3 style={{ color: "#d43255" }}>2.2 Prohibited Activities</h3>
      <p>
        You agree not to:
        <ul>
          <li style={{color: "#B0B0B0"}}>Use the Service to spam, scam, or engage in fraudulent activities;</li>
          <li style={{color: "#B0B0B0"}}>Circumvent or attempt to circumvent any security measures implemented by the Service;</li>
          <li style={{color: "#B0B0B0"}}>Use automated systems (e.g., bots, scrapers) to interact with the Service without prior written permission.</li>
        </ul>
      </p>

      <h3 style={{ color: "#d43255" }}>2.3 Ownership and Licensing</h3>
      <p style={{color: "#B0B0B0"}}>
        You retain ownership of any content you post, upload, or otherwise share on the Service
        ("User Content"). By submitting User Content, you grant IdeaSights a non-exclusive,
        royalty-free, worldwide, perpetual license to use, display, reproduce, distribute, and
        modify the User Content for the purpose of operating, improving, or promoting the Service.
        This license ends when you delete your User Content or terminate your account, except where
        the content has been shared or incorporated into other materials by the Service or its
        users.
      </p>

      <h2 style={{ color: "#d43255" }}>3. Moderation and Content Removal</h2>
      <p style={{color: "#B0B0B0"}}>
        We reserve the right to review, moderate, and/or remove any User Content at our sole
        discretion, without prior notice to you, if we determine that it violates these Terms or is
        otherwise inappropriate.
      </p>

      <h2 style={{ color: "#d43255" }}>4. Limited Liability</h2>
      <p style={{color: "#B0B0B0"}}>
        To the fullest extent permitted by law, the Service and its operators shall not be held
        liable for any damages, losses, or harm resulting from your use of the Service, including
        but not limited to:
      </p>
      <ul>
        <li style={{color: "#B0B0B0"}}>Your reliance on the accuracy or completeness of any User Content;</li>
        <li style={{color: "#B0B0B0"}}>Any technical issues, interruptions, or downtime of the Service;</li>
        <li style={{color: "#B0B0B0"}}>The actions or omissions of other users.</li>
      </ul>
      <p style={{color: "#B0B0B0"}}>
        Your use of the Service is at your own risk, and you agree to indemnify and hold harmless
        the Service and its operators from any claims arising from your use or misuse of the
        Service.
      </p>

      <h2 style={{ color: "#d43255" }}>5. Intellectual Property</h2>
      <p style={{color: "#B0B0B0"}}>
        All content provided by the Service, including but not limited to its design, logos, and
        text, is the intellectual property of the Service and is protected by copyright, trademark,
        and other applicable laws. You may not reproduce, distribute, or create derivative works
        from any portion of the Service without prior written consent.
      </p>

      <h2 style={{ color: "#d43255" }}>6. Changes to These Terms</h2>
      <p style={{color: "#B0B0B0"}}>
        We reserve the right to modify these Terms at any time. Any changes will be effective
        immediately upon posting the updated Terms on the Service. Your continued use of the
        Service constitutes your acceptance of the revised Terms.
      </p>

      <h2 style={{ color: "#d43255" }}>7. Termination</h2>
      <p style={{color: "#B0B0B0"}}>
        We reserve the right to terminate or suspend your access to the Service at any time, for
        any reason, including but not limited to your violation of these Terms.
      </p>
    </div>
</>



            
            <footer className="footer">
                <p>
                    If you have any questions about our policies, please contact us at ideasights.public@gmail.com
                </p>
            </footer>
        </div>
    );
};

export default LegalPage;
