import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';

const ReportPage = () => {
    const { postId } = useParams();
    const navigate = useNavigate();
    const [reason, setReason] = useState('');
    const [details, setDetails] = useState('');
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleReportSubmit = async (e) => {
        e.preventDefault();

        if (!reason) {
            setError('Please provide a reason for reporting.');
            return;
        }

        setIsSubmitting(true);
        setError(null);

        try {
            // Pobierz email twórcy posta
            const postDocRef = doc(db, 'questions', postId);  // Zastąp 'posts' swoją kolekcją
            const postDoc = await getDoc(postDocRef);
            if (!postDoc.exists()) {
                throw new Error("Post not found");
            }

            const postData = postDoc.data();
            const email = postData.email; // Zakładam, że post zawiera pole 'email' twórcy

            // Pobierz email zgłaszającego
            const userEmail = auth.currentUser ? auth.currentUser.email : "Anonymous"; // Zalogowany użytkownik lub Anonymous

            const reportsCollection = collection(db, 'reportsQuestions');

            // Dodaj nowy raport do Firestore
            await addDoc(reportsCollection, {
                postId, // ID posta, który jest zgłaszany
                reason,
                details,
                email, // Email twórcy posta
                userEmail,    // Email osoby zgłaszającej
                reportedAt: serverTimestamp(), // Użyj timestampu serwera Firestore
            });

            alert("Report submitted successfully.");
            navigate(-1); // Powrót do poprzedniej strony
        } catch (error) {
            console.error("Error submitting report:", error);
            setError("An error occurred while submitting the report. Please try again later.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div style={containerStyle}>
            <h2 style={headerStyle}>Report Question</h2>
            <form onSubmit={handleReportSubmit} style={formStyle}>
                <label style={labelStyle}>
                    Reason for Reporting:
                </label>
                <input
                    type="text"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    style={inputStyle}
                    required
                    placeholder='reason'
                    maxLength={200}
                />

                <label style={labelStyle}>
                    Additional Details (optional):
                </label>
                <textarea
                    value={details}
                    onChange={(e) => setDetails(e.target.value)}
                    style={textareaStyle}
                    placeholder='details'
                    maxLength={2000}
                />

                {error && <p style={errorStyle}>{error}</p>}
                <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Submitting...' : 'Submit Report'}
                </button>
            </form>
        </div>
    );
};

// Styles as objects
const containerStyle = {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto', // Centers container horizontally
    padding: '20px',
};


const headerStyle = {
    textAlign: 'center',
    color:'white',
    fontWeight:'300',
    fontSize:'30px'
};

const labelStyle = {
    fontWeight:'300',
    fontSize:'23px',
}

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems:'center',
    width:'100%'
};

const inputStyle = {
    padding: '8px',
};

const textareaStyle = {
    padding: '8px',
    minHeight: '80px',
        height:'300px'
};

const errorStyle = {
    color: 'red',
    textAlign: 'center',
};

export default ReportPage;
