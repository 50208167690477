import React, { useState, useEffect } from 'react';
import { db, auth, onAuthStateChanged, analytics, logEvent } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, increment, deleteDoc } from 'firebase/firestore';

const Social = ({ postId }) => {
    const [isSaved, setIsSaved] = useState(false);
    const [user, setUser] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isReporting, setIsReporting] = useState(false);

    const handleSavePost = async () => {
        if (!user || isSaving) return;
        setIsSaving(true);

        try {
            const userId = user.uid;
            const savedPostDoc = doc(db, `saved/${userId}/savedPosts/${postId}`);
            const postDoc = doc(db, `posts/${postId}`);

            if (isSaved) {
                // Unsaving the post
                await deleteDoc(savedPostDoc);
                setIsSaved(false);
                await updateDoc(postDoc, { saveCount: increment(-1) });
            } else {
                // Saving the post
                await setDoc(savedPostDoc, { postId });
                setIsSaved(true);
                await updateDoc(postDoc, { saveCount: increment(1) });
                 try {
                        // Log the share event to Firebase Analytics
                        if (analytics) {
                            logEvent(analytics, "savePostIdea", {
                                method: "clipboard",
                                post_id: postId,
                                user_id: user ? user.uid : "Anonymous", // Include user info if available
                            });
                        } else {
                            console.warn("Analytics not initialized; share event not logged.");
                        }
                
                        // Increment the share count in the database
                        const postDoc = doc(db, `posts/${postId}`);
                        await updateDoc(postDoc, { shareCount: increment(1) });
                    } catch (error) {
                        console.error("Error updating share count or logging event:", error);
                    }
            }
        } catch (error) {
            console.error('Error saving or unsaving the post:', error);
        } finally {
            setIsSaving(false);
        }
    };

    const handleReportPost = async () => {
        if (isReporting) return;
        setIsReporting(true);
        window.location = `/report/${postId}`;
    };

    const handleShare = async () => {
        const link = `${window.location.origin}/post/${postId}`;
        navigator.clipboard.writeText(link);
        alert('Link copied to clipboard');

        try {
            // Log the share event to Firebase Analytics
            if (analytics) {
                logEvent(analytics, 'shareIdea', {
                    method: 'clipboard',
                    post_id: postId,
                    user_id: user ? user.uid : 'Anonymous',
                });
            }

            // Increment the share count in the database
            const postDoc = doc(db, `posts/${postId}`);
            await updateDoc(postDoc, { shareCount: increment(1) });
        } catch (error) {
            console.error('Error updating share count or logging event:', error);
        }
    };

    useEffect(() => {
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser); // Update user state dynamically
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const postDoc = doc(db, `posts/${postId}`);
                const snapshot = await getDoc(postDoc);

                if (snapshot.exists()) {
                    const postData = snapshot.data();
                }
            } catch (error) {
                console.error('Error fetching post data:', error);
            }
        };

        const checkIfSaved = async () => {
            if (user?.uid) {
                const userId = user.uid;
                const savedPostDoc = doc(db, `saved/${userId}/savedPosts/${postId}`);

                try {
                    const snapshot = await getDoc(savedPostDoc);
                    setIsSaved(snapshot.exists());
                } catch (error) {
                    console.error('Error fetching saved status:', error);
                }
            }
        };

        if (postId) {
            fetchPostData();
        }

        if (user && postId) {
            checkIfSaved();
        } else {
            setIsSaved(false); // Reset saved state when user logs out
        }
    }, [user, postId]); // Re-run when `user` or `postId` changes

    return (
        <>
            {user ? (
                <button onClick={handleSavePost} className="save-button" disabled={isSaving} style={btnStyle}>
                    {isSaved ? 'Unsave' : 'Save'}
                </button>
            ) : null}
            <button style={btnStyle} onClick={handleShare}>
                Share
            </button>
            <button style={btnStyle} onClick={handleReportPost} disabled={isReporting}>
                Report
            </button>
        </>
    );
};

const btnStyle = {
    margin: '5px',
    width: '90px',
    whiteSpace: 'inherit',
    wordBreak: 'keep-all',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex:'5',
};

export default Social;
