



    import React, { useState, useEffect, useCallback, useRef } from "react";
    import { Link, useSearchParams, useNavigate } from "react-router-dom";
    import { db, auth, analytics, logEvent } from "../firebase.js"; 
    import { doc, getDoc, updateDoc, increment } from "firebase/firestore";
    import defimg from "./defimg.jpg";
    import Socialq from './socialq.jsx';
    import './dots.css';
    const serverUrl = import.meta.env.VITE_SERVER_URL;
    
    
    const LIMIT = 20; // Number of posts per batch
    
    const PostList = () => {
        const [posts, setPosts] = useState([]);
        const [filteredPosts, setFilteredPosts] = useState([]);
        const [searchParams] = useSearchParams();
        const searchQuery = searchParams.get('tag'); // Pobieramy parametr 'tag', który służy jako fraza wyszukiwania
        const [hoveredPostId, setHoveredPostId] = useState(null); // State to track hovered post
        const [lastKey, setLastKey] = useState(null); // Track the last key for pagination
        const [loading, setLoading] = useState(false);
        const [hasMore, setHasMore] = useState(true); // Track if there are more posts to load
        const [isAdmin, setIsAdmin] = useState(false);
        const [user, setUser] = useState('');
        const navigate = useNavigate();
        
        const [showConfirmation, setShowConfirmation] = useState(false);
        const [postToDelete, setPostToDelete] = useState(null);
       // let [csrfToken, setCsrfToken] = useState(true);

        const loadMoreRef = useRef();
    
        // const fetchCsrfToken = async () => {
        //                try {
        //                    const response = await fetch(`${serverUrl}/csrf-token`, {
        //                        credentials: 'include', // Include cookies
        //                    });
        //                    const data = await response.json();
        //                    setCsrfToken(data.csrfToken);
        //                } catch (error) {
        //                    console.error('Error fetching CSRF token:', error);
        //                }
        //            };
        //            useEffect(() => {  
        //                fetchCsrfToken();
        //             },[]);
    
        const loadMorePosts = useCallback(async () => {
            if (loading || !hasMore) return; // Prevent additional calls if already loading or no more posts
            setLoading(true);
            
            try {

                const response = await fetch(
                    `${serverUrl}/searchQuestions?limit=${LIMIT}&lastKey=${lastKey || ''}&q=${encodeURIComponent(searchQuery || '')}`, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        credentials: 'include',
                    }
                );
                const data = await response.json();
                console.log('data:', data);
        
                if (data.posts.length > 0) {
                    // Append new posts and update lastKey
                    setPosts(prevPosts => [...prevPosts, ...data.posts]);
                    setFilteredPosts(prevFilteredPosts => [...prevFilteredPosts, ...data.posts]);
                    setLastKey(data.lastKey); // Update lastKey for next batch
                    setHasMore(data.hasMore); // Stop further loads if hasMore is false
                } else {
                    // If no posts returned, set hasMore to false to prevent further calls
                    setHasMore(false);
                }
            } catch (error) {
                console.error("Error loading more posts:", error);
            } finally {
                setLoading(false);
            }
        }, [lastKey, searchQuery, loading, hasMore]);
        
 useEffect(() => {
         const fetchPosts = async () => {
             setPosts([]);
             setFilteredPosts([]);
             setLastKey(null);
             setHasMore(true);
             setLoading(true);
     
             try {
                 const response = await fetch(
                     `${serverUrl}/searchQuestions?limit=${LIMIT}&lastKey=&q=${encodeURIComponent(searchQuery || '')}`,
                     {
                         headers: {
                             'Content-Type': 'application/json',
                         },
                         credentials: 'include', // Wysyłaj ciasteczka z żądaniem
                     }
                 );
     
                 const data = await response.json();
                 if (data.posts.length > 0) {
                     setPosts(data.posts);
                     setFilteredPosts(data.posts);
                     setLastKey(data.lastKey || null);
                     setHasMore(data.hasMore);
                 } else {
                     setHasMore(false);
                 }
             } catch (error) {
                 console.error("Error fetching posts:", error);
             } finally {
                 setLoading(false);
             }
         };
     
         fetchPosts();
     }, [searchQuery]);
        
    
    
        const [isVisible, setIsVisible] = useState();
        useEffect(() => {
            // Log the ref only when it's defined
            if (loadMoreRef.current) {
                let observer = new IntersectionObserver((entries)=>{
                    let entry = entries[0];
                    setIsVisible(entry.isIntersecting);
                });
                observer.observe(loadMoreRef.current);
            }
        }, [loadMoreRef.current]); 
        
    useEffect(()=>{
        if (isVisible){
       loadMorePosts();
        }
    },[isVisible]);
    
    
    
    
    /*
        useEffect(() => {
            // Initial load
            loadMorePosts();
        }, [loadMorePosts]);
    
        useEffect(() => {
            const handleScroll = () => {
                // Check if user has scrolled near the bottom
                if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
                    loadMorePosts();
                }
            };
    
            window.addEventListener("scroll", handleScroll);
            return () => window.removeEventListener("scroll", handleScroll);
        }, [loadMorePosts]);*/
    
    
    
    
     /*  
        const handlePostClick = (postId) => {
            navigate(`/questions/${postId}`); // Navigate to the post detail page
            window.scrollTo(0, 0); // Scroll to the top when navigating to the post detail
        };*/

        const handlePostClick = async (postId, postSlug) => {
            try {
                let watchedTags = [];
                if (searchQuery) {
                    const postRef = doc(db, `questions/${postId}`);
                    const postSnapshot = await getDoc(postRef);
                    
                    if (postSnapshot.exists()) {
                        const postData = postSnapshot.data();
                        const currentCount = postData?.foundByTags?.[searchQuery]?.count || 0;
                        watchedTags = postData.tags || [];
        
                        // Increment count by 1
                        await updateDoc(postRef, {
                            [`foundByTags.${searchQuery}.count`]: currentCount + 1,
                        });
        
                        console.log(`Incremented count for tag: ${searchQuery} in post: ${postId}`);
                    } else {
                        console.log("Post not found");
                    }
                }
                try {
                    // Log the share event to Firebase Analytics
                    if (analytics) {
                        logEvent(analytics, "postEnterQuestion", {
                            method: "clipboard",
                            post_id: postId,
                            user_id: user ? user.uid : "Anonymous", // Include user info if available
                            tags: watchedTags,
                        });
                    } else {
                        console.warn("Analytics not initialized; share event not logged.");
                    }
            
                    // Increment the share count in the database
                    const postDoc = doc(db, `questions/${postId}`);
                    await updateDoc(postDoc, { shareCount: increment(1) });
                } catch (error) {
                    console.error("Error updating share count or logging event:", error);
                }
        
                // Navigate to the post detail page
                navigate(`/questions/${postSlug}/${postId}`);
                window.scrollTo(0, 0);
            } catch (error) {
                console.error("Error updating tags in Firestore:", error);
            }
        };
    
    
    
    
    
        useEffect(() => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                const getAccesslvl = async () => {
                    const currentUser = auth.currentUser;
                    if (currentUser) {
                        setUser(currentUser);
                        const emailToSearch = currentUser.email;
                        const userRef = doc(db, "users", currentUser.uid); // Get user by UID (ensure UID is used)
        
                        try {
                            const userSnapshot = await getDoc(userRef);
        
                            if (userSnapshot.exists()) {
                                const userData = userSnapshot.data();
                                if (userData.accessLevel === 2) {
                                    setIsAdmin(true);
                                }
                            } else {
                                console.log("User not found");
                            }
                        } catch (error) {
                            console.error("Error fetching user data:", error);
                        }
                    } /*else {
                        // User signed out
                        navigate('/');
                    }*/
                };
        
                getAccesslvl();
            });
        
            return unsubscribe;
        }, []);
        
        
        

        
        
        const handleDeleteClick = (post) => {
            setShowConfirmation(true);
            setPostToDelete(post.id);
        };
        
        const cancelDelete = () => {
            setShowConfirmation(false);
            setPostToDelete(null);
        };
        
        const confirmDelete = async () => {
            try {
                // Fetch the CSRF token
                const token = await auth.currentUser.getIdToken();
        
        
                // Make the DELETE request with the token in the headers
                const response = await fetch(`${serverUrl}/questions/${postToDelete}`, {
                    method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                           // 'X-CSRF-Token': csrfToken, // Ensure this is properly set
                            Authorization: `Bearer ${token}`,
                        },
                        credentials: 'include', // Include cookies
                    });
        
                if (!response.ok) {
                    throw new Error('Failed to delete post');
                }
        
                setPosts(posts.filter((p) => p.id !== postToDelete));
                alert("Post deleted successfully!");
            } catch (error) {
                console.error("Error deleting post:", error);
                alert("Could not delete post");
            } finally {
                setShowConfirmation(false);
                setPostToDelete(null);
            }
        };

        if (loading && posts.length === 0) {
            return <p>Loading...</p>;
        }
    
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {filteredPosts.length > 0 ? (
                    filteredPosts.map((post) => (
                        <div
                            key={post.id}
                            style={post.images && post.images.length > 0 ? cardStyle : cardStyleNoImg}
                            onMouseEnter={() => setHoveredPostId(post.id)}
                            onMouseLeave={() => setHoveredPostId(null)}
                            onClick={() => handlePostClick(post.id, post.slug)} // Navigate when the card is clicked
                        >
    
                                <img
                                    src={post.images ? post.images[0] : defimg}
                                    alt={post.header}
                                    style={post.images && post.images.length > 0 ? imgStyle : imgStylenone}
                                />
                                <h3 style={post.images && post.images.length > 0 ? headerStyle : headerStyleNoImg}  className="noScrollbarHeader">{post.header}</h3>
    
                            {hoveredPostId === post.id && (
                                <div
                                    style={socialStyle}
                                    onClick={(e) => e.stopPropagation()} // Prevents the Social component click from triggering the card navigation
                                >
                                    <Socialq postId={post.id} />
                                </div>
                                
                            )}
                             {hoveredPostId === post.id && isAdmin && (
                            <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteClick(post);
                            }}
                            style={deleteButtonStyle}
                        >
                            X
                        </button>
                        )}
                              {/* '...' button for mobile devices */}
                              <p 
                                className="more-button"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevents navigation on button click
                                }}
                            >
                                ...
                            </p>
                        </div>
                    ))
                ) : (
                    <p>No posts found for this search query.</p>
                )}
                <div ref={loadMoreRef} style={{ height: '1px' }}></div>

                {showConfirmation && (
                <div style={confirmationOverlayStyle}>
                    <div style={confirmationBoxStyle}>
                        <p>Are you sure you want to delete this post?</p>
                        <button onClick={confirmDelete} style={confirmButtonStyle}>
                            Yes
                        </button>
                        <button onClick={cancelDelete} style={cancelButtonStyle}>
                            No
                        </button>
                    </div>
                </div>
            )}
            </div>
        );
        
    }



    const cardStyle = {
        position: 'relative',
        border: '1.5px solid rgba(173, 56, 83, 0.8)',
        borderRadius: '8px',
        padding: '15px',
        margin: '5px',
        width: '435px',
        height: '485px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '0.3s',
        backgroundColor: 'rgba(24, 24, 24, 0.8)',
        zIndex: 1,
    };
    const cardStyleNoImg = {
        position: 'relative',
        border: '1.5px solid rgba(173, 56, 83, 0.8)',
        borderRadius: '8px',
        padding: '15px',
        margin: '5px',
        width: '435px',
        height: '485px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: '0.3s',
        backgroundColor: 'rgba(24, 24, 24, 0.8)',
        zIndex: 1,
    };
    
    const socialStyle = {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: 2,
    };
    
    const deleteButtonStyle = {
        position: 'absolute',
        top: '5px',
        left: '5px',
        backgroundColor: '#2E2E2E',
        opacity: '0.5',
        color: 'white',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '25px',
        width: '25px',
        padding: '0',
        lineHeight: '1',
        zIndex: 2,
    };
    
    const imgStyle = {
        height:'auto',
        width:'auto',
        maxWidth: '90%',
        maxHeight: '70%',
        objectFit: 'cover',
        borderRadius: '4px',
    };
    
    const imgStylenone = {
        maxWidth: '100%',
        height: '300px',
        objectFit: 'cover',
        borderRadius: '4px',
        display: 'none',
    };
    
    const headerStyle = {
        fontWeight: '300',
        fontSize: '20px',
        margin: '10px 0 0 0',
        color: 'white',
        textAlign: 'center',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        maxHeight: '120px', // Limit the height of the header area
        overflow: 'auto',  // Enable scrolling if text exceeds maxHeight
        padding: '0 5px',  // Add some padding to prevent text from touching the edges
    };
    const headerStyleNoImg = {
        fontWeight: '300',
        fontSize: '20px',
        margin: '10px 0 0 0',
        color: 'white',
        textAlign: 'center',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        maxHeight: '90%', // Limit the height of the header area
        overflow: 'auto',  // Enable scrolling if text exceeds maxHeight
        padding: '0 5px',  // Add some padding to prevent text from touching the edges
    };
    
    
    const confirmationOverlayStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };
    
    const confirmationBoxStyle = {
        backgroundColor:'#111111',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        border: '1px solid white',
    };
    
    const confirmButtonStyle = {
        color: '#d43255',
        border: 'none',
        padding: '10px 20px',
        marginRight: '10px',
        cursor: 'pointer',
        borderRadius: '4px',
    };
    
    const cancelButtonStyle = {
        color: '#d43255',
        border: 'none',
        padding: '10px 20px',
        cursor: 'pointer',
        borderRadius: '4px',
    };
        
    
    
    
    
    
    export default PostList;
    