import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { auth } from '../firebase';
import './PostDetail.css'; // Import CSS
import { db } from "../firebase.js"; // Firebase database instance
import { onAuthStateChanged } from 'firebase/auth';
import RatingComponentQ from '../components/RatingComponentQ.jsx';
import Socialq from './socialq.jsx';
import EditQuestion from './EditQuestion.jsx';
import QuestionAnalytics from './QuestionAnalytics.jsx';
import ChatButton from './CreateNewChat.jsx';
import { query, collection, where, getDocs, doc, getDoc, updateDoc, addDoc} from 'firebase/firestore';
import { Helmet } from 'react-helmet-async';
const serverUrl = import.meta.env.VITE_SERVER_URL;

const PostDetail = () => {
    const { id } = useParams(); // Gets id from URL
    const [post, setPost] = useState(null);
    const [comments, setComments] = useState([]); // State for comments
    const [newComment, setNewComment] = useState({ text: '' }); // New comment state
    const [userName, setUserName] = useState(''); // State for logged-in user's name
    const [posterName, setPosterName] = useState(''); // State for post author's name
    const [posterUid, setPosterUid] = useState('');
    const [userUid, setUserUid] = useState('');
    const [posterEmail, setPosterEmail] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(0); // For image carousel
    const [isSliding, setIsSliding] = useState(false); // To control sliding animation
    const [slideDirection, setSlideDirection] = useState(''); // To track slide direction (left or right)
    const [views, setViews] = useState(0); // State for view count
    const [lastLoadedKey, setLastLoadedKey] = useState(null); // Track last comment key for pagination
    const [loading, setLoading] = useState(false); 
    const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);
    const COMMENTS_BATCH_SIZE = 20; 

    const loadMoreRef = useRef();


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                fetchUserName(user);  // Fetch user data once authenticated
            }
        });

        return () => unsubscribe();  // Cleanup listener on unmount
    }, []);

    // Function to fetch the user's name from Firebase
    const fetchUserName = async (user) => {
        if (user) {
            const userUid = user.uid;  // Use userUid (from Firebase Authentication)
            const userDocRef = doc(db, 'users', userUid);  // Reference the user document by userUid as document ID
    
            try {
                const docSnapshot = await getDoc(userDocRef);
                if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    setUserName(userData.name);
                    setUserEmail(userData.email);  // If needed, you can still get the email
                    setUserUid(userUid);  // Set the user UID
                } else {
                    console.log("User document not found.");
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }
    };
    
    
    const fetchPosterName = async (email) => {
        const usersRef = query(collection(db, 'users'), where('email', '==', email)); // Firestore query
    
        try {
            const querySnapshot = await getDocs(usersRef);
            if (!querySnapshot.empty) {
                querySnapshot.forEach(async (doc) => {
                    const userData = doc.data();
                    setPosterName(userData.name);
                    setPosterEmail(userData.email);
    
                    // Await the UID retrieval and then set it
                    const uid = await getUidByEmail(userData.email);
                    setPosterUid(uid);
                });
            }
        } catch (error) {
            console.error('Error fetching poster name:', error);
        }
    };
    const getUidByEmail = async (email) => {
        const usersRef = query(collection(db, 'users'), where('email', '==', email)); // Firestore query
    
        try {
            const querySnapshot = await getDocs(usersRef);
            if (!querySnapshot.empty) {
                let userUid = null;
                querySnapshot.forEach((doc) => {
                    userUid = doc.id; // The document ID is the UID in Firestore
                });
                return userUid;
            } else {
                console.log('No user found with the provided email.');
                return null;
            }
        } catch (error) {
            console.error('Error fetching UID by email:', error);
            return null;
        }
    };
        

    const preloadImages = (images) => {
        const preloadedImages = [];
        images.forEach((imageUrl) => {
            const img = new Image();
            img.src = imageUrl;
            preloadedImages.push(img);
        });
    };



    useEffect(() => {
        const postRef = doc(db, 'questions', id); // Firestore document reference

        getDoc(postRef)
            .then((docSnapshot) => {
                if (docSnapshot.exists()) {
                    const postData = docSnapshot.data();
                    setPost(postData);
                    fetchPosterName(postData.email);

                    if (postData.images) preloadImages(postData.images);

                    // Generate a session key to track views for this post
                    const sessionKey = `viewed_${id}`;

                    // Only increment views if the post hasn't been viewed in this session
                    if (!sessionStorage.getItem(sessionKey)) {
                        const updatedViews = typeof postData.views === 'number' ? postData.views + 1 : 1;

                        // Update views in Firestore
                        updateDoc(postRef, { views: updatedViews })
                            .then(() => {
                                setViews(updatedViews);
                                // Store the session key to avoid recounting views in this session
                                sessionStorage.setItem(sessionKey, 'true');
                            })
                            .catch((error) => console.error('Error updating views:', error));
                    } else {
                        // If already viewed in this session, just set the current views in state
                        setViews(postData.views || 0);
                    }
                } else {
                    console.log('Post not found');
                }
            })
            .catch((error) => console.error('Error fetching post:', error));

        // Fetch comments and user data via the server
        fetchUserName();
        fetchComments();
    }, [id]);
    

    // const fetchCsrfToken = async () => {
    //     const response = await fetch(`${serverUrl}/csrf-token`, {
    //         credentials: 'include', // Include cookies
    //     });
    //     const data = await response.json();
    //     return data.csrfToken;
    // };

    const fetchComments = async () => {
        if (loading || allCommentsLoaded) return;
        setLoading(true);
    
        try {
           // const csrfToken = await fetchCsrfToken();
            const response = await fetch(`${serverUrl}/questions/${id}/comments?limit=${COMMENTS_BATCH_SIZE}&lastKey=${lastLoadedKey || ''}`, {
                headers: {
                    'Content-Type': 'application/json',
                   // 'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
            });
    
            if (response.ok) {
                const { comments: newComments, lastKey, hasMore } = await response.json();
                if (newComments.length > 0) {
                    setComments((prevComments) => [...prevComments, ...newComments]);
                    setLastLoadedKey(lastKey);
                }
                if (!hasMore) {
                    setAllCommentsLoaded(true);
                }
            } else {
                console.error('Failed to fetch comments. Status:', response.status);
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setLoading(false);
        }
    };
    








    const handleCommentSubmit = async (e) => {
        e.preventDefault();
        // Ensure the user is signed in and comment text is provided
        //fetchUserName();
        if (userName && newComment.text && auth.currentUser) {
            // Reference to the comments sub-collection for this specific post
            const commentsRef = collection(db, 'questions', id, 'comments');
    
            const commentToAdd = {
                name: userName,
                text: newComment.text,
                timestamp: new Date(), // Optionally add a timestamp to each comment
            };
    
            try {
                // Add the comment to the Firestore sub-collection
                await addDoc(commentsRef, commentToAdd);
    
                // Update the local state for comments
                setComments([...comments, commentToAdd]);
                setNewComment({ text: '' });
    
                // Reference to the post document to update the comment count
                const postRef = doc(db, 'questions', id);
    
                // Read the current comments count
                const postSnapshot = await getDoc(postRef);
                if (postSnapshot.exists()) {
                    const postData = postSnapshot.data();
                    let currentCount = postData.commentsCount || 0;
    
                    // Increment the count
                    const updatedCount = currentCount + 1;
    
                    // Update the comments count in the post document
                    await updateDoc(postRef, { commentsCount: updatedCount });
                }
            } catch (error) {
                console.error('Error adding comment or updating count:', error);
            }
        } else if (!userName) {
            alert('You need to sign in to add a comment');
        }
    };



    let touchStartX = 0;
    let touchEndX = 0;

    const handleTouchStart = (e) => {
        touchStartX = e.targetTouches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndX = e.targetTouches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (touchStartX - touchEndX > 50 && (touchStartX - touchEndX) != touchStartX) {
            showNextImage(); // Swipe left
        }

        if (touchEndX - touchStartX > 50) {
            showPreviousImage(); // Swipe right
        }
    };

    const showPreviousImage = () => {
        if (isSliding) return;

        const newIndex = currentImageIndex === 0 ? post.images.length - 1 : currentImageIndex - 1;
        setSlideDirection('right');
        //setNextImageIndex(newIndex);
        setCurrentImageIndex(newIndex);
        setIsSliding(true);
        setTimeout(() => {
           // setCurrentImageIndex(newIndex);
            setIsSliding(false);
        }, 500);
    };

    const showNextImage = () => {
        if (isSliding) return;

        const newIndex = currentImageIndex === post.images.length - 1 ? 0 : currentImageIndex + 1;
        setSlideDirection('left');
        //setNextImageIndex(newIndex);
        setCurrentImageIndex(newIndex);
        setIsSliding(true);
        setTimeout(() => {
            //setCurrentImageIndex(newIndex);
            setIsSliding(false);
        }, 500);
    };


  
    
    const [isVisible, setIsVisible] = useState();
    useEffect(() => {
        // Log the ref only when it's defined
        if (loadMoreRef.current) {
            let observer = new IntersectionObserver((entries)=>{
                let entry = entries[0];
                setIsVisible(entry.isIntersecting);
            });
            observer.observe(loadMoreRef.current);
        }
    }, [loadMoreRef.current]); 
    
useEffect(()=>{
    if (isVisible){
    fetchComments();
    }
},[isVisible]);


    if (!post) return <p>Loading...</p>;



    return (
        <>
        {post && post.header && post && post.header &&
        post.description  && post.images && post.slug && post.id && (

            <Helmet>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "DiscussionForumPosting",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": `https://ideasights.com/questions/${post.slug}/${post.id}`
                },
                "headline": post.header,
                "description": post.description || "Explore this post on IdeaSights!",
                "datePublished": post.createdAt?.toISOString() || new Date().toISOString(),
                "dateModified": post.updatedAt?.toISOString() || new Date().toISOString(),
                "author": {
                  "@type": "Person",
                  "name": post.email
                },
                "image": post.images?.[0] || "https://ideasights.com/default-image.png",
                "keywords": post.tags?.join(", ") || "",
                "interactionStatistic": {
                  "@type": "InteractionCounter",
                  "interactionType": "https://schema.org/CommentAction",
                  "userInteractionCount": post.commentsCount || 0
                }
              })}
            </script>
            <title>{post.header} - IdeaSights</title>
            <meta name="description" content={post.description || "Explore this post on IdeaSights!"} />
            <link rel="canonical" href={`https://ideasights.com/post/${post.slug}/${post.id}`} />
            <meta property="og:title" content={post.header} />
            <meta property="og:description" content={post.description || "Explore this post on IdeaSights!"} />
            <meta property="og:image" content={post.images?.[0] || "https://ideasights.com/default-image.png"} />
          </Helmet>
          
)}


        <div className="post-detail">
            {userUid == posterUid && <EditQuestion post={post} postId={id} />}
            {userUid == posterUid && <QuestionAnalytics postId={id} />}

            <div className="toppart">
                <div className="left-column">
                    <h1 className="post-header">{post.header}</h1>

                    {post.images && post.images.length > 0 && (
                        <div
                            className="imgdiv"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            <p id="leftarrow" onClick={showNextImage}>
                                {'<'}
                            </p>
                            <div className={`carousel-image-container ${isSliding ? `sliding ${slideDirection}` : ''}`}>
                                <img
                                    src={post.images[currentImageIndex]}
                                    alt={post.header}
                                    className="post-image current"
                                />

                            </div>
                            <p id="rightarrow" onClick={showPreviousImage}>
                                {'>'}
                            </p>
                        </div>
                    )}
                    <p className="post-tags">
                        <strong>User:</strong> {posterName}
                    </p>
                  {/* <p className="post-tags">
                        <strong>Tags:</strong> {post.tags.join(', ')}
                    </p>*/}
                   {/* <p className='post-tags'>
                        <strong>Views:</strong> {views}
                    </p>*/}
                    {post.allowRating && <RatingComponentQ postId={id} />}
                    <Socialq postId={id} />
                     {post.allowContact && auth.currentUser && userUid != posterUid && <ChatButton userEmail={userEmail} posterEmail={posterEmail} />}
                    


                </div>

                <div className="right-column">
                    <p className="post-description">{post.description}</p>
                </div>
            </div>
                <div className="comments-section">
                    <h2 className="post-header">Answers</h2>
                    <div className="justcomments">
                        {comments.length > 0 ? (
                            comments.map((comment, index) => (
                                <div key={index} className="comment">
                                    <p className="comment-author">{comment.name}</p>
                                    <p className="comment-text">{comment.text}</p>
                                </div>
                            ))
                        ) : (
                            <p>No answers yet, be first!</p>
                        )}
                        <div ref={loadMoreRef} style={{ height: '1px' }}></div>
                    </div>
                    <form className="comment-form" onSubmit={handleCommentSubmit}>
                        <textarea
                            placeholder="Type here"
                            value={newComment.text}
                            onChange={(e) => setNewComment({ text: e.target.value })}
                            required
                            maxLength={2000}
                        ></textarea>
                        <button type="submit">Add answer</button>
                    </form>
                </div>
        </div>
        </>
    );
};

export default PostDetail;