import React from "react";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import withgoogle from "../../images/googleImg.png";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase.js";

// Function for Google Sign-In
const signWithGoogle = async ({ setVisible }) => {
  try {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    // Log in using Google popup
    const result = await signInWithPopup(auth, provider);

    // Get user data
    const user = result.user;
    console.log(user);

    // Check if the user already exists in Firestore
    const userExists = await checkIfUserExists(user.uid);

    if (!userExists) {
      // If user doesn't exist, save their data
      await postusername(user.uid, user.displayName, user.email);
    }

    // Get access token (optional, if needed for further actions)
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;

    // Hide modal after successful login
    setVisible(false);

    console.log("Sign-in successful!");
  } catch (error) {
    // Error handling
    console.error("Error during Google Sign-In:", error);

    if (error.code === "auth/popup-blocked") {
      console.log("Popup blocked. User might need to allow popups.");
    }

    if (error.code === "auth/cancelled-popup-request") {
      console.log("Another popup request was made before this one.");
    }
  }
};

// Function to check if the user already exists in Firestore
async function checkIfUserExists(uid) {
  const userRef = doc(db, "users", uid); // Reference to user's document in Firestore
  const userDoc = await getDoc(userRef);

  // Return true if the user document exists, false otherwise
  return userDoc.exists();
}

// Function to save new user data in Firestore
async function postusername(uid, name, email) {
  const userRef = doc(db, "users", uid); // Reference to the user's document in Firestore

  try {
    await setDoc(userRef, {
      name: name,
      email: email,
      accessLevel: 0, // Default access level for new users
    });
    console.log("User data saved successfully!");
  } catch (error) {
    console.error("Error saving user data:", error);
  }
}

// React component rendering the sign-in button
const GoogleSignInButton = ({ setVisible }) => {
  return (
    <p
      style={{ userSelect: "none", cursor: "pointer" }}
      onClick={() => signWithGoogle({ setVisible })}
    >
      <img className="withGoogle" src={withgoogle} alt="Sign in with Google" />
      Sign in with Google
    </p>
  );
};

export default GoogleSignInButton;
