import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase'; // Import Firebase auth

// Create a Context for Authentication
const AuthContext = createContext();

// AuthProvider component that will wrap your app
export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);  // State to track the authenticated user

  useEffect(() => {
    // Log when the component mounts

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {  // Log when the user logs in
        setAuthUser(user);  // Set the auth user
      } else {
        console.log('User is logged out');  // Log when the user logs out
        setAuthUser(null);  // Set authUser to null when the user logs out
      }
    });

    return () => {
      unsubscribe(); // Clean up the listener when the component unmounts
    };
  }, []);  // Empty dependency array means this effect runs once on mount

  // Provide the authUser context to all components in your app
  return (
    <AuthContext.Provider value={{ authUser }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use authentication state
export const useAuth = () => useContext(AuthContext);
