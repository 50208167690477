import React, { useState, useEffect, useRef }  from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc, setDoc, addDoc, serverTimestamp, deleteDoc, getDoc } from "firebase/firestore";
import { db, auth, signOut } from '../firebase.js';


import GoogleSignInButton from "./SignWithGoogle.jsx";


const SignUpDiv = ({visible, setVisible, setOtherVisible}) => {
    const signUpRef = useRef(null);


    function checkIfEmailOrUsernameExists(email, username) {
        const usersCollection = collection(db, "users");
    
        const emailQuery = query(usersCollection, where("email", "==", email));
        const usernameQuery = query(usersCollection, where("name", "==", username));
    
        return Promise.all([
            getDocs(emailQuery),
            getDocs(usernameQuery),
        ]).then(([emailSnapshot, usernameSnapshot]) => {
            const emailExists = !emailSnapshot.empty; // Firestore returns an empty snapshot if no results
            const usernameExists = !usernameSnapshot.empty;
    
            if (emailExists) {
                return "email";
            } else if (usernameExists) {
                return "username";
            } else {
                return "nottaken";
            }
        }).catch(error => {
            console.error("Error checking email or username:", error);
            throw error; // Ensure the caller can handle errors
        });
    }

    
async function postusername(uid, name, email, dateOfBirth) {

    const userRef = doc(db, "users", uid);  // Reference to the user's document in Firestore

    try {
        await setDoc(userRef, {
            name: name,
            email: email,
            dateOfBirth: dateOfBirth,
            accessLevel: 0, // Default access level for new users
        });
        console.log("User data saved successfully!");
    } catch (error) {
        console.error("Error saving user data:", error);
    }
    
}


    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        const username = event.target.usernameinput.value;
        const email = event.target.emailinput.value;
        const password = event.target.passwordinput.value;
        const passwordrepeat = event.target.passwordrepeatinput.value;
        let dateOfBirth = new Date(event.target.age.value);
        let now = new Date();
        let yearDiff = now.getFullYear() - dateOfBirth.getFullYear();
        let monthDiff =  now.getMonth() - dateOfBirth.getMonth();
        let dayDiff =  now.getDate() - dateOfBirth.getDate();
        if (yearDiff < 13 || ((yearDiff == 13 && monthDiff < 0) ||(yearDiff == 13 && monthDiff == 0 && dayDiff < 0))){
            alert('You have to be at least 13 to create account!');
            return;
        }

        if (password === passwordrepeat) {
            if (password.length >= 8){
               checkIfEmailOrUsernameExists(email, username)
                   .then((resp) => {
                       if (resp === 'nottaken') {
                        createUserWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                          const user = userCredential.user;
                      
                          // Zapisz dane użytkownika w Firestore
                          postusername(user.uid, username, email, dateOfBirth);
                      
                          // Wyślij e-mail weryfikacyjny
                          sendEmailVerification(user).then(() => {
                            alert('A verification email has been sent. Please verify your email before logging in.');
                      
                            // Wyloguj użytkownika po wysłaniu e-maila weryfikacyjnego
                            signOut(auth)
                              .then(() => {
                                console.log("User has been signed out after registration.");
                                setVisible(false); // Zamknij formularz rejestracji
                              })
                              .catch((error) => {
                                console.error("Error during sign out:", error);
                              });
                          }).catch((error) => {
                            console.error("Error sending email verification:", error);
                          });
                        })
                        .catch((error) => {
                          console.error("Error creating user:", error);
                        });
                       } else {
                           console.log("Email or username taken:", resp);
                           resp == 'email'? alert('Email is already in use') : alert('Username is already taken');
                       }
                   })
                   .catch((error) => {
                       console.error("Error checking username/email:", error);
                   });

                 } else {
                    alert('Password must be at least 8 characters long');
                   }

           } else {
               alert('Passwords do not match');
           }
    };

    function toOther() {
        setVisible(false);
        setOtherVisible(true);
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (signUpRef.current && !signUpRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setVisible]);



    return (
        visible && (
            <div id="signupdiv" className="sign" ref={signUpRef}>
                <label>Sign Up</label>
                <form id="signupform" onSubmit={handleSubmit}>
                    <input id="usernameinput" placeholder="username" required type="text" />
                    <input id="age" placeholder="Date of birth" type="date" required/>
                    <input id="emailinput" placeholder="email" type="email" required />
                    <input id="passwordinput" placeholder="password" type="password" required />
                    <input id="passwordrepeatinput" placeholder="repeat password" type="password" required />
                    <button type="submit">Submit</button>
                </form>
                <p style={{ userSelect: 'none' }} id="gotosignin" onClick={toOther}>Already Have an account? Sign In</p>
                <GoogleSignInButton setVisible={setVisible}/>
            </div>
        )
    );
};

export default SignUpDiv;