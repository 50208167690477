import React, { useRef, useEffect } from 'react';
import { sendPasswordResetEmail, getAuth } from 'firebase/auth';

const ResetPassword = ({ visible, setVisible, setLoginVisible }) => {
    const resetDiv = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resetDiv.current && !resetDiv.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setVisible]);

    const resetPassword = (email) => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("Password reset email sent! Check your inbox.");
                setVisible(false);
                setLoginVisible(true);
            })
            .catch((error) => {
                console.error("Error sending password reset email:", error);
                alert("Error sending password reset email. Please try again.");
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = event.target.email.value; // Pobierz wartość z inputu
        resetPassword(email); // Wywołaj funkcję z emailem
    };

    return (
        visible && (
            <div id="resetPassword" className="sign" ref={resetDiv}>
                <label>Enter your email and we'll send you a link to reset your password</label>
                <form id="signupform" onSubmit={handleSubmit}>
                    <input id="emailinput" name="email" placeholder="email" type="email" required />
                    <button type="submit">Submit</button>
                </form>
            </div>
        )
    );
};

export default ResetPassword;
