import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { db, auth } from '../firebase';
import { collection, query, where, onSnapshot, doc, getDoc, getDocs } from 'firebase/firestore';

const ChatList = () => {
    const [chats, setChats] = useState([]);
    const [userId, setUserId] = useState(null);
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(true);
    const [usersNames, setUsersNames] = useState({}); // To store user email -> name mapping
    const [decryptedMessages, setDecryptedMessages] = useState({}); // To store decrypted messages for each chat

    // Fetch the current user's name based on email
    const fetchUserName = async (email) => {
        const usersRef = collection(db, 'users'); // Reference to the users collection
        const q = query(usersRef, where('email', '==', email)); // Query by email
    
        try {
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                    const userData = doc.data();
                    setUserName(userData.name); // Set the user's name
                });
            } else {
                console.log('User not found');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    // Fetch participant names based on emails
    const fetchParticipantsNames = async (participantEmails) => {
        const usersRef = collection(db, 'users');
        let users = {};
    
        for (let email of participantEmails) {
            if (usersNames[email] || users[email]) continue; // Skip if the user is already fetched
    
            // Optimistically set a placeholder to trigger a re-render
            setUsersNames((prevNames) => ({ ...prevNames, [email]: 'Fetching...' }));
    
            const q = query(usersRef, where('email', '==', email));
            try {
                const snapshot = await getDocs(q);
                if (!snapshot.empty) {
                    snapshot.forEach((doc) => {
                        const userData = doc.data();
                        users[email] = userData.name; // Save the user name
                    });
                } else {
                    users[email] = 'Unknown'; // Fallback for unknown users
                }
            } catch (error) {
                console.error('Error fetching user data for participant:', error);
                users[email] = 'Error'; // Fallback for errors
            }
        }
    
        setUsersNames((prevNames) => ({ ...prevNames, ...users }));
    };
    

    // Fetch chat key and decrypt the last message
    const fetchChatKey = async (chatId) => {
        const keyDoc = doc(db, 'chatKeys', chatId); // Reference to the specific chat key document
    
        try {
            const snapshot = await getDoc(keyDoc);
            if (!snapshot.exists()) {
                throw new Error('Chat key not found');
            }
    
            const { key } = snapshot.data(); // The saved randomKey
            return key;
        } catch (error) {
            console.error('Error fetching chat key:', error);
            throw error;
        }
    };

    const decryptMessage = (encryptedMessage, key) => {
        if(!encryptedMessage){
            return;
        }
        const bytes = CryptoJS.AES.decrypt(encryptedMessage, key);
        const originalMessage = bytes.toString(CryptoJS.enc.Utf8);
        return originalMessage;
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.email);
                fetchUserName(user.email); // Fetch username when user logs in
                setLoading(false);
            } else {
                setUserId(null);
                setUserName(''); // Clear username if no user is logged in
                setLoading(false);
                console.log('No user logged in');
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (!userId) return;
    
        const chatsRef = collection(db, 'chats');
        const q = query(chatsRef, where('participants', 'array-contains', userId)); // Query chats where user is a participant
    
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const userChats = [];
            const allParticipantEmails = new Set();
    
            snapshot.forEach((doc) => {
                const chat = doc.data();
                const id = doc.id;
                userChats.push([id, chat]);
    
                chat.participants.forEach((email) => allParticipantEmails.add(email));
            });
    
            setChats(userChats); // Update chats state
    
            // Fetch participant names (this can be async and independent of decrypted message)
            fetchParticipantsNames(Array.from(allParticipantEmails));
        });
    
        return () => unsubscribe();
    }, [userId]);
    
    // Separate effect to fetch decrypted messages after `chats` are updated
    useEffect(() => {
        if (chats.length === 0) return; // No chats to decrypt
    
        // Decrypt messages only after chats are set
        chats.forEach(([id, chat]) => {
            fetchChatKey(id)
                .then((key) => {
                    const decryptedLastMessage = decryptMessage(chat.lastMessage, key);
                    setDecryptedMessages((prev) => ({
                        ...prev,
                        [id]: decryptedLastMessage || 'No messages yet',
                    }));
                })
                .catch((error) => console.error('Error decrypting message:', error));
        });
    }, [chats]); // Dependency on chats, so decryption happens after chats are set
    

    if (loading) {
        return <p>Loading your chats...</p>;
    }

    if (!userId) {
        return <p>Please log in to see your chats.</p>;
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h2 style={{ color: 'white', display: 'flex', alignSelf: 'center' }}>Your Chats</h2>
            {chats.length > 0 ? (
                chats.map(([id, chat]) => (
                    <Link key={id} to={`/chat/${id}`} style={linkStyle}>
                        <div style={{ margin: '10px 5px', padding: '10px', border: '1px solid white' }}>
    <strong>Chat with:</strong>{' '}
    {chat.participants
        .filter((u) => u !== userId)
        .map((participantEmail) => {
            return usersNames[participantEmail] || 'Loading...'; // Fallback if name isn't fetched
        })
        .join(', ')}
    <br />
    <small>
        <strong>Last message:</strong>{' '}
        {decryptedMessages[id] || 'No messages yet'}
    </small>
</div>

                    </Link>
                ))
            ) : (
                <p>No chats available.</p>
            )}
        </div>
    );
};

export default ChatList;

const linkStyle = {
    color: 'white',
    textDecoration: 'none',
};
