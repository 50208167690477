import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useAuth } from './AuthContext';

import isLogo from "../../images/isLogo.png";

const Navbar = ({setSignUpVisible, setSignInVisible, setChooseFormVisible, setProfileSettingsVisible, setIsMenuVisible}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1164);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1164);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const authUser = useAuth();

  const handleSearch = () => {
    
    if (!searchQuery || searchQuery.trim() === '') {
       location.pathname.startsWith('/questions') ? navigate('/questions') : navigate('/');
      }
      else {
   if (location.pathname.startsWith('/questions')) {
      navigate(`/questions/?tag=${encodeURIComponent(searchQuery)}`);
    } else {
      navigate(`/?tag=${encodeURIComponent(searchQuery)}`);
    }
}
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };



  return (
    <nav id="navbar" style={{ zIndex: 20 }}>
      <div className="navdiv">
        <a id="mainimg" href="/">
          <img id="logo" src={isLogo} alt="Logo" />
        </a>

        <input
          placeholder="Search"
          id="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button id="searchButton" onClick={handleSearch}>
          <ion-icon id="searchicon" name="search-outline"></ion-icon>
        </button>
      </div>
      <div className="navdiv">
        {!authUser.authUser && (
            <>
        <p style={{ userSelect: 'none' }} id="signup" onClick={() => setSignUpVisible(true)}>
          Sign Up
        </p>
        <p style={{ userSelect: 'none' }} id="signin" onClick={() => setSignInVisible(true)}>
          Sign In
        </p>
        </>
)}
        {authUser.authUser && (
            <>
          <p style={{ userSelect: 'none' }} id="postbtn" onClick={() => setChooseFormVisible(true)}>
          <ion-icon className='navicon' name="add-outline"></ion-icon>
          </p>
        
        <p style={{ userSelect: 'none' }} id="editaccountbtn" onClick={() => setProfileSettingsVisible(true)}>
          <ion-icon className="navicon" name="person-outline"></ion-icon>
        </p>
        </>)}
        {(isMobile && 
        <p style={{ userSelect: 'none' }} id="moremobile" onClick={()=>{setIsMenuVisible(true)}}>
          <ion-icon className="navicon" name="reorder-four-outline"></ion-icon>
        </p>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
