import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, query, where, onSnapshot, doc, getDoc, getDocs, orderBy, addDoc, updateDoc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import { useAuth } from './AuthContext';
const serverUrl = import.meta.env.VITE_SERVER_URL;
const Chat = () => {
    const { chatId } = useParams();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [lastKey, setLastKey] = useState(null);
    const messagesEndRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [isParticipant, setIsParticipant] = useState('');
   // const [authUser, setAuthUser] = useState(null);
   let authUser = useAuth();
    const navigate = useNavigate();
    const [usernamesCache, setUsernamesCache] = useState({});



/*
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
        if (typeof window !== 'undefined') {
            setHeight(window.innerHeight);
        }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);




   
/*
    useEffect(() => {
        console.log('Auth state change activated:', authUser);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthUser(user);
            } else {
                setAuthUser(null);
                navigate('/'); // Redirect unauthenticated users
            }
        });

        return () => unsubscribe();
    }, []);
*/


    const fetchChatKey = async () => {
        try {
            const chatDoc = await getDoc(doc(db, 'chatKeys', chatId));
            if (!chatDoc.exists()) throw new Error('Chat key not found');
            return chatDoc.data().key;
        } catch (error) {
            console.error('Error fetching chat key:', error);
            throw error;
        }
    };

    const encryptMessage = (message, key) => CryptoJS.AES.encrypt(message, key).toString();

    const decryptMessage = (encryptedMessage, key) => {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedMessage, key);
            return bytes.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error('Error decrypting message:', error);
            return '[Decryption Failed]';
        }
    };



    // const fetchCsrfToken = async () => {
    //     const response = await fetch(`${serverUrl}/csrf-token`, {
    //         credentials: 'include',
    //     });
    //     const data = await response.json();
    //     return data.csrfToken;
    // };

    const fetchMessages = async (loadMore = false) => {
        if (loading || allMessagesLoaded) return;
        setLoading(true);

        try {
            const chatKey = await fetchChatKey();
            const messagesCollection = collection(db, 'chats', chatId, 'messages');
            let q;

            if (lastKey) {
                q = query(
                    messagesCollection,
                    orderBy('createdAt', 'desc'),
                    startAfter(lastKey),
                    limit(20)
                );
            } else {
                q = query(messagesCollection, orderBy('createdAt', 'desc'), limit(20));
            }

            const snapshot = await getDocs(q);

            if (snapshot.empty) {
                setAllMessagesLoaded(true);
                return;
            }

            const fetchedMessages = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                content: decryptMessage(doc.data().content, chatKey),
            }));

            setMessages((prev) =>
                loadMore ? [...prev, ...fetchedMessages.reverse()] : fetchedMessages.reverse()
            );

            setLastKey(snapshot.docs[snapshot.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching messages:', error);
        } finally {
            setLoading(false);
        }
    };
    
    



    const verifyParticipant = async () => {
        try {
            //const csrfToken = await fetchCsrfToken();
            const email = auth.currentUser?.email;

            if (!email) {
                setIsParticipant(false);
                return;
            }

            const response = await fetch(`${serverUrl}/chat/${chatId}/verifyParticipant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'X-CSRF-Token': csrfToken,
                },
                credentials: 'include',
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                const { isParticipant } = await response.json();
                setIsParticipant(isParticipant);

                if (!isParticipant) {
                    navigate('/'); // Redirect non-participants
                }
            } else {
                console.error('Error response from server:', response.status, response.statusText);
                setIsParticipant(false);
                navigate('/');
            }
        } catch (error) {
            console.error('Error verifying participation:', error);
            setIsParticipant(false);
            navigate('/');
        }
    };
    

    useEffect(() => {
        if (authUser) {
            verifyParticipant();
                    const email = auth.currentUser?.email;
                        setUserEmail(email); 

        }
    }, [authUser]);

    
    const sendMessage = async () => {
        if (!newMessage.trim()) return;

        try {
            
            const user = auth.currentUser;
            if (!user) throw new Error('User not authenticated');

            const chatKey = await fetchChatKey(chatId);
            const encryptedMessage = encryptMessage(newMessage, chatKey);

            await addDoc(collection(db, 'chats', chatId, 'messages'), {
                content: encryptedMessage,
                senderEmail: user.email,
                createdAt: new Date(),
            });
            await updateDoc(doc(db, 'chats', chatId), {
                lastMessage: encryptedMessage  // Store the latest message
            });
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };


    const handleScroll = (e) => {
        if (e.target.scrollTop === 0 && lastKey) {
            fetchMessages(true); // Load older messages
        }
    };

    const fetchUsernameByEmail = async (email) => {
        if (usernamesCache[email]) {
            return usernamesCache[email]; // Return cached username
        }
    
        try {
            const usersCollection = collection(db, 'users');
            const q = query(usersCollection, where('email', '==', email));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                const username = querySnapshot.docs[0].data().name; // Assuming 'name' field contains the username
                setUsernamesCache((prevCache) => ({ ...prevCache, [email]: username }));
                return username;
            } else {
                console.warn(`No user found with email: ${email}`);
                return '[Unknown]'; // Default if no user found
            }
        } catch (error) {
            console.error('Error fetching username:', error);
            return '[Error]';
        }
    };
    




    useEffect(() => {
        if (isParticipant) {
            const messagesCollection = collection(db, 'chats', chatId, 'messages');
            const unsubscribe = onSnapshot(
                query(messagesCollection, orderBy('createdAt', 'asc')),
                async (snapshot) => {
                    const chatKey = await fetchChatKey();
                    const newMessages = await Promise.all(
                        snapshot.docs.map(async (doc) => {
                            const data = doc.data();
                            const username = await fetchUsernameByEmail(data.senderEmail);
                            return {
                                id: doc.id,
                                ...data,
                                userName: username,
                                content: decryptMessage(data.content, chatKey),
                            };
                        })
                    );
                    setMessages(newMessages);
                }
            );
            return unsubscribe;
        }
    }, [chatId, isParticipant]);
    

    useEffect(() => {
        if (messagesEndRef.current) messagesEndRef.current.scrollIntoView();
    }, [messages]);



    const mDiv = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height:'100%',
    };
    const mainContainer = {
        top:'0',
        height: '80%',
        overflowY: 'scroll',
        margin: '10px',
        width: '100%',
        positon:'absolute',
    };
    const headerStyle = {
        color: 'white',
        fontSize: '11px',
        margin: '0',
        fontWeight: '300',
    };
    const pStyle = {
        fontSize: '25px',
        fontWeight: '300',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
    };
    const inputStyle = {
        width:'80%',
    }


    if (isParticipant === null) {
        return <div>Loading...</div>; 
    }
    return (
        <div style={mDiv}>
            <div
                style={mainContainer}
                onScroll={handleScroll}
            >
                {messages.length === 0 && !loading && <p>No messages to display.</p>}
                {messages.map((msg, index) => (
    <div key={`${msg.id}-${index}`}>
        <header style={headerStyle}>{msg.userName}</header>
        <p style={pStyle}>{msg.content}</p>
    </div>
))}

                <div ref={messagesEndRef} />
            </div>
            <input
            style={inputStyle}
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

export default Chat;
