import React, { useState, useRef, useEffect } from "react";
import { sendPasswordResetEmail, signOut, getAuth, onAuthStateChanged, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; // Zakładam, że db (Firestore) jest poprawnie skonfigurowane w pliku firebase.js
const serverUrl = import.meta.env.VITE_SERVER_URL;
const ProfileSettings = ({ visible, setVisible }) => {
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userDate, setUserDate] = useState();
  const [rusureVisible, setRusureVisible] = useState(false);
  //const [csrfToken, setCsrfToken] = useState('');
  const ref = useRef(null);
  const auth = getAuth(); // Pobranie instancji Firebase Authentication


//  const fetchCsrfToken = async () => {
//                try {
//                    const response = await fetch(`${serverUrl}/csrf-token`, {
//                        credentials: 'include', // Include cookies
//                    });
//                    const data = await response.json();
//                    setCsrfToken(data.csrfToken);
//                } catch (error) {
//                    console.error('Error fetching CSRF token:', error);
//                }
//            };
//            useEffect(() => {  
//                fetchCsrfToken();
//             },[]);


const handleSubmit = async (event) => {
  event.preventDefault();

  const newUsername = event.target.usernameinputsett.value.trim();
  const newDate = event.target.ageSet.value; // Should be in 'YYYY-MM-DD' format
  const user = auth.currentUser;

  if (!user) {
    console.error("User not logged in.");
    return;
  }

  try {
    const emailToSearch = user.email;
    const usersCollection = collection(db, "users");

    // Query Firestore for the logged-in user by email
    const emailQuery = query(usersCollection, where("email", "==", emailToSearch));
    const userSnapshot = await getDocs(emailQuery);

    if (userSnapshot.empty) {
      console.error("User not found.");
      return;
    }

    // Get the user document
    const userDoc = userSnapshot.docs[0]; // Assuming emails are unique
    const userDocRef = doc(db, "users", userDoc.id);
    const userData = userDoc.data();

    const currentUsername = userData.name;
    const currentDate = userData.dateOfBirth;

    // Check if there are any changes to the username or date of birth
    if (newUsername === currentUsername && newDate === currentDate) {
      alert("No changes detected.");
      return;
    }

    // If the username is empty, retain the current username
    const updatedUsername = newUsername || currentUsername;

    // Check if the new username is already taken (only if it's different from the current one)
    if (updatedUsername !== currentUsername) {
      const usernameQuery = query(usersCollection, where("name", "==", updatedUsername));
      const usernameSnapshot = await getDocs(usernameQuery);

      if (!usernameSnapshot.empty) {
        alert("Username is already taken.");
        return;
      }
    }

    let dateOfBirth = new Date(newDate);
        let now = new Date();
        let yearDiff = now.getFullYear() - dateOfBirth.getFullYear();
        let monthDiff =  now.getMonth() - dateOfBirth.getMonth();
        let dayDiff =  now.getDate() - dateOfBirth.getDate();
        if (yearDiff < 13 || ((yearDiff == 13 && monthDiff < 0) ||(yearDiff == 13 && monthDiff == 0 && dayDiff < 0))){
            alert("Date is incorrect!");
            return;
        }

    // Update the user's document in Firestore
    await updateDoc(userDocRef, {
      name: updatedUsername,
      dateOfBirth: newDate,
    });

    alert("Profile updated successfully.");
    setVisible(false); // Close settings view
    setUserData(); // Refresh user data
  } catch (error) {
    console.error("Error updating profile:", error);
    alert("An error occurred while updating your profile. Please try again.");
  }
};




  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Wylogowanie zakończone sukcesem
        window.location = "/";
      })
      .catch((error) => {
        console.error("Error during logout:", error);
      });
  };

  const resetPassword = () => {
    const user = auth.currentUser;

    if (user) {
      const email = user.email;

      sendPasswordResetEmail(auth, email)
        .then(() => {
          alert("Password reset email sent! Check your inbox.");
        })
        .catch((error) => {
          console.error("Error sending password reset email:", error);
          alert("Error sending password reset email. Please try again.");
        });
    } else {
      alert("User not logged in.");
    }
  };

  const setUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const emailToSearch = user.email;

      try {
        const usersCollection = collection(db, "users");
        const userQuery = query(usersCollection, where("email", "==", emailToSearch));
        const querySnapshot = await getDocs(userQuery);

        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          setUserName(userData.name);
          setUserEmail(userData.email);
          setUserDate(userData.dateOfBirth);
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      console.log("User is signed out.");
    }
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserData(); // Pobierz dane użytkownika tylko, gdy jest zalogowany
      } else {
        console.log("User not logged in.");
      }
    });
  
    return () => unsubscribe(); // Usuń listener, gdy komponent zostanie odmontowany
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setVisible(false);
        setRusureVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visible, setVisible]);


  const deleteAccount = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      alert("Couldn't find user.");
      return;
    }
  
    try {
      const email = user.email;
  
      // Prompt user for password to confirm deletion
      const password = prompt("Please re-enter your password to confirm deletion:");
      if (!password) {
        alert("Password is required to delete the account.");
        return;
      }
  
      // Reauthenticate the user
      const credential = EmailAuthProvider.credential(email, password);
      await reauthenticateWithCredential(user, credential);
  
  
      // Make a POST request to backend to delete user and their data
      const response = await fetch(`${serverUrl}/delete-user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //"X-CSRF-Token": csrfToken,
        },
        credentials: "include", // Include cookies for CSRF protection
        body: JSON.stringify({
          email: user.email,
          userId: user.uid, // Pass user's UID to backend
        }),
      });
  
      if (response.ok) {
        alert("Account deleted successfully");
        window.location.href = "/"; // Redirect after deletion
      } else {
        const errorData = await response.json();
        console.error("Error deleting account:", errorData);
        alert("Failed to delete account. Please try again.");
      }
    } catch (error) {
      if (error.code === "auth/wrong-password") {
        alert("Incorrect password. Please try again.");
      } else if (error.code === "auth/network-request-failed") {
        alert("Network error. Please check your connection and try again.");
      } else {
        console.error("Error during account deletion:", error);
        alert("An error occurred. Please try again.");
      }
    }
  };

  const handleDeleteAccount = () => {
    deleteAccount().then(() => {
      setVisible(false); // Zamknij okno ustawień, jeśli usunięcie się powiedzie
    }).catch((error) => {
      console.error("Error in account deletion process:", error);
    });
  };
  

  return (
    visible && (
      <div ref={ref} className="sign">
        <label>Your account</label>
        <form id="settingsform" onSubmit={(e) => { e.preventDefault(); handleSubmit(e, { userName, userDate })}}>
      <input
        id="usernameinputsett"
        type="text"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Username"
      />
      <input
        id="ageSet"
        type="date"
        value={userDate}
        onChange={(e) => setUserDate(e.target.value)}
      />
      <input
        id="emailinputsett"
        placeholder={userEmail}
        type="email"
        disabled
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button type="submit">Save changes</button>
        <button
          type="button"
          id="deleteacc"
          onClick={() => setRusureVisible(true)}
        >
          Delete account
        </button>
      </div>
    </form>
        <div
          style={{
            justifyContent: "space-between",
            width: "100%",
            display: "flex",
            margin: "10px",
            height: "54px",
          }}
        >
          <p
            className="pover"
            style={{ userSelect: "none" }}
            id="logoutbtn"
            onClick={handleLogout}
          >
            Log out
          </p>
          <p
            className="pover"
            style={{ userSelect: "none" }}
            id="resetpassword"
            onClick={resetPassword}
          >
            Change password
          </p>
        </div>

        {rusureVisible && (
          <div id="rusure" className="sign">
            <label>Are You sure?</label>
            <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
              <button id="nodelete" onClick={() => setRusureVisible(false)}>
                No
              </button>
              <button id="yesdelete" onClick={handleDeleteAccount}>
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default ProfileSettings;
