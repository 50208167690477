import React, { useState, useRef, useEffect } from 'react';
import { collection, addDoc, serverTimestamp, doc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from './AuthContext'; // Zakładam, że masz AuthContext
import {db, storage} from '../firebase';


const AddQuestionForm = ({ visible, setVisible }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [postHeader, setPostHeader] = useState('');
  const [fileArray, setFileArray] = useState([]);
  const [tags, setTags] = useState('');
  const [postText, setPostText] = useState('');
  const [allowRating, setAllowRating] = useState(true);
  const [allowContact, setAllowContact] = useState(true);
  const [previewImages, setPreviewImages] = useState([]);
  const ref = useRef(null);

  const authUser = useAuth();

  const handleNext = () => {
    if (currentStep === 1 && !postHeader) {
      alert('Please fill in the header.');
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewImages = [];

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        newPreviewImages.push({ url: event.target.result, file });
        setPreviewImages([...previewImages, ...newPreviewImages]);
      };
      reader.readAsDataURL(file);
    });

    setFileArray([...fileArray, ...files]);
  };

  const handleRemoveImage = (index) => {
    const updatedFileArray = fileArray.filter((_, i) => i !== index);
    const updatedPreviewImages = previewImages.filter((_, i) => i !== index);

    setFileArray(updatedFileArray);
    setPreviewImages(updatedPreviewImages);
  };

  const uploadImages = async () => {
    const uploadPromises = fileArray.map(async (file) => {
      const storageReference = storageRef(storage, `posts/${Date.now()}_${file.name}`);
      const snapshot = await uploadBytes(storageReference, file);
      return getDownloadURL(snapshot.ref);
    });

    return Promise.all(uploadPromises);
  };

  const handleDrop = (e) => {
    e.preventDefault(); // Prevent default behavior (e.g., opening files in a new tab)
  
    const files = Array.from(e.dataTransfer.files); // Extract the dropped files
    const newPreviewImages = [];
  
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        newPreviewImages.push({ url: event.target.result, file });
        setPreviewImages((prev) => [...prev, ...newPreviewImages]); // Update state incrementally
      };
      reader.readAsDataURL(file);
    });
  
    setFileArray((prev) => [...prev, ...files]); // Update the file array state
  };
  


const handleDragOver = (event) => {
    event.preventDefault();
};



  const generateSlug = (title) => {
    return title
      .toLowerCase() 
      .trim()
      .replace(/[^a-z0-9\s-]/g, '') 
      .replace(/\s+/g, '-') 
      .replace(/-+/g, '-'); 
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!authUser) {
      alert('You need to sign in to add a post.');
      return;
    }
  
    try {
      const imageUrls = await uploadImages();
  

      let baseSlug = generateSlug(postHeader);
      let uniqueSlug = baseSlug;
  

      const postsCollection = collection(db, 'questions');
      let slugExists = true;
      let counter = 1;
  
      while (slugExists) {
        const q = query(postsCollection, where('slug', '==', uniqueSlug));
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          slugExists = false; 
        } else {

          uniqueSlug = `${baseSlug}-${counter}`;
          counter++;
        }
      }
  
      const postData = {
        header: postHeader,
        slug: uniqueSlug,
        tags: tags.split(',').map((tag) => tag.trim()),
        email: authUser.authUser.email,
        description: postText,
        allowRating,
        allowContact,
        createdAt: serverTimestamp(),
        images: imageUrls,
      };
  
      await addDoc(postsCollection, postData);
  
      alert('Post successfully added!');
      resetForm();
      setVisible('');
    } catch (error) {
      console.error('Error adding post: ', error);
      alert('An error occurred while adding your post. Please try again.');
    }
  };

  const resetForm = () => {
    setPostHeader('');
    setFileArray([]);
    setTags('');
    setPostText('');
    setAllowRating(true);
    setAllowContact(true);
    setPreviewImages([]);
    setCurrentStep(1);
  };

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setVisible(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [visible, setVisible]);

  return (
    visible && (
      <div id="addpostdiv" className="addpost" ref={ref}>
        <form onSubmit={handleSubmit} id="addpostform">
          {currentStep === 1 && (
            <section className="sectio" id="s1">
              <label>Enter Header for your question</label>
              <input
                id="postheader"
                type="text"
                value={postHeader}
                onChange={(e) => setPostHeader(e.target.value)}
                placeholder="header"
                required
                maxLength="200"
              />
              <button id="tos2" type="button" onClick={handleNext}>
                next
              </button>
            </section>
          )}

          {currentStep === 2 && (
            <section className="sectio" id="s2">
              <p className="back-button" id="backToS1" onClick={handleBack}>
                ←
              </p>
              <label className="filelabel">
                <input
                  id="imginput"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                />
                Select a photo
                <p style={{ alignSelf: "center" }}>(click here)</p>
              </label>
              <label>or</label>
              <div id="dropZone" className="drop-zone"
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
                Drag & Drop images here
              </div>
              <h4>(optional)</h4>
              <div id="imgcontainer">
                {previewImages.map((img, index) => (
                  <div key={index} className="previmg">
                    <img src={img.url} alt="Preview" />
                    <button
                      type="button"
                      className="removeBtn"
                      onClick={() => handleRemoveImage(index)}
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              <button id="tos3" type="button" onClick={handleNext}>
                next
              </button>
            </section>
          )}

          {currentStep === 3 && (
            <section className="sectio" id="s3">
              <p className="back-button" id="backToS2" onClick={handleBack}>
                ←
              </p>
              <label id="populartagslabel">Add categories</label>
              <textarea
                id="tagz"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="Add tags/categories for better searching results (separate tags with commas)"
                maxLength="500"
              ></textarea>
              <h4>(optional)</h4>
              <button id="tos4" type="button" onClick={handleNext}>
                next
              </button>
            </section>
          )}

          {currentStep === 4 && (
            <section className="sectio" id="s4">
              <p className="back-button" id="backToS3" onClick={handleBack}>
                ←
              </p>
              <label>Describe the question</label>
              <textarea
                id="posttext"
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
                placeholder="Description"
                required
                maxLength="4000"
              ></textarea>
              <label className="styled-checkbox">
                Enable rating
                <input
                  type="checkbox"
                  id="allowRating"
                  checked={allowRating}
                  onChange={() => setAllowRating(!allowRating)}
                />
                <span className="checkbox-custom"></span>
              </label>
              <label className="styled-checkbox">
                Enable contact
                <input
                  type="checkbox"
                  id="allowContact"
                  checked={allowContact}
                  onChange={() => setAllowContact(!allowContact)}
                />
                <span className="checkbox-custom"></span>
              </label>
              <button type="submit">Post</button>
            </section>
          )}
        </form>
      </div>
    )
  );
};

export default AddQuestionForm;
