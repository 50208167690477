import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import Social from "./social.jsx";
import './dots.css';
const serverUrl = import.meta.env.VITE_SERVER_URL;
const PostList = () => {
    const { username } = useParams();
    const [posts, setPosts] = useState([]);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [postToDelete, setPostToDelete] = useState(null);
    const [currentUserEmail, setCurrentUserEmail] = useState(null);
    const [hoveredPostId, setHoveredPostId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lastKey, setLastKey] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const LIMIT = 20; // Set the limit per page load
    const navigate = useNavigate();

    const loadMoreRef = useRef();

    // const fetchCsrfToken = async () => {
    //     const response = await fetch(`${serverUrl}/csrf-token`, {
    //         credentials: 'include', // Include cookies
    //     });
    //     const data = await response.json();
    //     return data.csrfToken;
    // };

    // Fetch current user email on auth state change
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUserEmail(user ? user.email : null);
        });
        return unsubscribe;
    }, []);

    // Fetch posts by username from server
    const loadMorePosts = useCallback(async () => {
        if (loading || !hasMore) return; // Prevent additional calls if already loading or no more posts
        setLoading(true);
    
        try {
           // const csrfToken = await fetchCsrfToken();
            const response = await fetch(
                `${serverUrl}/posts/user/${username}?limit=${LIMIT}&lastKey=${lastKey || ''}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        //'X-CSRF-Token': csrfToken, // Include CSRF token
                    },
                    credentials: 'include', // Ensure cookies are sent with the request
                }
            );
    
            if (!response.ok) {
                throw new Error('Failed to fetch posts');
            }
    
            const data = await response.json();
    
            if (data.posts.length > 0) {
                // Append new posts and update lastKey
                setPosts(prevPosts => [...prevPosts, ...data.posts]);
                setLastKey(data.lastKey); // Update lastKey for next batch
                setHasMore(data.hasMore); // Stop further loads if hasMore is false
            } else {
                setHasMore(false); // No more posts
            }
        } catch (error) {
            console.error("Error loading more posts:", error);
        } finally {
            setLoading(false);
        }
    }, [lastKey, loading, hasMore, username]);
    















    const [isVisible, setIsVisible] = useState();
    useEffect(() => {
        // Log the ref only when it's defined
        if (loadMoreRef.current) {
            let observer = new IntersectionObserver((entries)=>{
                let entry = entries[0];
                setIsVisible(entry.isIntersecting);
            });
            observer.observe(loadMoreRef.current);
        }
    }, [loadMoreRef.current]); 
    
useEffect(()=>{
    if (isVisible){
   loadMorePosts();
    }
},[isVisible]);




    const handleDeleteClick = (post) => {
        setShowConfirmation(true);
        setPostToDelete(post.id);
    };


  


    const confirmDelete = async () => {
        try {
            // Fetch the CSRF token
           // const csrfToken = await fetchCsrfToken();
            const token = await auth.currentUser.getIdToken();
    
    
            // Make the DELETE request with the token in the headers
            const response = await fetch(`${serverUrl}/posts/${postToDelete}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                   // 'X-CSRF-Token': csrfToken, // Include CSRF token
                    Authorization: `Bearer ${token}`,
                },
                credentials: 'include', // Ensure cookies are sent with the request
            });
    
            if (!response.ok) {
                throw new Error('Failed to delete post');
            }
    
            setPosts(posts.filter((p) => p.id !== postToDelete));
            alert("Post deleted successfully!");
        } catch (error) {
            console.error("Error deleting post:", error);
            alert("Could not delete post");
        } finally {
            setShowConfirmation(false);
            setPostToDelete(null);
        }
    };
    

    const cancelDelete = () => {
        setShowConfirmation(false);
        setPostToDelete(null);
    };

    const handlePostClick = (postId, postSlug) => {
        navigate(`/post/${postSlug}/${postId}`);
        window.scrollTo(0, 0);
    };

    if (loading && posts.length === 0) {
        return <p>Loading...</p>;
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {posts.length > 0 ? (
                posts.map((post) => (
                    <div
                        key={post.id}
                        style={post.images && post.images.length > 0 ? cardStyle : cardStyleNoImg}
                        onMouseEnter={() => setHoveredPostId(post.id)}
                        onMouseLeave={() => setHoveredPostId(null)}
                        onClick={() => handlePostClick(post.id, post.slug)}
                    >
                        <img
                            src={post.images ? post.images[0] : null}
                            alt={post.header}
                            style={post.images && post.images.length > 0 ? imgStyle : imgStylenone}
                        />
                        <h3 style={post.images && post.images.length > 0 ? headerStyle : headerStyleNoImg} className="noScrollbarHeader">{post.header}</h3>

                        {hoveredPostId === post.id && (
                            <>
                                {currentUserEmail === post.email && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(post);
                                        }}
                                        style={deleteButtonStyle}
                                    >
                                        X
                                    </button>
                                )}
                                <div
                                    style={socialStyle}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <Social postId={post.id} />
                                </div>
                            </>
                        )}
                          {/* '...' button for mobile devices */}
                          <p 
                            className="more-button"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents navigation on button click
                               
                            }}
                        >
                            ...
                        </p>
                    </div>
                ))
            ) : (
                <p>No posts found</p>
            )}
 <div ref={loadMoreRef} style={{ height: '1px' }}></div>
            {showConfirmation && (
                <div style={confirmationOverlayStyle}>
                    <div style={confirmationBoxStyle}>
                        <p>Are you sure you want to delete this post?</p>
                        <button onClick={confirmDelete} style={confirmButtonStyle}>
                            Yes
                        </button>
                        <button onClick={cancelDelete} style={cancelButtonStyle}>
                            No
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

const cardStyle = {
    position: 'relative',
    border: '1.5px solid rgba(173, 56, 83, 0.8)',
    borderRadius: '8px',
    padding: '15px',
    margin: '5px',
    width: '435px',
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.3s',
    backgroundColor: 'rgba(24, 24, 24, 0.8)',
    zIndex: 1,
};
const cardStyleNoImg = {
    position: 'relative',
    border: '1.5px solid rgba(173, 56, 83, 0.8)',
    borderRadius: '8px',
    padding: '15px',
    margin: '5px',
    width: '435px',
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.3s',
    backgroundColor: 'rgba(24, 24, 24, 0.8)',
    zIndex: 1,
};

const socialStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 2,
};

const deleteButtonStyle = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    backgroundColor: '#2E2E2E',
    opacity: '0.5',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25px',
    width: '25px',
    padding: '0',
    lineHeight: '1',
    zIndex: 2,
};

const imgStyle = {
    height:'auto',
    width:'auto',
    maxWidth: '90%',
    maxHeight: '70%',
    objectFit: 'cover',
    borderRadius: '4px',
};

const imgStylenone = {
    maxWidth: '100%',
    height: '300px',
    objectFit: 'cover',
    borderRadius: '4px',
    display: 'none',
};

const headerStyle = {
    fontWeight: '300',
    fontSize: '20px',
    margin: '10px 0 0 0',
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    maxHeight: '120px', // Limit the height of the header area
    overflow: 'auto',  // Enable scrolling if text exceeds maxHeight
    padding: '0 5px',  // Add some padding to prevent text from touching the edges
};
const headerStyleNoImg = {
    fontWeight: '300',
    fontSize: '20px',
    margin: '10px 0 0 0',
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    maxHeight: '90%', // Limit the height of the header area
    overflow: 'auto',  // Enable scrolling if text exceeds maxHeight
    padding: '0 5px',  // Add some padding to prevent text from touching the edges
};


const confirmationOverlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const confirmationBoxStyle = {
    backgroundColor:'#111111',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    border: '1px solid white',
};

const confirmButtonStyle = {
    color: '#d43255',
    border: 'none',
    padding: '10px 20px',
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: '4px',
};

const cancelButtonStyle = {
    color: '#d43255',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '4px',
};
    




    
export default PostList;
