import React, { useState, useEffect } from 'react';
import { db, auth, onAuthStateChanged, analytics, logEvent} from '../firebase';
import { doc, getDoc, setDoc, updateDoc, increment, deleteDoc } from 'firebase/firestore'
//import {  } from 'firebase/analytics';

const Social = ({ postId }) => {
    const [isSaved, setIsSaved] = useState(false);
    const [user, setUser] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isReporting, setIsReporting] = useState(false);

    const handleSavePost = async () => {
        if (!user || isSaving) return;
        setIsSaving(true);
    
        try {
            const userId = user.uid;
            const savedPostDoc = doc(db, `saved/${userId}/savedQuestions/${postId}`);
            const postDoc = doc(db, `questions/${postId}`);
    
            if (isSaved) {
                // Unsaving the post
                await deleteDoc(savedPostDoc);
                setIsSaved(false);
                await updateDoc(postDoc, { saveCount: increment(-1) });
            } else {
                // Saving the post
                await setDoc(savedPostDoc, { postId });
                setIsSaved(true);
                await updateDoc(postDoc, { saveCount: increment(1) });
                try {
                    // Log the share event to Firebase Analytics
                    if (analytics) {
                        logEvent(analytics, "savePostQuestion", {
                            method: "clipboard",
                            post_id: postId,
                            user_id: user ? user.uid : "Anonymous", // Include user info if available
                        });
                    } else {
                        console.warn("Analytics not initialized; event not logged.");
                    }
            
                    // Increment the share count in the database
                    const postDoc = doc(db, `questions/${postId}`);
                    await updateDoc(postDoc, { shareCount: increment(1) });
                } catch (error) {
                    console.error("Error updating share count or logging event:", error);
                }
            }
        } catch (error) {
            console.error("Error saving or unsaving the post:", error);
        } finally {
            setIsSaving(false);
        }
    };


const handleReportPost = async () => {
    if (isReporting) return;
    setIsReporting(true);
   window.location = `/reportQuestion/${postId}`;
};



const handleShare = async () => {
    const link = `${window.location.origin}/questions/${postId}`;
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard");

    try {
        // Log the share event to Firebase Analytics
        if (analytics) {
            logEvent(analytics, "shareQuestion", {
                method: "clipboard",
                post_id: postId,
                user_id: user ? user.uid : "Anonymous", // Include user info if available
            });
        } else {
            console.warn("Analytics not initialized; share event not logged.");
        }

        // Increment the share count in the database
        const postDoc = doc(db, `questions/${postId}`);
        await updateDoc(postDoc, { shareCount: increment(1) });
    } catch (error) {
        console.error("Error updating share count or logging event:", error);
    }
};


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchPostData = async () => {
            try {
                const postDoc = doc(db, `questions/${postId}`);
                const snapshot = await getDoc(postDoc);
        
                if (snapshot.exists()) {
                    const postData = snapshot.data();
                }
            } catch (error) {
                console.error("Error fetching post data:", error);
            }
        };

        const checkIfSaved = async () => {
            if (user?.uid) {
                const userId = user.uid;
                const savedPostDoc = doc(db, `saved/${userId}/savedQuestions/${postId}`);
        
                try {
                    const snapshot = await getDoc(savedPostDoc);
                    setIsSaved(snapshot.exists());
                } catch (error) {
                    console.error("Error fetching saved status:", error);
                }
            }
        };

        if (postId) {
            fetchPostData();
        }
        
        if (user && postId) {
            checkIfSaved();
        }
    }, [user, postId]);

    return (
        <>
            {user ? (
                <button onClick={handleSavePost} className="save-button" disabled={isSaving} style={btnStyle}>
                    {isSaved ? 'Unsave' : 'Save'}
                </button>
            ) : null}
            <button style={btnStyle} onClick={handleShare}>
                Share
            </button>
            <button style={btnStyle} onClick={handleReportPost} disabled={isReporting}>
                Report
            </button>
        </>
    );
};

const btnStyle = {
    margin: '5px',
    width: '90px',
    whiteSpace: 'inherit',
    wordBreak: 'keep-all',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex:'5',
};

export default Social;
