import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase.js";
import defimg from "./defimg.jpg";
import Social from './social.jsx';
import './dots.css';
const serverUrl = import.meta.env.VITE_SERVER_URL;
const SavedPostsList = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState(null);
    const [lastKey, setLastKey] = useState(null); // Track lastKey for pagination
    const [loadingMore, setLoadingMore] = useState(false); // Loading state for infinite scroll
    const [hoveredPostId, setHoveredPostId] = useState(null); // State to track hovered post
    const observerRef = useRef(); // Ref for intersection observer
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setCurrentUser(user);
                fetchSavedPosts(user.uid);
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        });
        return () => unsubscribe();
    }, []);

    // const fetchCsrfToken = async () => {
    //     const response = await fetch(`${serverUrl}/csrf-token`, {
    //         credentials: 'include', // Include cookies
    //     });
    //     const data = await response.json();
    //     return data.csrfToken;
    // };

    const fetchSavedPosts = async (userId, lastKeyParam = null) => {
        try {
           // const csrfToken = await fetchCsrfToken();
            const limit = 20; // Number of posts to load per batch
            const response = await fetch(`${serverUrl}/savedPosts/${userId}?limit=${limit}${lastKeyParam ? `&lastKey=${lastKeyParam}` : ''}`, {
                headers: {
                    'Content-Type': 'application/json',
                    //'X-CSRF-Token': csrfToken, // Include CSRF token
                },
                credentials: 'include', // Ensure cookies are sent with the request
            });
    
            if (response.ok) {
                const data = await response.json();
                setPosts(prevPosts => [...prevPosts, ...data.posts]);
                setLastKey(data.lastKey); // Update lastKey with the new value from the server
                setLoading(false);
                setLoadingMore(false); // Reset loading state for infinite scroll
            } else {
                console.error('Failed to fetch saved posts from server');
                setLoading(false);
                setLoadingMore(false);
            }
        } catch (error) {
            console.error('Error fetching saved posts:', error);
            setLoading(false);
            setLoadingMore(false);
        }
    };
    

    // Infinite scrolling with Intersection Observer
    useEffect(() => {
        if (loading || loadingMore || !lastKey) return;

        const observer = new IntersectionObserver(entries => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setLoadingMore(true);
                fetchSavedPosts(currentUser.uid, lastKey); // Load more posts with the lastKey
            }
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [lastKey, loading, loadingMore, currentUser]);

    const handlePostClick = (postId,postSlug) => {
        navigate(`/post/${postSlug}/${postId}`);
        window.scrollTo(0, 0);
    };

    if (loading) {
        return <p>Loading saved posts...</p>;
    }

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {posts.length > 0 ? (
                posts.map((post) => (
                    <div key={post.id} style={post.images && post.images.length > 0 ? cardStyle : cardStyleNoImg} onMouseEnter={() => setHoveredPostId(post.id)}
                    onMouseLeave={() => setHoveredPostId(null)}
                    onClick={() => handlePostClick(post.id,post.slug)}>
                            <img
                                src={post.images ? post.images[0] : defimg}
                                alt={post.header}
                                style={post.images && post.images.length > 0 ? imgStyle : imgStylenone}
                            />
                            <h3 style={post.images && post.images.length > 0 ? headerStyle : headerStyleNoImg}  className="noScrollbarHeader">{post.header}</h3>
                        {hoveredPostId === post.id && (
                            <div style={socialStyle} onClick={(e) => e.stopPropagation()}>
                                <Social postId={post.id} />
                            </div>
                        )}
                          {/* '...' button for mobile devices */}
                          <p 
                            className="more-button"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents navigation on button click
                            
                            }}
                        >
                            ...
                        </p>
                    </div>
                ))
            ) : (
                <p>No saved posts found.</p>
            )}
            <div ref={observerRef} style={{ height: '20px' }}></div>
        </div>
    );
};
const cardStyle = {
    position: 'relative',
    border: '1.5px solid rgba(173, 56, 83, 0.8)',
    borderRadius: '8px',
    padding: '15px',
    margin: '5px',
    width: '435px',
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.3s',
    backgroundColor: 'rgba(24, 24, 24, 0.8)',
    zIndex: 1,
};
const cardStyleNoImg = {
    position: 'relative',
    border: '1.5px solid rgba(173, 56, 83, 0.8)',
    borderRadius: '8px',
    padding: '15px',
    margin: '5px',
    width: '435px',
    height: '485px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: '0.3s',
    backgroundColor: 'rgba(24, 24, 24, 0.8)',
    zIndex: 1,
};

const socialStyle = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 2,
};

const deleteButtonStyle = {
    position: 'absolute',
    top: '5px',
    left: '5px',
    backgroundColor: '#2E2E2E',
    opacity: '0.5',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '25px',
    width: '25px',
    padding: '0',
    lineHeight: '1',
    zIndex: 2,
};

const imgStyle = {
    height:'auto',
    width:'auto',
    maxWidth: '90%',
    maxHeight: '70%',
    objectFit: 'cover',
    borderRadius: '4px',
};

const imgStylenone = {
    maxWidth: '100%',
    height: '300px',
    objectFit: 'cover',
    borderRadius: '4px',
    display: 'none',
};

const headerStyle = {
    fontWeight: '300',
    fontSize: '20px',
    margin: '10px 0 0 0',
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    maxHeight: '120px', // Limit the height of the header area
    overflow: 'auto',  // Enable scrolling if text exceeds maxHeight
    padding: '0 5px',  // Add some padding to prevent text from touching the edges
};
const headerStyleNoImg = {
    fontWeight: '300',
    fontSize: '20px',
    margin: '10px 0 0 0',
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    maxHeight: '90%', // Limit the height of the header area
    overflow: 'auto',  // Enable scrolling if text exceeds maxHeight
    padding: '0 5px',  // Add some padding to prevent text from touching the edges
};


const confirmationOverlayStyle = {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const confirmationBoxStyle = {
    backgroundColor:'#111111',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    border: '1px solid white',
};

const confirmButtonStyle = {
    color: '#d43255',
    border: 'none',
    padding: '10px 20px',
    marginRight: '10px',
    cursor: 'pointer',
    borderRadius: '4px',
};

const cancelButtonStyle = {
    color: '#d43255',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '4px',
};
    

    



    

export default SavedPostsList;
