import React, { useEffect, useState, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { Navigate, useNavigate } from "react-router";

import idea from "../../images/idea.png";
import question from "../../images/question.png";
import chats from "../../images/chats.png";
import myposts from "../../images/myposts.png";
import questionUser from "../../images/questionUser.png";
import saved from "../../images/saved.png";
import savedQuestions from "../../images/savedQuestions.png";
import popular from "../../images/popular.png";





const MobileSide = ({ isMenuVisible, setIsMenuVisible }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [username, setUsername] = useState('');

    const [tags, setTags] = useState([]); // Stan do przechowywania tagów
    const [loading, setLoading] = useState(true); // Stan ładowania
    const [isListVisible, setIsListVisible] = useState(false); // Stan widoczności listy

    const buttonRef = useRef(null); // Referencja do przycisku
    const listRef = useRef(null); // Referencja do listy

    const menuRef = useRef(null);
    const navigate = useNavigate();
  
    // Reset UI to initial state
    const resetUI = () => {
      setIsAdmin(false);
      setIsLoggedIn(false);
      setUsername('');
    };
  
    // Fetch user authentication and permissions
    useEffect(() => {
      const fetchUserState = async () => {
        resetUI();
  
        onAuthStateChanged(auth, async (user) => {
          if (user && user.emailVerified) {
            setIsLoggedIn(true);
            try {
              const usersCollection = collection(db, "users");
              const q = query(usersCollection, where("email", "==", user.email));
              const querySnapshot = await getDocs(q);
  
              querySnapshot.forEach((doc) => {
                const userData = doc.data();
                setIsAdmin(userData.accessLevel === 2);
                setUsername(userData.name);
              });
            } catch (error) {
              console.error("Error fetching user access level:", error);
            }
          } else {
            resetUI();
          }
        });
      };
  
      fetchUserState();
    }, []);

  // Obsługa kliknięcia poza menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        window.innerWidth <= 1164
      ) {
        setIsMenuVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsMenuVisible]);

  // Utrzymanie menu widocznego na PC
  useEffect(() => {
    const handleInitialMenuVisibility = () => {
      if (window.innerWidth > 1164) {
        setIsMenuVisible(true); // Zawsze widoczne na PC
      } else {
        setIsMenuVisible(false); // Ukryte na urządzeniach mobilnych
      }
    };
  
    handleInitialMenuVisibility(); // Ustaw widoczność podczas ładowania strony
  
    // Dodaj listener na zmianę rozmiaru okna
    const handleResize = () => {
      if (window.innerWidth > 1164) {
        setIsMenuVisible(true);
      } else {
        setIsMenuVisible(false);
      }
    };
  
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsMenuVisible]);



  useEffect(() => {
    const loadTags = async () => {
      try {
        // Option 1: Fetch tags from a document with an array field
        const popularTagsDocRef = doc(db, "popularTags", "summary");
        const snapshot = await getDoc(popularTagsDocRef);

        let tagsArray = [];
        if (snapshot.exists()) {
          tagsArray = snapshot.data().topTags.map(item => item.tag); // Extract 'tag' field from each object
        }

        setTags(tagsArray); // Aktualizujemy stan
      } catch (error) {
        console.error("Error loading tags:", error);
      } finally {
        setLoading(false);
      }
    };

    loadTags();
  }, []); // Ładowanie danych tylko raz, po załadowaniu komponentu

  
  const showList = () => setIsListVisible(true);

  // Funkcja do ukrycia listy
  const hideList = () => setIsListVisible(false);

  return (
    isMenuVisible && (
      <aside className="sidem" id="mobileside" ref={menuRef}>
        <div className="sidemdiv">
          {/* Admin Section */}
          {isAdmin && (
            <div id="adminsection" style={{ width: "100%" }}>
              <button
                style={{ userSelect: "none" }}
                className="pm"
                onClick={()=>{navigate('/reports')}}
              >
                Reported posts
              </button>
              <button
                style={{ userSelect: "none" }}
                className="pm"
                onClick={()=>{navigate('/reportsQuestions')}}
              >
                Reported questions
              </button>
              <hr style={{ width: "100%" }} />
            </div>
          )}

          {/* General Links */}
          <button style={{ userSelect: "none" }} onClick={()=>{navigate('/')}} className="pm">
            <img className="pmg" src={idea} alt="Idea Icon" />
            Ideas
          </button>
          <button style={{ userSelect: "none" }} onClick={()=>{navigate('/questions')}}className="pm">
            <img className="pmg" src={question} alt="Question Icon" />
            Questions
          </button>

          {/* Conditional Links for Logged-In Users */}
          {isLoggedIn && (
            <>
              <hr id="chatsline" style={{ width: "100%" }} />
              <button style={{ userSelect: "none" }} className="pm" onClick={()=>{navigate('/chatlist')}}>
                <img className="pmg" src={chats} alt="Chats Icon" />
                Chats
              </button>
              <hr id="forme" style={{ width: "100%" }} />
              <button style={{ userSelect: "none" }} className="pm" onClick={()=>{navigate(`/user/${username}`)}}>
                <img className="pmg" src={myposts} alt="My Posts Icon" />
                My ideas
              </button>
              <button style={{ userSelect: "none" }} className="pm" onClick={()=>{navigate(`/userQuestions/${username}`)}}>
                <img
                  className="pmg"
                  src={questionUser}
                  alt="My Questions Icon"
                />
                My questions
              </button>
              <button
                style={{ userSelect: "none" }}
                className="pm"
                onClick={()=>{navigate('/saved-posts')}}
              >
                <img className="pmg" src={saved} alt="Saved Posts Icon" />
                Saved ideas
              </button>
              <button
                style={{ userSelect: "none" }}
                className="pm"
                onClick={()=>{navigate('/saved-questions')}}
              >
                <img
                  className="pmg"
                  src={savedQuestions}
                  alt="Saved Questions Icon"
                />
                Saved questions
              </button>
            </>
          )}

          {/* Categories */}
          <hr style={{ width: "100%" }} />
          <button
        ref={buttonRef}
        style={{ userSelect: "none" }}
        className="pm"
        id="popularm"
        onMouseEnter={showList} // Pokazuje listę po najechaniu na przycisk
        onMouseLeave={hideList} // Ukrywa listę po opuszczeniu przycisku
      >
        <img className="pmg" src={popular} alt="Popular Categories Icon" />
        Popular categories
      </button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul
          ref={listRef}
          className={`ulm ${isListVisible ? "visible" : ""}`}
          id="popdownm"
          onMouseEnter={showList} // Pokazuje listę, gdy kursor jest nad listą
          onMouseLeave={hideList} // Ukrywa listę, gdy kursor opuści listę
        >
          {tags.map((tag, index) => (
            <li
              key={index}
              className="lim"
              data-tag={tag}
              onClick={() => {
                navigate(`/?tag=${encodeURIComponent(tag)}`);
              }}
            >
              {tag}
            </li>
          ))}
        </ul>
      )}
        </div>
      </aside>
    )
  );
};

export default MobileSide;
