import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PostList from './components/PostList';
import PostDetail from './components/PostDetail';
import PostListForUser from './components/PostListForUser';
import QuestionsList from './components/QuestionsList';
import QuestionDetail from './components/QuestionDetail';
import LegalPage from './components/LegalPage';
import SavedPostsList from './components/SavedPostsList';
import SavedQuestionsList from './components/SavedQuestionsList'
import QuestionList from './components/QuestionListForUser';
import ReportedPostsList from './components/ReportedPostsList';
import ReportedQuestionsList from './components/ReportedQuestionsList'
import ReportPost from './components/ReportPost';
import ReportQuestion from './components/ReportQuestion';
import Tutorial from './components/HowToUse';

import ChatList from './components/ChatList';
import Chat from './components/PrivateChat';
import { AuthProvider } from './components/AuthContext';

import Navbar from './components/NavBar';
import SignUpDiv from './components/SignUpDiv';
import SignInDiv from './components/SignInDiv';
import ChoosePostType from './components/ChoosePostType';
import AddPostForm from './components/AddPost';
import AddQuestionForm from './components/AddQuestion';
import ProfileSettings from './components/ProfileSettings';
import MobileSide from './components/Sidebar';
import ResetPassword from './components/ResetPassword';
const App = () => {
    const [isSignUpVisible, setIsSignUpVisible] = useState(false);
    const [isSignInVisible, setIsSignInVisible] = useState(false);
    const [ChooseFormVisible, setChooseFormVisible] = useState(false);
    const [postType, setPostType] = useState('');
    const [profileSettingsVisible, setProfileSettingsVisible] = useState(false);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isResetVisible, setIsResetVisible] = useState(false);


    return (
        <AuthProvider>
        <Router>
            <Navbar setSignUpVisible={setIsSignUpVisible} setSignInVisible={setIsSignInVisible} setChooseFormVisible={setChooseFormVisible} setProfileSettingsVisible={setProfileSettingsVisible} setIsMenuVisible={setIsMenuVisible} />
           <MobileSide isMenuVisible={isMenuVisible} setIsMenuVisible={setIsMenuVisible}/>
            <SignUpDiv visible={isSignUpVisible} setVisible={setIsSignUpVisible} setOtherVisible={setIsSignInVisible} />
            <SignInDiv visible={isSignInVisible} setVisible={setIsSignInVisible} setOtherVisible={setIsSignUpVisible} setIsResetVisible={setIsResetVisible} />
            <ChoosePostType visible={ChooseFormVisible} setVisible={setChooseFormVisible} setPostType={setPostType} />
            <AddPostForm visible={postType === 'idea'} setVisible={setPostType} />
            <AddQuestionForm visible={postType === 'question'} setVisible={setPostType} />
            <ProfileSettings visible={profileSettingsVisible} setVisible={setProfileSettingsVisible} />
            <ResetPassword visible={isResetVisible} setVisible={setIsResetVisible} setLoginVisible={setIsSignInVisible} />
            <div className='roote'>
            <Routes>
                <Route path="/" element={<PostList />} />
                <Route path="/questions" element={<QuestionsList />} />
                <Route path="/questions/:slug/:id" element={<QuestionDetail />} />
                <Route path="/post/:slug/:id" element={<PostDetail />} />
                <Route path="/user/:username" element={<PostListForUser/>} />
                <Route path="/userQuestions/:username" element={<QuestionList/>} />
                <Route path="/legal" element={<LegalPage />} />
                <Route path="/saved-posts" element={<SavedPostsList />} />
                <Route path="/saved-questions" element={<SavedQuestionsList />} />
                <Route path='/reports' element={<ReportedPostsList/>}/>
                <Route path='/reportsQuestions' element={<ReportedQuestionsList/>}/>
                <Route path="/report/:postId" element={<ReportPost />} />
                <Route path="/reportQuestion/:postId" element={<ReportQuestion />} />
              {/*  <Route path='/howToUse' element={<Tutorial/>}/>*/}

                <Route path='/chatList' element={<ChatList/>}/>
                <Route path="/chat/:chatId" element={<Chat />} />
            </Routes>
            </div>
        </Router>
        </AuthProvider>
    );
};

export default App;
