import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase'; // Ensure Firestore and Auth are correctly initialized
import { doc, getDoc } from 'firebase/firestore';
import './PostAnalytics.css';

const QuestionAnalytics = ({ postId }) => {
    const [metrics, setMetrics] = useState(null);
    const [isAuthor, setIsAuthor] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Reference the post document in Firestore
                const postDocRef = doc(db, 'questions', postId);
                const postSnapshot = await getDoc(postDocRef);

                if (postSnapshot.exists()) {
                    const postData = postSnapshot.data();

                    // Update metrics state safely
                    setMetrics({
                        commentsCount: postData.commentsCount || 0,
                        ratingsCount: postData.ratingsCount || 0,
                        saveCount: postData.saveCount || 0,
                        shareCount: postData.shareCount || 0,
                        views: postData.views || 0,
                        tags: postData.foundByTags || {},
                    });

                    // Ensure the current user matches the post creator
                    const currentUser = auth.currentUser;
                    if (currentUser && currentUser.email === postData.email) {
                        setIsAuthor(true);
                    }
                } else {
                    console.error("Post not found!");
                    // Handle missing post appropriately
                }
            } catch (error) {
                console.error("Error fetching post data:", error);
            }
        };

        fetchData();
    }, [postId]);

    if (!isAuthor || !metrics) return null;

    return (
        <div className='maindiv'>
            <header className='header'>Post Analytics</header>
            <div className='row'>
                <div>
                    <p className='pan'>Comments: {metrics.commentsCount}</p>
                    <p className='pan'>Ratings: {metrics.ratingsCount}</p>
                    <p className='pan'>Saves: {metrics.saveCount}</p>
                    <p className='pan'>Shares: {metrics.shareCount}</p>
                    <p className='pan'>Views: {metrics.views}</p>
                </div>
                <div>
                    {/* Display tags and their counts */}
                    {/*<div className='tags'>
                        <p className='pan'>Post was found by tags:</p>
                        <div className='tagsScroll'>
                            {Object.entries(metrics.tags).map(([tag, data]) => (
                                <p key={tag} className='tag'>
                                    {tag}: {data.count || 0}
                                </p>
                            ))}
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    );
};

export default QuestionAnalytics;
