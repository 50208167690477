import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, onAuthStateChanged } from '../firebase';
import { db } from '../firebase.js'; // Your Firebase database instance
import './RatingComponent.css';

const RatingComponent = ({ postId }) => {
    const [user, setUser] = useState(null);
    const [userRating, setUserRating] = useState(null);
    const [averageRating, setAverageRating] = useState(0);
    const [ratingsCount, setRatingsCount] = useState(0);
    const [showThankYou, setShowThankYou] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);
            if (currentUser) {
                fetchUserRating();
            } else {
                setUserRating(null); // Clear user rating when logged out
            }
        });

        // Clean up the listener when the component unmounts
        return () => unsubscribe();
    }, [postId]);

    useEffect(() => {
        fetchAverageRating();
    }, [postId]);

    const fetchAverageRating = async () => {
        const postRef = doc(db, 'posts', postId);
    
        try {
            const postSnapshot = await getDoc(postRef);
            if (postSnapshot.exists()) {
                const data = postSnapshot.data();
                setAverageRating(data.averageRating || 0);
                setRatingsCount(data.ratingsCount || 0);
            } else {
                console.log('Post does not exist');
            }
        } catch (error) {
            console.error('Error fetching average rating:', error);
        }
    };
    

    const fetchUserRating = async () => {
        if (!user) return;
    
        const ratingRef = doc(db, 'posts', postId, 'ratings', user.uid);
    
        try {
            const userRatingSnapshot = await getDoc(ratingRef);
            if (userRatingSnapshot.exists()) {
                setUserRating(userRatingSnapshot.data().value);
            } else {
                setUserRating(null); // No rating exists
            }
        } catch (error) {
            console.error('Error fetching user rating:', error);
        }
    };
    

    const handleRatingSubmit = async (newRating) => {
        if (!user) {
            alert('Please sign in to rate.');
            return;
        }
    
        const userId = user.uid;
        const ratingRef = doc(db, 'posts', postId, 'ratings', userId);
        const postRef = doc(db, 'posts', postId);
    
        try {
            const postSnapshot = await getDoc(postRef);
    
            if (!postSnapshot.exists()) {
                console.error('Post does not exist.');
                return;
            }
    
            const postData = postSnapshot.data();
            let totalRating = (postData.averageRating || 0) * (postData.ratingsCount || 0); // Calculate total rating sum
            let ratingsCount = postData.ratingsCount || 0;
    
            const userRatingSnapshot = await getDoc(ratingRef);
            let oldRating = 0;
    
            if (userRatingSnapshot.exists()) {
                // User has rated before; retrieve the old rating
                oldRating = userRatingSnapshot.data().value;
            } else {
                // New rating; increment the ratings count
                ratingsCount += 1;
            }
    
            // Update totalRating by removing oldRating and adding newRating
            totalRating = totalRating - oldRating + newRating;
    
            // Calculate the new average rating
            const averageRating = ratingsCount > 0 ? totalRating / ratingsCount : 0;
    
            // Update the user's rating in Firestore
            await setDoc(ratingRef, { value: newRating });
    
            // Update the post's ratings data
            await updateDoc(postRef, {
                averageRating: parseFloat(averageRating.toFixed(1)), // Keep precision to 1 decimal place
                ratingsCount,
            });
    
            // Update local state
            setUserRating(newRating);
            setAverageRating(parseFloat(averageRating.toFixed(1)));
            setRatingsCount(ratingsCount);
    
            // Show thank-you message
            setShowThankYou(true);
            setTimeout(() => {
                setShowThankYou(false);
            }, 2000);
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };
    
    
    
    

    return (
        <div className="rating-component">
            {showThankYou ? (
                <div className="thank-you-message">
                    <p>Thank you for your rating!</p>
                </div>
            ) : (
                <>
                    <p>Average Rating: {averageRating} (Ratings: {ratingsCount})</p>
                    <div>
                        <p>Your Rating:</p>
                        {user ? (
                            <div className="btndiv">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                                    <button
                                        key={value}
                                        onClick={() => handleRatingSubmit(value)}
                                        className="btn"
                                    >
                                        {value}
                                    </button>
                                ))}
                            </div>
                        ) : (
                            <p>Please sign in to submit your rating.</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default RatingComponent;
