import { initializeApp } from "firebase/app";
import { getAuth,reauthenticateWithCredential ,createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail, EmailAuthProvider, sendEmailVerification,setPersistence, browserSessionPersistence} from "firebase/auth";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc, setDoc, addDoc, serverTimestamp, deleteDoc, getDoc } from "firebase/firestore";
// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCmBsHv0DjUvH3id5bvUCMGEDOmwD5VFxk",
    authDomain: "ideasights-5867f.firebaseapp.com",
    projectId: "ideasights-5867f",
    storageBucket: "ideasights-5867f.firebasestorage.app",
    messagingSenderId: "55022295868",
    appId: "1:55022295868:web:0ee2ee3e77b03671bedc39",
    measurementId: "G-8F9EGW1K4V"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Pass the app instance here
const storage = getStorage(app);


// Now you can use Firestore, Auth, Storage, and other services





// Function to set a value in localStorage
function setLocalStorageItem(key, value) {
    if (typeof localStorage !== 'undefined') {
        localStorage.setItem(key, value);
    }
}

// Function to get a value from localStorage
function getLocalStorageItem(key) {
    if (typeof localStorage !== 'undefined') {
        return localStorage.getItem(key);
    }
    return null;
}

let analytics = null;

// Check if the user has already made a choice regarding cookies
document.addEventListener('DOMContentLoaded', () => {
    const cookieBanner = document.getElementById('cookieAccept');
    const userConsent = getLocalStorageItem('userConsent');
    
    // Initialize analytics if consent is given
    if (userConsent === 'accepted') {
        // Initialize Firebase Analytics
        //analytics = getAnalytics(app);
        //console.log('Firebase Analytics initialized:', analytics); // Ensure it's initialized properly
        
        // initializeAnalytics(); // Call the function to log the page view
    }
    
    // Show cookie banner if no consent decision has been made
    if (!userConsent) {
        setTimeout(() => {
            cookieBanner.classList.add('visible');
        }, 2000); // Show after 2 seconds
    }
});


// Event listener for "Accept" button
document.getElementById('acceptCookies').addEventListener('click', () => {
    setLocalStorageItem('userConsent', 'accepted'); // Store consent for 1 year
    hideCookieBanner();
   // analytics = getAnalytics(app); // Reinitialize analytics after acceptance
   // console.log('User accepted cookies, Firebase Analytics reinitialized:', analytics);
    //initializeAnalytics(); // Initialize analytics and log page view
});

// Event listener for "Refuse" button
document.getElementById('refuseCookies').addEventListener('click', () => {
    setLocalStorageItem('userConsent', 'refused'); // Store refusal for 1 year
    hideCookieBanner();
});
/*
// Function to initialize analytics after consent
function initializeAnalytics() {
    const userConsent = getCookie('userConsent');
    if (userConsent === 'accepted') {
        if (window.gtag) { // Ensure `gtag` is available
            window.analytics = getAnalytics(app);
            logEvent(window.analytics, 'page_view', {
                page_title: document.title,
                page_path: window.location.pathname,
            });
            console.log('Firebase Analytics Initialized');
        } else {
            console.error("Google Analytics script not loaded yet");
        }
    }
}*/

// Function to hide the cookie banner with animation
function hideCookieBanner() {
    const cookieBanner = document.getElementById('cookieAccept');
    cookieBanner.classList.remove('visible');
    // Optional: fully remove the banner from the DOM after the animation is done
    setTimeout(() => {
        cookieBanner.style.display = 'none';
    }, 500); // Match this to the CSS transition duration
}




async function logincheck() {
    const resetUI = () => {
        // Hide all elements by default
        const elementsToHide = [
            "adminsection", "forme", "chatsline", "chats",
            "mypostsm", "myquestionsm", "savedpostsm",
            "savedquestionsm", "editaccountbtn", "postbtn"
        ];
        elementsToHide.forEach((id) => {
            const element = document.getElementById(id);
            if (element) element.style.display = "none";
        });

        // Show login/signup by default
        const signup = document.getElementById("signup");
        const signin = document.getElementById("signin");
        if (signup) signup.style.display = "block";
        if (signin) signin.style.display = "block";

        // Handle mobile-specific elements
        const moreMobile = document.getElementById("moremobile");
        if (moreMobile) moreMobile.style.display = "block";
    };

    const updateUIForLoggedInUser = (isAdmin) => {
        // Show elements for logged-in users
        const elementsToShow = [
            "forme", "chatsline", "chats",
            "mypostsm", "myquestionsm", "savedpostsm",
            "savedquestionsm", "editaccountbtn", "postbtn"
        ];
        elementsToShow.forEach((id) => {
            const element = document.getElementById(id);
            if (element) element.style.display = "flex";
        });

        // Hide signup/signin
        const signup = document.getElementById("signup");
        const signin = document.getElementById("signin");
        if (signup) signup.style.display = "none";
        if (signin) signin.style.display = "none";

        // Show admin section if the user is an admin
        if (isAdmin) {
            const adminSection = document.getElementById("adminsection");
            if (adminSection) adminSection.style.display = "block";

            const gotoReports = document.getElementById("toreportedposts");
            const gotoReportsQuestions = document.getElementById("toreportedquestions");

            if (gotoReports) {
                gotoReports.addEventListener("click", () => {
                    window.location = "/reports";
                });
            }

            if (gotoReportsQuestions) {
                gotoReportsQuestions.addEventListener("click", () => {
                    window.location = "/reportsQuestions";
                });
            }
        }

        // Handle mobile-specific elements
        if (window.innerWidth > 1164) {
            const moreMobile = document.getElementById("moremobile");
            if (moreMobile) moreMobile.style.display = "none";
        }
    };

    resetUI(); // Ensure a clean UI state at the start

    await onAuthStateChanged(auth, async (user) => {
        if (user && user.emailVerified) {
            // User is logged in
            let accessLevel = null;

            try {
                const usersCollection = collection(db, "users");
                const q = query(usersCollection, where("email", "==", user.email));
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    accessLevel = userData.accessLevel;
                });

                updateUIForLoggedInUser(accessLevel === 2); // Update UI with admin check
            } catch (error) {
                console.error("Error fetching user access level:", error);
            }
        } else {
            // No user is logged in; reset the UI
            resetUI();
        }
    });
}

document.addEventListener("DOMContentLoaded", () => {
    logincheck();
});


/*
const signup = document.getElementById('signup');
const signupdiv = document.getElementById('signupdiv');
signup.addEventListener('click', () => {
    signupdiv.style.display = 'flex';
});

document.addEventListener('mousedown', (event) => {
    if (!signupdiv.contains(event.target)) {
        signupdiv.style.display = 'none';
    }
});

const signupform = document.getElementById('signupform');
signupform.addEventListener('submit', function (event) {
    event.preventDefault();

    const username = document.getElementById('usernameinput').value;
    const email = document.getElementById('emailinput').value;
    const password = document.getElementById('passwordinput').value;
    const passwordrepeat = document.getElementById('passwordrepeatinput').value;

    if (password === passwordrepeat) {
        checkIfEmailOrUsernameExists(email, username)
            .then((resp) => {
                if (resp === 'nottaken') {
                    createUserWithEmailAndPassword(auth, email, password)
                        .then((userCredential) => {
                            const user = userCredential.user;
                            console.log("User signed up:", user.uid, username, email);

                            postusername(user.uid, username, email);
                            sendEmailVerification(user);
                          
                            
                                alert('A verification email has been sent. Please verify your email before logging in.');
                                signupdiv.style.display = 'none';

                                // Run logincheck after ensuring the user is signed out
                               // logincheck();
                        })

                        .catch((error) => {
                            console.error("Error creating user:", error);
                        });
                } else {
                    console.log("Email or username taken:", resp);
                }
            })
            .catch((error) => {
                console.error("Error checking username/email:", error);
            });
    } else {
        alert('Passwords do not match');
    }
});


// Function to check if email or username already exists
function checkIfEmailOrUsernameExists(email, username) {
    const usersCollection = collection(db, "users");

    const emailQuery = query(usersCollection, where("email", "==", email));
    const usernameQuery = query(usersCollection, where("name", "==", username));

    return Promise.all([
        getDocs(emailQuery),
        getDocs(usernameQuery),
    ]).then(([emailSnapshot, usernameSnapshot]) => {
        const emailExists = !emailSnapshot.empty; // Firestore returns an empty snapshot if no results
        const usernameExists = !usernameSnapshot.empty;

        if (emailExists) {
            return "email";
        } else if (usernameExists) {
            return "username";
        } else {
            return "nottaken";
        }
    }).catch(error => {
        console.error("Error checking email or username:", error);
        throw error; // Ensure the caller can handle errors
    });
}

async function postusername(uid, name, email) {
    console.log("postusername called with:", uid, name, email);

    const userRef = doc(db, "users", uid);  // Reference to the user's document in Firestore

    try {
        await setDoc(userRef, {
            name: name,
            email: email,
            accessLevel: 0, // Default access level for new users
        });
        console.log("User data saved successfully!");
    } catch (error) {
        console.error("Error saving user data:", error);
    }
}




const signin = document.getElementById('signin');
const signinform = document.getElementById('signinform');

signin.addEventListener('click', () => {
    signindiv.style.display = 'flex';
});





signinform.addEventListener('submit', function (event) {
    event.preventDefault();
    const email = document.getElementById('emailinputl').value;
    const password = document.getElementById('passwordinputl').value;

    setPersistence(auth, browserSessionPersistence)
        .then(() => {
            return signInWithEmailAndPassword(auth, email, password);
        })
        .then((userCredential) => {
            const user = userCredential.user;
            if (user.emailVerified) {
                searchname();
                window.location.reload();
               // logincheck();
                //signindiv.style.display = 'none';
                
            } else {
                alert('Please verify your email before logging in.');
                auth.signOut();
            }
        })
        .catch((error) => {
            alert('Wrong data');
        });
});



async function searchname() {
    const user = auth.currentUser;  
    if (user) {
        const emailToSearch = user.email;
        const usersCollection = collection(db, "users");
        const emailQuery = query(usersCollection, where("email", "==", emailToSearch));

        try {
            const querySnapshot = await getDocs(emailQuery);

            querySnapshot.forEach((doc) => {
                const userData = doc.data(); // Get document data
                console.log("User logged in:", userData.name);
            });

            if (querySnapshot.empty) {
                console.log("No matching user found.");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    } else {
        console.log("User is signed out.");
    }
}

/*
const gotosignup = document.getElementById('gotosignup');
const gotosignin = document.getElementById('gotosignin');

let signindiv = document.getElementById('signindiv');


document.addEventListener('mousedown', (event) => {
    if (!signindiv.contains(event.target)) {
        signindiv.style.display = 'none';
    }
});

gotosignup.addEventListener('click', () => {
    signupdiv.style.display = 'flex';
    signindiv.style.display = 'none';
});

gotosignin.addEventListener('click', () => {
    signindiv.style.display = 'flex';
    signupdiv.style.display = 'none';
});

const postbtn = document.getElementById('postbtn');

let choosepost = document.getElementById('choosepost');
postbtn.addEventListener('click',()=>{
    choosepost.style.display = 'flex';
})
document.addEventListener('mousedown',(e)=>{
    if (!choosepost.contains(e.target)) {
        choosepost.style.display = 'none';
    }
})


const addpostdiv = document.getElementById('addpostdiv');
/*
postbtn.addEventListener('click', () => {
    addpostdiv.style.display = 'flex';
    let s1 = document.getElementById("s1");
let s2 = document.getElementById("s2");
let s3 = document.getElementById("s3");
let s4 = document.getElementById("s4");
    s1.style.display = 'flex';
    s2.style.display = 'none';
    s3.style.display = 'none';
    s4.style.display = 'none';
});*/
/*
document.addEventListener('mousedown', (event) => {
    if (!addpostdiv.contains(event.target)) {
        addpostdiv.style.display = 'none';
    }
});


let previmg = document.getElementById('previmg');
let imginput = document.getElementById('imginput');


let logoutbtn = document.getElementById('logoutbtn');
logoutbtn.addEventListener('click', () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
        window.location = '/';
        //logincheck();
    }).catch((error) => {
        // An error happened.
    });
});

document.addEventListener('DOMContentLoaded', () => {
    const tagList = document.getElementById('tagList');
    const tagz = document.getElementById('tagz');

    tagList.addEventListener('click', (event) => {
        // Sprawdzenie, czy kliknięty element jest tagiem
        if (event.target && event.target.classList.contains('tag-item')) {
            const selectedTag = event.target.textContent;
            const currentText = tagz.value;

            // Sprawdzenie, czy tag nie jest już obecny w polu tekstowym
            if (!currentText.includes(selectedTag)) {
                // Dodanie tagu do pola tekstowego
                tagz.value = currentText ? `${currentText}, ${selectedTag}` : selectedTag;
            }
        }
    });

    const tagListQ = document.getElementById('tagListQ');
    const tagzQ = document.getElementById('tagzQ');

    tagListQ.addEventListener('click', (event) => {
        // Sprawdzenie, czy kliknięty element jest tagiem
        if (event.target && event.target.classList.contains('tag-item')) {
            const selectedTag = event.target.textContent;
            const currentText = tagzQ.value;

            // Sprawdzenie, czy tag nie jest już obecny w polu tekstowym
            if (!currentText.includes(selectedTag)) {
                // Dodanie tagu do pola tekstowego
                tagzQ.value = currentText ? `${currentText}, ${selectedTag}` : selectedTag;
            }
        }
    });
});

const addPostForm = document.getElementById('addpostform');
const imgInput = document.getElementById('imginput');
const imgContainer = document.getElementById('imgcontainer');
let filearray = []; // Stores the file objects for upload

// Display selected images

let s1 = document.getElementById("s1");
let s2 = document.getElementById("s2");
let s3 = document.getElementById("s3");
let s4 = document.getElementById("s4");
let tos2 = document.getElementById('tos2');
let tos3 = document.getElementById('tos3');
let tos4 = document.getElementById('tos4');
let postHeader = document.getElementById('postheader');

tos2.addEventListener('click', (e) => {
e.preventDefault(); // Zapobiega domyślnemu zachowaniu przycisku
if (postHeader.value !== '' && postHeader.value !== null) {
s1.style.display = 'none';
s2.style.display = 'flex';
} else {
alert("Please fill in the header.");
}
});

tos3.addEventListener('click', (e) => {
e.preventDefault();
s2.style.display = 'none';
s3.style.display = 'flex';
});

tos4.addEventListener('click', (e) => {
e.preventDefault();
s3.style.display = 'none';
s4.style.display = 'flex';
});


imgInput.addEventListener('change', function() {
const files = Array.from(this.files);

files.forEach(file => {
const reader = new FileReader();
reader.onload = function(e) {
    let cont = document.createElement('div');
    const img = document.createElement('img');
    img.src = e.target.result;
    img.alt = 'Uploaded Image';
    cont.classList.add('previmg');
    cont.appendChild(img);
    imgContainer.appendChild(cont);

    const removeBtn = document.createElement('button');
    removeBtn.textContent = 'X';
    removeBtn.classList.add('removeBtn');

    // Obsługa usuwania obrazu po kliknięciu w "X"
    removeBtn.addEventListener('click', () => {
        const index = filearray.indexOf(file);
        if (index > -1) {
            filearray.splice(index, 1); // Usuń z filearray
        }
        cont.remove(); // Usuń z podglądu

        // Zaktualizuj scroll, jeśli mniej niż 2 obrazy
        if (filearray.length < 2) {
            imgContainer.style.overflowX = 'unset';
        }
    });

    cont.appendChild(removeBtn);

    imgContainer.appendChild(cont);
    

    // Add the file object (instead of base64) to filearray for later upload
    filearray.push(file); 
};
reader.readAsDataURL(file); // Convert file to a data URL for preview
});
if (filearray.length >=2 ) {
imgContainer.style.overflowX = 'scroll';
}

});
document.addEventListener('DOMContentLoaded', function () {
const imgInput = document.getElementById('imginput');
const dropZone = document.getElementById('dropZone');
const imgContainer = document.getElementById('imgcontainer');

// Obsługa przeciągania
dropZone.addEventListener('dragenter', (e) => {
e.preventDefault();
dropZone.classList.add('dragover');
});

dropZone.addEventListener('dragover', (e) => {
e.preventDefault();
});

dropZone.addEventListener('dragleave', () => {
dropZone.classList.remove('dragover');
});

dropZone.addEventListener('drop', (e) => {
e.preventDefault();
dropZone.classList.remove('dragover');
const files = e.dataTransfer.files;
handleFiles(files);
});



// Funkcja przetwarzająca pliki
function handleFiles(files) {
[...files].forEach(file => {
    const reader = new FileReader();
    reader.onload = function (e) {
       let cont = document.createElement('div');
    const img = document.createElement('img');
    img.src = e.target.result;
    img.alt = 'Uploaded Image';
    cont.classList.add('previmg');
    cont.appendChild(img);


    const removeBtn = document.createElement('button');
    removeBtn.textContent = 'X';
    removeBtn.classList.add('removeBtn');

    // Obsługa usuwania obrazu po kliknięciu w "X"
    removeBtn.addEventListener('click', () => {
        const index = filearray.indexOf(file);
        if (index > -1) {
            filearray.splice(index, 1); // Usuń z filearray
        }
        cont.remove(); // Usuń z podglądu

        // Zaktualizuj scroll, jeśli mniej niż 2 obrazy
        if (filearray.length < 2) {
            imgContainer.style.overflowX = 'unset';
        }
    });

    cont.appendChild(removeBtn);

    imgContainer.appendChild(cont);
    
    
        filearray.push(file);
    };
    reader.readAsDataURL(file);
    if (filearray.length >=2 ) {
imgContainer.style.overflowX = 'scroll';
}
});
}
});

// Function to upload images to Firebase Storage and return their download URLs
async function uploadImages() {
const uploadPromises = filearray.map(async (file, index) => {
const storageReference = storageRef(storage, `posts/${Date.now()}_${file.name}`);
const snapshot = await uploadBytes(storageReference, file);
const downloadURL = await getDownloadURL(snapshot.ref);
return downloadURL; // Returns the image URL
});
return Promise.all(uploadPromises); // Waits for all uploads to finish and returns an array of URLs
}


// Submit the form
addPostForm.addEventListener('submit', async function (e) {
    e.preventDefault();

    if (auth) {
        // Collect form data
        const postHeader = document.getElementById('postheader').value;
        const tags = document.getElementById('tagz').value.split(',').map(tag => tag.trim());
        const postText = document.getElementById('posttext').value;
        const allowComments = document.getElementById('allowComments').checked;
        const allowRating = document.getElementById('allowRating').checked;
        const allowContact = document.getElementById('allowContact').checked;

        try {
            const user = auth.currentUser;  
                if (user) {
                    const userEmail = user.email;

                    // Upload images to Firebase Storage and get their URLs
                    const imageUrls = await uploadImages();

                    // Create a post object with optional images
                    const postData = {
                        header: postHeader,
                        tags: tags,
                        email: userEmail,
                        description: postText,
                        allowComments: allowComments,
                        allowRating: allowRating,
                        allowContact: allowContact,
                        createdAt: serverTimestamp(), // Use Firestore's server timestamp
                    };

                    // If images were uploaded, add them to the post data
                    if (imageUrls.length > 0) {
                        postData.images = imageUrls;
                    }

                    // Save post details to Firestore
                    const postsCollection = collection(db, 'posts');
                    await addDoc(postsCollection, postData);

                    alert('Post successfully added!');
                    addPostForm.reset(); // Reset the form after successful submission
                    imgContainer.innerHTML = ''; // Clear image previews
                    filearray = []; // Clear the file array after submission
                    imgContainer.style.overflowX = 'unset';
                    s2.style.display = 'none';
                    s3.style.display = 'none';
                    s4.style.display = 'none';
                    addpostdiv.style.display = 'none';
                } else {
                    alert('You need to sign in to add a post');
                }
            ;
        } catch (error) {
            console.error('Error adding post: ', error);
        }
    } else {
        console.log('User not logged in');
    }
});




//questions part





let addQuestionDiv = document.getElementById('addQuestionDiv');
const addQuestionForm = document.getElementById('addQuestionForm');
const imgInputQ = document.getElementById('imginputQ');
const imgContainerQ = document.getElementById('imgContainerQ');
let questionFileArray = []; // Stores the file objects for upload

// Section elements
let qs1 = document.getElementById("qs1");
let qs2 = document.getElementById("qs2");
let qs3 = document.getElementById("qs3");
let qs4 = document.getElementById("qs4");
let toqs2 = document.getElementById('toqs2');
let toqs3 = document.getElementById('toqs3');
let toqs4 = document.getElementById('toqs4');
let questionText = document.getElementById('questionText');

document.addEventListener('mousedown', (event) => {
    if (!addQuestionDiv.contains(event.target)) {
        addQuestionDiv.style.display = 'none';
    }
});


let toQuestionForm = document.getElementById('toQuestionForm');

toQuestionForm.addEventListener('click', () => {
choosepost.style.display = 'none';
addQuestionDiv.style.display = 'flex';
addpostdiv.style.display = 'none';
    let qs1 = document.getElementById("qs1");
let qs2 = document.getElementById("qs2");
let qs3 = document.getElementById("qs3");
let qs4 = document.getElementById("qs4");
    qs1.style.display = 'flex';
    qs2.style.display = 'none';
    qs3.style.display = 'none';
    qs4.style.display = 'none';
});
let toPostForm = document.getElementById('toPostForm');
toPostForm.addEventListener('click', () => {
choosepost.style.display = 'none';
addQuestionDiv.style.display = 'none';
addpostdiv.style.display = 'flex';
    let qs1 = document.getElementById("s1");
let qs2 = document.getElementById("s2");
let qs3 = document.getElementById("s3");
let qs4 = document.getElementById("s4");
    qs1.style.display = 'flex';
    qs2.style.display = 'none';
    qs3.style.display = 'none';
    qs4.style.display = 'none';
});


// Show the next section
toqs2.addEventListener('click', (e) => {
e.preventDefault();
if (questionText.value !== '') {
qs1.style.display = 'none';
qs2.style.display = 'flex';
} else {
alert("Please fill in the question.");
}
});

toqs3.addEventListener('click', (e) => {
e.preventDefault();
qs2.style.display = 'none';
qs3.style.display = 'flex';
});

toqs4.addEventListener('click', (e) => {
e.preventDefault();
qs3.style.display = 'none';
qs4.style.display = 'flex';
});


document.getElementById('backToS1').addEventListener('click', (e) => {
e.preventDefault();
document.getElementById('s2').style.display = 'none';
document.getElementById('s1').style.display = 'flex';
});

document.getElementById('backToS2').addEventListener('click', (e) => {
e.preventDefault();
document.getElementById('s3').style.display = 'none';
document.getElementById('s2').style.display = 'flex';
});

document.getElementById('backToS3').addEventListener('click', (e) => {
e.preventDefault();
document.getElementById('s4').style.display = 'none';
document.getElementById('s3').style.display = 'flex';
});


document.getElementById('backToQs1').addEventListener('click', () => {
document.getElementById('qs2').style.display = 'none';
document.getElementById('qs1').style.display = 'flex';
});

document.getElementById('backToQs2').addEventListener('click', () => {
document.getElementById('qs3').style.display = 'none';
document.getElementById('qs2').style.display = 'flex';
});

document.getElementById('backToQs3').addEventListener('click', () => {
document.getElementById('qs4').style.display = 'none';
document.getElementById('qs3').style.display = 'flex';
});



// Display selected images
imgInputQ.addEventListener('change', function() {
const files = Array.from(this.files);

files.forEach(file => {
const reader = new FileReader();
reader.onload = function(e) {
    let cont = document.createElement('div');
    const img = document.createElement('img');
    img.src = e.target.result;
    img.alt = 'Uploaded Image';
    cont.classList.add('previmg');
    cont.appendChild(img);
    imgContainerQ.appendChild(cont);

    const removeBtn = document.createElement('button');
    removeBtn.textContent = 'X';
    removeBtn.classList.add('removeBtn');

    // Handle removing the image on click
    removeBtn.addEventListener('click', () => {
        const index = questionFileArray.indexOf(file);
        if (index > -1) {
            questionFileArray.splice(index, 1); // Remove from questionFileArray
        }
        cont.remove(); // Remove from preview
    });

    cont.appendChild(removeBtn);
    imgContainerQ.appendChild(cont);
    
    // Add the file object (instead of base64) to questionFileArray for later upload
    questionFileArray.push(file); 
};
reader.readAsDataURL(file); // Convert file to a data URL for preview
});
});

// Handle dragging and dropping images
document.addEventListener('DOMContentLoaded', function () {
const dropZoneQ = document.getElementById('dropZoneQ');

dropZoneQ.addEventListener('dragenter', (e) => {
e.preventDefault();
dropZoneQ.classList.add('dragover');
});

dropZoneQ.addEventListener('dragover', (e) => {
e.preventDefault();
});

dropZoneQ.addEventListener('dragleave', () => {
dropZoneQ.classList.remove('dragover');
});

dropZoneQ.addEventListener('drop', (e) => {
e.preventDefault();
dropZoneQ.classList.remove('dragover');
const files = e.dataTransfer.files;
handleFilesQ(files);
});

// Process dropped files
function handleFilesQ(files) {
[...files].forEach(file => {
    const reader = new FileReader();
    reader.onload = function (e) {
        let cont = document.createElement('div');
        const img = document.createElement('img');
        img.src = e.target.result;
        img.alt = 'Uploaded Image';
        cont.classList.add('previmg');
        cont.appendChild(img);

        const removeBtn = document.createElement('button');
        removeBtn.textContent = 'x';
        removeBtn.classList.add('removeBtn');

        removeBtn.addEventListener('click', () => {
            const index = questionFileArray.indexOf(file);
            if (index > -1) {
                questionFileArray.splice(index, 1); // Remove from questionFileArray
            }
            cont.remove(); // Remove from preview
        });

        cont.appendChild(removeBtn);
        imgContainerQ.appendChild(cont);
        questionFileArray.push(file);
    };
    reader.readAsDataURL(file);
});
}
});

// Function to upload images to Firebase Storage and return their download URLs
async function uploadQuestionImages() {
const uploadPromises = questionFileArray.map(async (file) => {
const storageReference = storageRef(storage, `questions/${Date.now()}_${file.name}`);
const snapshot = await uploadBytes(storageReference, file);
const downloadURL = await getDownloadURL(snapshot.ref);
return downloadURL; // Returns the image URL
});
return Promise.all(uploadPromises); // Waits for all uploads to finish and returns an array of URLs
}

// Submit the question
addQuestionForm.addEventListener('submit', async function (e) {
    e.preventDefault();
    if (auth) {
        // Collect form data
        const question = document.getElementById('questionText').value;
        const tags = document.getElementById('tagzQ').value.split(',').map(tag => tag.trim());
        const additionalDetails = document.getElementById('detailsQ').value;
        const allowRating = document.getElementById('allowRatingQ').checked;
        const allowContact = document.getElementById('allowContactQ').checked;

        try {
            const user = auth.currentUser;  
                if (user) {
                    const userEmail = user.email;

                    // Upload images to Firebase Storage and get their URLs
                    const imageUrls = await uploadQuestionImages();

                    // Create a post data object
                    const postData = {
                        header: question,
                        tags: tags,
                        email: userEmail,
                        description: additionalDetails,
                        allowRating: allowRating,
                        allowContact: allowContact,
                        createdAt: serverTimestamp(), // Use Firestore's server timestamp
                    };

                    // If images were uploaded, add them to the post data
                    if (imageUrls.length > 0) {
                        postData.images = imageUrls;
                    }

                    // Create a new question document in Firestore
                    const questionsCollection = collection(db, 'questions');  // Reference to 'questions' collection
                    await addDoc(questionsCollection, postData);

                    alert('Question successfully added!');
                    addQuestionForm.reset(); // Reset the form after successful submission
                    imgContainerQ.innerHTML = ''; // Clear image previews
                    questionFileArray = []; // Clear the file array after submission
                    qs2.style.display = 'none';
                    qs3.style.display = 'none';
                    qs4.style.display = 'none';
                    addQuestionDiv.style.display = 'none';
                } else {
                    alert('You need to sign in to add a question');
                }
            ;
        } catch (error) {
            console.error('Error adding question: ', error);
        }
    } else {
        console.log('User not logged in');
    }
});







//the part is over, look higher

/* document.getElementById('searchButton').addEventListener('click', function () {
    const searchQuery = document.getElementById('search').value;
    if (searchQuery) {
        // Przekieruj do strony z tagiem jako parametrem
        window.location.href = `/?tag=${encodeURIComponent(searchQuery)}`;
    }
});

// Obsługa wyszukiwania po wciśnięciu Enter
document.getElementById('search').addEventListener('keypress', function (e) {
    if (e.key === 'Enter') {
        const searchQuery = document.getElementById('search').value;
        if (searchQuery) {
            window.location.href = `/?tag=${encodeURIComponent(searchQuery)}`;
        }
    }
});*/

/*
document.getElementById('searchButton').addEventListener('click', function () {
const searchQuery = document.getElementById('search').value;
if (searchQuery) {
const currentPath = window.location.pathname;

// Check which page the user is on and adjust the URL
if (currentPath.startsWith('/questions')) {
    // If on the /questions page, direct to /questions/?tag=
    window.history.pushState({},'',`/questions/?tag=${encodeURIComponent(searchQuery)}`);
} else {
    // Default behavior for all other pages (e.g., /)
     window.history.pushState({},'',`/?tag=${encodeURIComponent(searchQuery)}`);
}
}
});

// Also handle pressing the "Enter" key for searching
document.getElementById('search').addEventListener('keypress', function (e) {
if (e.key === 'Enter') {
const searchQuery = document.getElementById('search').value;
if (searchQuery) {
    const currentPath = window.location.pathname;
    
    // Check which page the user is on and adjust the URL
    if (currentPath.startsWith('/questions')) {
        window.location.href = `/questions/?tag=${encodeURIComponent(searchQuery)}`;
    } else {
        window.location.href = `/?tag=${encodeURIComponent(searchQuery)}`;
    }
}
}
});




let editaccount = document.getElementById('editaccountbtn');
let settingsdiv = document.getElementById('settingsdiv');
editaccount.addEventListener('click', () => {
    settingsdiv.style.display = 'flex';
})

document.addEventListener('mousedown', (event) => {
    if (!settingsdiv.contains(event.target)) {
        settingsdiv.style.display = 'none';
    }
})
// Pobieramy elementy formularza

const usernameInput = document.getElementById('usernameinputsett');
const emailInput = document.getElementById('emailinputsett');
const settingsForm = document.getElementById('settingsform');

// Funkcja do wypełnienia formularza danymi użytkownika
async function populateUserData() {
    const user = auth.currentUser;  
        if (user) {
            const emailToSearch = user.email;

            try {
                // Reference to the 'users' collection
                const usersCollection = collection(db, 'users');

                // Query to find the user document by email
                const userQuery = query(usersCollection, where('email', '==', emailToSearch));

                // Execute the query
                const querySnapshot = await getDocs(userQuery);

                // Process the query results
                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    usernameInput.value = userData.name;  // Populate the username
                    emailInput.value = user.email;       // Populate the email
                });
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        } else {
            console.log("User is signed out.");
        }
    ;
}

// Wywołujemy funkcję po kliknięciu "Edit Account"
editaccount.addEventListener('click', () => {
    settingsdiv.style.display = 'flex';
    populateUserData();  // Wypełniamy formularz danymi użytkownika
});

// Funkcja sprawdzająca, czy nazwa użytkownika jest zajęta
function checkIfUsernameExists(username) {
    const usersCollection = collection(db, 'users'); // Reference to the 'users' collection
    const usernameQuery = query(usersCollection, where('name', '==', username)); // Query for username

    return new Promise(async (resolve, reject) => {
        try {
            // Execute the query
            const querySnapshot = await getDocs(usernameQuery);

            // Check if any documents exist
            if (!querySnapshot.empty) {
                resolve(true); // Username is taken
            } else {
                resolve(false); // Username is available
            }
        } catch (error) {
            reject(error); // Handle errors
        }
    });
}

settingsForm.addEventListener('submit', async (event) => {
    event.preventDefault();
    const newUsername = usernameInput.value;
    const user = auth.currentUser;

    if (!user) {
        console.error('User not logged in.');
        return;
    }

    try {
        const emailToSearch = user.email;
        const usersCollection = collection(db, 'users');

        // Query Firestore for the logged-in user by email
        const emailQuery = query(usersCollection, where('email', '==', emailToSearch));
        const userSnapshot = await getDocs(emailQuery);

        if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0]; // Assuming emails are unique, use the first document
            const userData = userDoc.data();
            const currentUsername = userData.name;

            // Check if the new username is the same as the current one
            if (newUsername === currentUsername) {
                console.log('No changes to the username.');
                return;
            }

            // Check if the new username is already taken
            const usernameQuery = query(usersCollection, where('name', '==', newUsername));
            const usernameSnapshot = await getDocs(usernameQuery);

            if (!usernameSnapshot.empty) {
                alert('Username is already taken.');
            } else {
                // Update the username in Firestore
                const userDocRef = doc(db, 'users', userDoc.id);
                await updateDoc(userDocRef, { name: newUsername });

                alert('Username updated successfully.');
                settingsdiv.style.display = 'none';
            }
        } else {
            console.error('User not found.');
        }
    } catch (error) {
        console.error('Error updating username:', error);
    }
});
// Pobierz referencję do przycisku "Change password"
const resetPasswordBtn = document.getElementById('resetpassword');

// Funkcja resetowania hasła
function resetPassword() {
    const user = auth.currentUser;

    if (user) {
        const email = user.email;

        // Wyślij e-mail z resetowaniem hasła
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert('Password reset email sent! Check your inbox.');
            })
            .catch((error) => {
                console.error('Error sending password reset email:', error);
                alert('Error sending password reset email. Please try again.');
            });
    } else {
        alert('User not logged in.');
    }
}

// Dodaj nasłuch na przycisk "Change password"
resetPasswordBtn.addEventListener('click', resetPassword);

function toptags(clicker, list) {
let clicked = false;

clicker.addEventListener('mouseenter', () => {
list.style.display = 'block';
});

clicker.addEventListener('mouseleave', (e) => {
if (!list.contains(e.relatedTarget)) {
list.style.display = 'none';
}
});

list.addEventListener('mouseenter', () => {
list.style.display = 'block';
});

list.addEventListener('mouseleave', (e) => {
if (!clicker.contains(e.relatedTarget)) {
list.style.display = 'none';
}
});

document.addEventListener('mousedown', (e) => {
if (!clicker.contains(e.target) && !list.contains(e.target) && clicked) {
list.style.display = 'none';
clicked = false;
}
});
}
let moremobile = document.getElementById('moremobile');
let mobileside = document.getElementById('mobileside');

moremobile.addEventListener('click',()=>{
mobileside.style.display = 'flex';
})

document.addEventListener('mousedown',(e)=>{
if(!mobileside.contains(e.target) && window.innerWidth <= 1164){
mobileside.style.display = 'none';
}
})




let popularm = document.getElementById('popularm');
let popdownm = document.getElementById('popdownm');

toptags(popularm,popdownm);

let placem = document.getElementById('placem');
//let popplacem = document.getElementById('popplacem');
//toptags(placem,popplacem);

const popularTagsRef = collection(db, 'popularTags'); // For collection-based
const popularTagsDoc = doc(db, 'popularTags', 'tags'); // For document with array field

async function loadTags() {
    try {
        // Option 1: Fetch tags from a document with an array field
        const popularTagsDocRef = doc(db, "popularTags", "summary"); // Path: popularTags/summary
        const snapshot = await getDoc(popularTagsDocRef);

        let tags = [];
        if (snapshot.exists()) {
            tags = snapshot.data().topTags.map(item => item.tag); // Extract 'tag' field from each object
        }

        // Option 2: Fetch tags from a collection (alternative)
        // Uncomment this section if you're fetching each tag as a separate document
        /*
        const popularTagsRef = collection(db, "popularTags"); // Path: "popularTags"
        const snapshot = await getDocs(popularTagsRef);
        tags = snapshot.docs.map(doc => doc.data().name); // Assuming each document has a 'name' field
        

        // Clear previous tags from UI
        [popdownm, tagList, tagListQ].forEach((list) => list.innerHTML = '');

        // Validate tags
        if (Array.isArray(tags) && tags.length > 0) {
            tags.forEach((tag) => {
                // Add tag to the dropdown (mobile view)
                const liMobile = document.createElement('li');
                liMobile.className = 'lim';
                liMobile.dataset.tag = tag;
                liMobile.textContent = tag;
                liMobile.addEventListener('click', () => {
                    window.location.href = `/?tag=${encodeURIComponent(tag)}`;
                });
                popdownm.appendChild(liMobile);

                // Add tag to desktop lists
                [tagList, tagListQ].forEach((list) => {
                    const liDesktop = document.createElement('li');
                    liDesktop.className = 'tag-item';
                    liDesktop.dataset.tag = tag;
                    liDesktop.textContent = tag;
                    liDesktop.addEventListener('click', () => {
                        window.location.href = `/?tag=${encodeURIComponent(tag)}`;
                    });
                    list.appendChild(liDesktop);
                });
            });
        } else {
            console.log('No popular tags available.');
        }
    } catch (error) {
        console.error('Error loading tags:', error);
    }
}

loadTags();








let myposts = document.getElementById('mypostsm');

myposts.addEventListener('click', () => {
    const user = auth.currentUser;  
        if (user) {
            console.log('User authenticated:', user.email);
            const emailToSearch = user.email;
            const usersRef = query(collection(db, 'users'), where('email', '==', emailToSearch));
            
            console.log('Element clicked');
            getDocs(usersRef).then((snapshot) => {
                console.log('Query reference:', usersRef.toString());
                if (!snapshot.empty) {
                    console.log('Snapshot exists');
                    snapshot.forEach((doc) => {
                        const userData = doc.data();
                        console.log('User logged in:', userData.name);
                        window.location.href = `/user/${userData.name}`;
                    });
                } else {
                    console.log('No user data found');
                }
            }).catch((error) => {
                console.error('Error fetching user data:', error);
            });

        } else {
            console.log('User not authenticated');
            alert('You need to sign in to see your posts');
        }
    ;
});


let myquestions = document.getElementById('myquestionsm');


myquestions.addEventListener('click', () => {
    const user = auth.currentUser;  
        if (user) {
            console.log('User authenticated:', user.email);
            const emailToSearch = user.email;
            const usersRef = query(collection(db, 'users'), where('email', '==', emailToSearch));

            console.log('Element clicked');
            getDocs(usersRef).then((snapshot) => {
                console.log('Query reference:', usersRef.toString());
                if (!snapshot.empty) {
                    console.log('Snapshot exists');
                    snapshot.forEach((doc) => {
                        const userData = doc.data();
                        console.log('User logged in:', userData.name);
                        window.location.href = `/userQuestions/${userData.name}`;
                    });
                } else {
                    console.log('No user data found');
                }
            }).catch((error) => {
                console.error('Error fetching user data:', error);
            });

        } else {
            console.log('User not authenticated');
            alert('You need to sign in to see your posts');
        }
    ;
});


let deleteacc = document.getElementById('deleteacc');
let rusure = document.getElementById('rusure');
deleteacc.addEventListener('click',()=>{
settingsdiv.style.display = 'none';
rusure.style.display = 'flex';
})

let yes = document.getElementById('yesdelete');
let no = document.getElementById('nodelete');
no.addEventListener('click',()=>{
rusure.style.display = 'none';
});
const serverUrl = import.meta.env.VITE_SERVER_URL;
const fetchCsrfToken = async () => {
    const response = await fetch(`${serverUrl}/csrf-token`, {
        credentials: 'include', // Include cookies
    });
    const data = await response.json();
    return data.csrfToken;
};

yes.addEventListener('click', () => {
    const user = auth.currentUser;
    if (user) {
        const email = user.email;

        // Prompt the user to re-enter their password to confirm deletion
        const password = prompt("Please re-enter your password to confirm deletion:");
        const credential = EmailAuthProvider.credential(email, password);

        reauthenticateWithCredential(user, credential).then(async () => {
            try {
                const csrfToken = await fetchCsrfToken();
                // Make a POST request to the backend to delete the user and their data
                const response = await fetch(`${serverUrl}/delete-user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': csrfToken,
                    },
                    credentials : 'include',
                    body: JSON.stringify({
                        email: user.email,
                        userId: user.uid, // Pass user's UID to backend
                    }),
                });

                if (response.ok) {
                    alert('Account deleted successfully');
                    window.location.href = '/'; // Redirect after deletion
                } else {
                    const errorData = await response.json();
                    console.error('Error:', errorData);
                    alert('Failed to delete account. Please try again.');
                }
            } catch (error) {
                console.error('Error making delete request:', error);
                alert('An error occurred. Please try again.');
            }
        }).catch((error) => {
            alert("Re-authentication failed, please try again.");
            console.error("Re-authentication error: ", error);
        });
    } else {
        alert("Couldn't find user.");
    }

    rusure.style.display = 'none';
    //logincheck(); // Update the UI after account deletion
});


let clearButton = document.getElementById('mainimg');
/*
clearButton.addEventListener('click', function () {
search.value = ''; // Clear input field
search.focus(); // Set focus back to input field

// Remove search parameters from the URL
const currentUrl = new URL(window.location);
currentUrl.search = ''; // Remove all query params
window.history.pushState({}, '', currentUrl); // Update the URL without reloading

// Determine the current path and redirect accordingly
const currentPath = window.location.pathname;

if (currentPath.includes('/questions/')) {
// Redirect to QuestionsList if on QuestionDetail
window.location.href = '/questions'; 
} else if (currentPath.includes('/post/')) {
// Redirect to PostList if on PostDetail
window.location.href = '/'; 
} else {
// Reload or redirect to the original state
window.location.href = currentUrl.pathname; // Reload the page without query params
}
});*/


const feedback = document.getElementById('feedback');
const feedbackdiv = document.getElementById('feedbackdiv');
const feedbackInput = document.getElementById('feedback-input');
const feedbackContainer = document.getElementById('feedback-container');
const feedbackForm = document.getElementById('feedback-form');
const thankYouMessage = document.getElementById('thank-you-message');
const submitButton = document.getElementById('submit-feedback');
const alreadySent = document.getElementById('alreadySent');

// Show the feedback container when the feedback button is clicked
feedback.addEventListener('click', () => {
feedbackContainer.style.display = 'flex';
if (hasSubmittedFeedback()) {
alreadySent.style.display = 'flex';
thankYouMessage.style.display = 'none';
} else {
feedbackdiv.style.display = 'block';
}
});

// Check if the feedback has already been submitted using cookies
function hasSubmittedFeedback() {
return document.cookie.split('; ').some(cookie => cookie.startsWith('feedbackSent=true'));
}

// Save feedback to Firebase
function saveFeedback(feedbackText) {
    const feedbackRef = collection(db, 'feedback'); // Reference to the 'feedback' collection in Firestore

    // Adding feedback to Firestore
    addDoc(feedbackRef, {
        feedback: feedbackText,
        timestamp: new Date().toISOString()
    }).then(() => {
        // Set a cookie to remember that the user has submitted feedback
        document.cookie = "feedbackSent=true; path=/; max-age=" + (60 * 60 * 24 * 1); // Cookie valid for 1 day

        feedbackdiv.style.display = 'none';  // Hide the feedback form
        thankYouMessage.style.display = 'block';  // Show the thank you message
    }).catch((error) => {
        console.error('Error saving feedback:', error);
        alert('Failed to submit feedback. Please try again later.');
    });
}


// Show the appropriate form based on whether the user has already submitted feedback
if (hasSubmittedFeedback()) {
alreadySent.style.display = 'flex';
} else {
feedbackdiv.style.display = 'block';
}


document.addEventListener('DOMContentLoaded', () => {
const feedbackTagList = document.getElementById('feedbackTagList');
const feedbackInput = document.getElementById('feedback-input');

feedbackTagList.addEventListener('click', (event) => {
// Check if the clicked element is a feedback tag
if (event.target && event.target.classList.contains('feedback-tag')) {
    const selectedTag = event.target.textContent;
    const currentText = feedbackInput.value;

    // Check if the tag is not already in the textarea
    if (!currentText.includes(selectedTag)) {
        // Add the tag to the textarea, separating with a comma if necessary
        feedbackInput.value = currentText ? `${currentText}, ${selectedTag}` : selectedTag;
    }
}
});
});


// Handle feedback submission
submitButton.addEventListener('click', () => {
const feedbackText = feedbackInput.value.trim();
if (feedbackText === '') {
alert('Please provide feedback before submitting.');
return;
}
saveFeedback(feedbackText);
});

document.addEventListener('mousedown',(e)=>{
if(!feedbackContainer.contains(e.target)){
feedbackContainer.style.display = 'none';
}
});

let morelist = document.getElementById('morelist');
let more = document.getElementById('more');

more.addEventListener('click',()=>{
morelist.style.display = 'flex';
});

document.addEventListener('mousedown',(e)=>{
if(!morelist.contains(e.target)){
morelist.style.display = 'none';
}
});

let cookiePolicy= document.getElementById('cookiePolicy');

cookiePolicy.addEventListener('click',()=>{
window.location = '/legal#cookie-policy';
});

let tou = document.getElementById('ToU');
tou.addEventListener('click',()=>{
window.location = '/legal#terms-of-use';
});

let privacyPolicy = document.getElementById('privacyPolicy');
privacyPolicy.addEventListener('click',()=>{
window.location = '/legal#privacy-policy';
});










/*
function setTheme(theme) {
    const lightTheme = document.getElementById('light-theme');
    const darkTheme = document.getElementById('dark-theme');

    if (theme === 'dark') {
        if (lightTheme && darkTheme) {
        lightTheme.disabled = true;
        darkTheme.disabled = false;
        document.getElementById('logo').src = '/images/isLogoWhite.png';
        }
    } else {
        if (lightTheme && darkTheme) {
        lightTheme.disabled = false;
        darkTheme.disabled = true;
         document.getElementById('logo').src = '/images/isLogo.png';
        }
    }


    localStorage.setItem('theme', theme);
}


function getStoredTheme() {
    return localStorage.getItem('theme');
}


const savedTheme = getStoredTheme();
if (savedTheme) {
    setTheme(savedTheme);
} else {
    setTheme('light');
}

// Button event listeners
document.getElementById('lightTheme').addEventListener('click', () => {
    setTheme('light');
});

document.getElementById('darkTheme').addEventListener('click', () => {
    setTheme('dark');
});

document.getElementById('lightTheme2').addEventListener('click', () => {
    setTheme('light');
});

document.getElementById('darkTheme2').addEventListener('click', () => {
    setTheme('dark');
});

let selectTheme = document.getElementById('selectTheme');
let changeTheme = document.getElementById('changeTheme');

changeTheme.addEventListener('click',()=>{
    selectTheme.style.display = 'flex';
})
document.addEventListener('mousedown',(e)=>{
    if (!selectTheme.contains(e.target)){
    selectTheme.style.display ='none';
    }
})
    */

