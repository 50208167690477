import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, sendPasswordResetEmail, signOut } from "firebase/auth";
//import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore, collection } from "firebase/firestore";



// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCmBsHv0DjUvH3id5bvUCMGEDOmwD5VFxk",
  authDomain: "ideasights-5867f.firebaseapp.com",
  projectId: "ideasights-5867f",
  storageBucket: "ideasights-5867f.firebasestorage.app",
  messagingSenderId: "55022295868",
  appId: "1:55022295868:web:0ee2ee3e77b03671bedc39",
  measurementId: "G-8F9EGW1K4V"
};

let app;
app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

let analytics = null;

// Function to get a value from localStorage
export function getLocalStorageItem(key) {
  if (typeof localStorage === 'undefined') {
    // Running in a non-browser environment, return null
    return null;
  }

  return localStorage.getItem(key);
}

// Function to initialize Analytics if consent is granted
function initializeAnalyticsIfConsent() {
  const userConsent = getLocalStorageItem('userConsent'); // Get consent value from localStorage
  if (userConsent === 'accepted') {
    if (!analytics) {
      analytics = getAnalytics(app); // Initialize Firebase Analytics
      logEvent(analytics, 'page_view', {
        page_title: document.title,
        page_path: window.location.pathname,
      });
    }
  }
}

// Call the function to initialize Analytics based on consent
initializeAnalyticsIfConsent();

  

// Export Firebase services
export { auth, db, storage, onAuthStateChanged, analytics, logEvent, sendPasswordResetEmail, signOut, collection };