import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, updateDoc, deleteDoc, arrayUnion, collection, getDocs, where, query } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { db, auth } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../firebase';
import './EditPost.css';
const serverUrl = import.meta.env.VITE_SERVER_URL;

const EditPost = ({ post, postId }) => {
    const [title, setTitle] = useState(post?.header || '');
    const [description, setDescription] = useState(post?.description || '');
    const [tags, setTags] = useState(post?.tags ? post.tags.join(', ') : '');
    const [images, setImages] = useState(post?.images || []);
    const [newImages, setNewImages] = useState([]);
    const [deletedImages, setDeletedImages] = useState([]);
    const [comments, setComments] = useState([]);
    const [isAuthor, setIsAuthor] = useState(false);
    const [loading, setLoading] = useState(false); 
    const [allCommentsLoaded, setAllCommentsLoaded] = useState(false);
    const [lastLoadedKey, setLastLoadedKey] = useState(null); // Track last comment key for pagination
    const COMMENTS_BATCH_SIZE = 10; 

    const { id } = useParams();

    const loadMoreRef = useRef(null);

    useEffect(() => {
        const user = auth.currentUser;
        if (user && user.email === post?.email) {
            setIsAuthor(true);
        }
    
      fetchComments();
    }, [post, id]);


    const fetchComments = async () => {
        if (loading || allCommentsLoaded) return; // Avoid fetching if already loading or all comments are loaded
        setLoading(true);
    
        try {
            // Fetch comments starting after the last loaded key
            const response = await fetch(`${serverUrl}/posts/${id}/comments?limit=${COMMENTS_BATCH_SIZE}&lastKey=${lastLoadedKey || ''}`);
            if (response.ok) {
                const newCommentsItem = await response.json();
                const newComments = newCommentsItem.comments;
    
                if (newComments.length > 0) {
                    const formattedComments = Object.entries(newComments).map(([key, comment]) => ({
                        id: key,  // Store the comment key as 'id'
                        ...comment
                    }));
    
                    setComments((prevComments) => [...prevComments, ...formattedComments]); // Append to existing comments
                    setLastLoadedKey(newComments[newComments.length - 1].id); // Update last loaded key to the latest
                } else {
                    setAllCommentsLoaded(true); // Mark all comments loaded if no new comments are fetched
                }
            } else {
                console.log('No more comments available.');
            }
        } catch (error) {
            console.error('Error fetching comments:', error);
        } finally {
            setLoading(false);
        }
    };
    

    useEffect(() => {
        const observerCallback = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !loading && !allCommentsLoaded) {
                    fetchComments();
                }
            });
        };
    
        const observerOptions = {
            root: loadMoreRef.current?.parentNode,
            rootMargin: '0px',
            threshold: 1.0,
        };
    
        const observer = new IntersectionObserver(observerCallback, observerOptions);
    
        // Check if `loadMoreRef` is available and observe it
        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }
    
        return () => {
            // Clean up observer on component unmount
            if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
        };
    }, [loading, allCommentsLoaded, loadMoreRef.current]);


    const generateSlug = (title) => {
        return title
          .toLowerCase() // Zamiana na małe litery
          .trim() // Usunięcie białych znaków na początku i końcu
          .replace(/[^a-z0-9\s-]/g, '') // Usunięcie znaków specjalnych
          .replace(/\s+/g, '-') // Zamiana spacji na myślniki
          .replace(/-+/g, '-'); // Usunięcie powtarzających się myślników
      };
      

const handleUpdatePost = async () => {
    const user = auth.currentUser;
    if (user && user.email === post?.email) {

        let baseSlug = post.slug;
        let uniqueSlug = baseSlug;
        if (title != post.header) {
  baseSlug = generateSlug(title);
  
      // Sprawdzenie unikalności slug
      const postsCollection = collection(db, 'posts');
      let slugExists = true;
      let counter = 1;
  
      while (slugExists) {
        const q = query(postsCollection, where('slug', '==', uniqueSlug));
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          slugExists = false; // Slug jest unikalny
        } else {
          // Jeśli slug już istnieje, dodaj licznik na końcu
          uniqueSlug = `${baseSlug}-${counter}`;
          counter++;
        }
      }
    }

        const views = typeof post?.views === 'number' ? post.views : 0;
        const updatedPost = {
            header: title,
            slug: uniqueSlug,
            description,
            tags: tags.split(',').map((tag) => tag.trim()),
            images, // Only the updated list of images
            views,
        };

        Object.keys(updatedPost).forEach((key) => {
            if (updatedPost[key] === undefined) {
                delete updatedPost[key];
            }
        });

        const postRef = doc(db, `posts/${postId}`);
        try {
            // Update the post in Firestore
            await updateDoc(postRef, updatedPost);

            // Call the delete-images endpoint if there are images to delete
            if (deletedImages.length > 0) {
                await fetch(`${serverUrl}/delete-images`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ images: deletedImages }),
                });
            }

            alert('Post updated successfully!');
        } catch (error) {
            console.error('Error updating post:', error);
        }
    }
};

    






const handleImageUpload = async (event) => {
     const user = auth.currentUser;
        if (user && user.email === post?.email) {
    const files = event.target.files; // Get selected files
    if (!files || files.length === 0) return;

    const uploadedImageUrls = []; // To store Firebase Storage URLs

    try {
        // Upload each file
        for (const file of files) {
            const storageRef = ref(storage, `posts/${Date.now()}_${file.name}`); // Unique file path
            const snapshot = await uploadBytes(storageRef, file); // Upload file
            const downloadUrl = await getDownloadURL(snapshot.ref); // Get download URL
            uploadedImageUrls.push(downloadUrl); // Add URL to the list
        }

        // Update Firestore with new image URLs
        const postRef = doc(db, `posts/${postId}`);
        await updateDoc(postRef, {
            images: arrayUnion(...uploadedImageUrls), // Append new URLs to the existing array
        });

        // Update local state
        setImages((prevImages) => [...prevImages, ...uploadedImageUrls]); // Add new URLs to state
    } catch (error) {
        console.error("Error uploading files or updating Firestore:", error);
    }
}
};


const handleDrop = async (event) => {
    event.preventDefault();
     const user = auth.currentUser;
        if (user && user.email === post?.email) {
    const files = event.dataTransfer?.files; // Use `dataTransfer` for drag-and-drop
    if (!files || files.length === 0) return;

    const uploadedImageUrls = []; // To store Firebase Storage URLs

    try {
        for (const file of files) {
            const storageRef = ref(storage, `posts/${Date.now()}_${file.name}`); // Unique file path
            const snapshot = await uploadBytes(storageRef, file); // Upload file
            const downloadUrl = await getDownloadURL(snapshot.ref); // Get download URL
            uploadedImageUrls.push(downloadUrl); // Add URL to the list
        }

        // Update Firestore with new image URLs
        const postRef = doc(db, `posts/${postId}`);
        await updateDoc(postRef, {
            images: arrayUnion(...uploadedImageUrls), // Append new URLs to the existing array
        });

        // Update local state
        setImages((prevImages) => [...prevImages, ...uploadedImageUrls]); // Add new URLs to state
    } catch (error) {
        console.error("Error uploading files or updating Firestore:", error);
    }
}
};


const handleDragOver = (event) => {
    event.preventDefault();
};

    const handleRemoveImage = (index) => {
        const removedImage = images[index]; 
        setImages(images.filter((_, i) => i !== index));
        setNewImages(newImages.filter((_, i) => i !== index));
        setDeletedImages((prev) => [...prev, removedImage]);
    };


    // const fetchCsrfToken = async () => {
    //     const response = await fetch(`${serverUrl}/csrf-token`, {
    //         credentials: 'include', // Include cookies
    //     });
    //     const data = await response.json();
    //     return data.csrfToken;
    // };

    const handleRemoveComment = async (commentId) => {
        //const csrfToken = await fetchCsrfToken();
    
        try {
            const response = await fetch(`${serverUrl}/posts/${postId}/comments/${commentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                   // 'X-CSRF-Token': csrfToken, // Include CSRF token
                },
                credentials: 'include', // Ensure cookies are sent with the request
            });
    
            if (response.ok) {
                // Filter out the comment with the matching ID from the comments state
                setComments((prevComments) => prevComments.filter((comment) => comment.id !== commentId));
    
                // Reference to the post document
                const postRef = doc(db, `posts/${postId}`);
    
                // Read the current comments count
                const postSnapshot = await getDoc(postRef);
                if (postSnapshot.exists()) {
                    const postData = postSnapshot.data();
                    const currentCount = postData.commentsCount || 0;
    
                    // Decrease the count by 1
                    const updatedCount = currentCount > 0 ? currentCount - 1 : 0;
    
                    // Update the count in Firestore
                    await updateDoc(postRef, { commentsCount: updatedCount });
                }
    
                alert('Comment removed successfully!');
            } else {
                throw new Error('Failed to remove comment');
            }
        } catch (error) {
            console.error('Error removing comment:', error);
        }
    };

   

    if (!isAuthor) return <p>You do not have permission to edit this post.</p>;

    return (
        <div className="edit-post">
            <h2 className="post-header">Edit Idea</h2>
            <div className="edit-field">
                <label className="post-label">Header:</label>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="post-input"
                    maxLength={45}
                />
            </div>

            <div className="edit-field">
                <label className="post-label">Description:</label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="post-input post-description"
                    maxLength={4000}
                />
            </div>

            <div className="edit-field">
                <label className="post-label">Tags:</label>
                <input
                    type="text"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="post-input"
                    placeholder="Separate tags with commas"
                    maxLength={500}
                />
            </div>

            <div className="edit-field">
                <label className="post-label">Images:</label>
                <div className="post-images">
                    {images.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image} alt={`Image ${index + 1}`} className="post-image" style={imgStyle} />
                            <button onClick={() => handleRemoveImage(index)} className="post-button">
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
                <div className='image-div'>
                <label id="filelabeal" className='fileLabeal'>
                    <input
                        id="imginput"
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleImageUpload}
                    />
                    Select a photo
                    <p style={{ alignSelf: 'center' }}>(click here)</p>
                </label>
                <label className='fileLabeal'>or</label>
                <div
                    id="dropZoneAdd"
                    className="drop-zone"
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    Drag & Drop images here
                </div>
                </div>
            </div>

            <button onClick={handleUpdatePost} className="post-button update-post-button">
                Save changes
            </button>

            <div className="post-comments">
                <h2 className="post-header">Manage comments</h2>
                {comments.length > 0 ? (
                    comments.map((comment) => (
                        <div key={comment.id} className="comment-item">
                            <p>
                                <strong>{comment.name}:</strong> {comment.text}
                            </p>
                            <button onClick={() => handleRemoveComment(comment.id)} className="post-button delete-button">
                                Delete
                            </button>
                        </div>
                    ))
                ) : (
                    <p className="no-comments">No comments yet.</p>
                )}
                 <div ref={loadMoreRef} style={{ height: '50px' }}></div>
            </div>
        </div>
    );
};

const imgStyle = {
    width: '400px',
    height: 'auto',
};

export default EditPost;
