import React, { useEffect, useRef } from "react";

const ChoosePostType = ({ visible, setVisible, setPostType }) => {
    const choosePostRef = useRef(null);

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (choosePostRef.current && !choosePostRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [visible, setVisible]);

    return (
        visible && (
            <div id="choosepost" className="addpost" ref={choosePostRef} >
                <p id="toPostForm" className="pover" onClick={() => setPostType('idea')}>
                    Post an Idea
                </p>
                <p id="toQuestionForm" className="pover" onClick={() => setPostType('question')}>
                    Ask a Question
                </p>
            </div>
        )
    );
};

export default ChoosePostType;