import React, { useEffect, useRef } from "react";
import { auth } from "../firebase"; // Adjust the import based on your project structure
import { signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";
import GoogleSignInButton from "./SignWithGoogle";




const SignInDiv = ({ visible, setVisible, setOtherVisible, setIsResetVisible }) => {
    const signInRef = useRef(null);


    async function searchname() {
        const user = auth.currentUser;  
        if (user) {
            const emailToSearch = user.email;
            const usersCollection = collection(db, "users");
            const emailQuery = query(usersCollection, where("email", "==", emailToSearch));
    
            try {
                const querySnapshot = await getDocs(emailQuery);
    
                querySnapshot.forEach((doc) => {
                    const userData = doc.data(); // Get document data
                });
    
                if (querySnapshot.empty) {
                    console.log("No matching user found.");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        } else {
            console.log("User is signed out.");
        }
    }

    function toOther() {
        setVisible(false);
        setOtherVisible(true);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        const email = event.target.emailinputl.value;
        const password = event.target.passwordinputl.value;

      /* setPersistence(auth, browserSessionPersistence)
               .then(() => {
                   return */signInWithEmailAndPassword(auth, email, password)
               //})
               .then((userCredential) => {
                   const user = userCredential.user;
                   if (user.emailVerified) {
                       searchname();
                       window.location.reload();
                          setVisible(false);
                      // logincheck();
                       //signindiv.style.display = 'none';
                       
                   } else {
                       alert('Please verify your email before logging in.');
                       auth.signOut();
                   }
               })
               .catch((error) => {
                   alert('Wrong data');
               });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (signInRef.current && !signInRef.current.contains(event.target)) {
                setVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setVisible]);


    return (
        visible && (
            <div id="signindiv" className="sign" ref={signInRef}>
                <label>Sign In</label>
                <form id="signinform" onSubmit={handleSubmit}>
                    <input id="emailinputl" placeholder="email" type="email" required />
                    <input id="passwordinputl" placeholder="password" type="password" required />
                    <button type="submit">Submit</button>
                </form>
                <p onClick={() => {setVisible(false); setIsResetVisible(true)}}>Forgot password?</p>
                <p style={{ userSelect: 'none' }} id="gotosignup" onClick={toOther}>No account? Create one</p>
                <GoogleSignInButton setVisible={setVisible}/>
            </div>
        )
    );
};

export default SignInDiv;